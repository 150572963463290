import gql from "graphql-tag";

export default gql`
  query {  
    allTradeScans (basket: true) {
      code
      url
      previewUrl
      pictureUrl
    }
  }`;
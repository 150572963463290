import {connect} from "react-redux";
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import QUERY_TRADE_COUNTRY_DATA from "./TradeCountryPageGraphql";
import TradeCountryPage from "./TradeCountryPage";
import withDataTranslation from "./lib/translationHelper";
import {withRouter} from "react-router-dom";

export default compose(
    withRouter,
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_TRADE_COUNTRY_DATA, {
        options: ({match: {params}}) => ({
            variables: {
                countryCode: params.countryCode,
                page: params.page,
            }
        })
    }),
    connect(
        null,
        {
            setPageTitle,
            setBreadcrumbs
        }
    ),
)(TradeCountryPage);
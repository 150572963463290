import gql from "graphql-tag";

export default gql`
  query ($page: Int, $countryCode: String!) {
    items(page: $page, countryCode: $countryCode, trade: true, pageSize: 50) {
      list {
        code
        twistOff
        companies {
          website
          translations {
            language
            shortName
          }
        }
        beverages {
          color
          translations {
            language
            name
          }
        }
        brands {
          translations {
            language
            name
          }
        }
        locations {
          country {
            flagUrl
            url
            translations {
              language
              name
            }
          }
          region {
            url
            translations {
              language
              name
            }
          }
          city {
            translations {
              language
              name
            }
          }
        }
        pictures {
          pictureType
          previewUrl
          pictureUrl
        }
      }
      numPages
      page
    }
    country(countryCode: $countryCode) {
      translations {
        name
        language
      }
    }
  }`;
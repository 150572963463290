import gql from "graphql-tag";

export default gql`
{
  regionStatData {
    location {
      url
      translations {
        name
        language
      }
      country {        
        url
        flagUrl
        translations {
          name
          language
        }
      }
    }
    amount
  }
}`;
export default () => ({
    amountHeader: {
        textAlign: "center",
    },
    shareCell: {
        minWidth: 100,
    },
    amountCell: {
        textAlign: "right",
    },
    flag: {
        verticalAlign: "text-bottom",
    },
    adCell: {
        padding: "0!important",
    }
});
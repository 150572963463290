import gql from "graphql-tag";

export default gql`
  query {
    mainMenuCollectionData {
      defective
      inside
      sets
      stats {
        beverages
        brands
        companies
        cities
        regions
        countries
        conditions
        years
        counterparties
      }
      trade
      continentMaps {
        code
        mapUrl
        translations {
          name
          language
        }
      }
      countryMaps {
        code
        mapUrl
        translations {
          name
          language
        }
        parent {
          code
        }
      }
      thanks
      websites
      blacklist
    }
    mainMenuTradeData {
      scans
    }
    mainMenuBlogData {
      blog
      sections {
        url
        translations {
          language
          title
        }
      }
    }
  }`;
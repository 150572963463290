import {lighten} from "@material-ui/core/styles";

export default theme => ({
    root: {
        display: "block",
        maxWidth: "100%",
        margin: "0 auto 20px",
        "@global": {
            path: {
                strokeLinejoin: "round",
                strokeWidth: ".3px",
                stroke: "#777",
            },
        }
    },
    "@global": {
        ".map": {
            "&__land": {
                "&_country, &_region": {
                    fill: "#fff",
                    cursor: "default",

                    "&.map__land_outer": {
                        fill: "#e6e6e6",

                        "&:hover": {
                            fill: lighten("#e6e6e6", 0.02),
                        }
                    }
                },
                "&_macro-region": {
                    fill: "transparent",
                },
                "&_continent": {
                    fill: "#fff",

                    "&.map__land_outer": {
                        fill: "#ccc",

                        "&:hover": {
                            fill: lighten("#ccc", 0.02),
                        }
                    }
                },
                "&_active": {
                    cursor: "pointer",
                    transition: theme.transitions.create("all"),
                },
                // Create array of amount categories [1, 2, 3, ... 10, 20, ... 100, 200, ... 1000, 2000, ... 10000]
                ...[...Array(10).keys()].reduce(
                    (result, i) =>
                        result.concat(
                            [...Array(4).keys()].map(
                                (p) => (i + 1) * Math.pow(10, p))
                        ),
                    []
                ).reduce(
                    // Calculate styles for amount categories
                    (map, amount) => {
                        const hue = 128 - Math.log10(amount) * 32;
                        const bgColor = `hsl(${hue}, 80%, 50%)`;
                        map[`&_amount-${amount}`] = {
                            fill: bgColor,
                            "&:hover": {
                                fill: lighten(bgColor, 0.15),
                            }
                        };
                        return map;
                    },
                    {}
                ),
                "&_amount-0": {
                    fill: "#fff",
                }
            },
            "&__click-area": {
                fill: "transparent !important",
                stroke: "transparent !important",
            },
            "&__water": {
                fill: "#b1e5f9",
            },
            "&__title": {
                fill: "#888",
                pointerEvents: "none",
                mixBlendMode: "difference",
                "&_macro-region, &_continent": {
                    fontStyle: "italic",
                    fill: "#888",
                    mixBlendMode: "difference",
                }
            },
            "&__text-path": {
                display: "none",
            },
            "&__pointer": {
                strokeWidth: "1.2px !important",
                textShadow: "1px 1px 0 rgba(255, 255, 255, .5)",
                fill: "none",
                pointerEvents: "none",
                display: "none",

                "&_show": {
                    display: "inline",
                }
            },
            "&__frame": {
                fill: "none",
                strokeWidth: "1.5px !important",
            },
            "&__border": {
                fill: "none",
                strokeWidth: "1.5px !important",
            },
            "&__scale": {
                "&_items": {
                    strokeWidth: "1px !important",
                    stroke: theme.palette.text.primary,
                    fill: "#fff",
                    pointerEvents: "none",
                },
                "&_item": {
                    strokeWidth: "0 !important",
                    pointerEvents: "none",
                },
                "&_title": {
                    fill: "#888",
                    mixBlendMode: "difference",
                    pointerEvents: "none",
                }
            },
            "&_loading": {
                "& .map": {
                    "&__title, &__pointer": {
                        display: "none",
                    },
                    "&__land_country, &__land_region, &__land_macro_region, &__land_continent": {
                        fill: "#ccc",
                    }
                }
            }
        }
    },
    showDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
})
import React from "react";
import {InputAdornment, FormControl, OutlinedInput, IconButton} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import LeftSideWidget from "./LeftSideWidgetContainer";
import bottleCapIndexLogo from "../img/bottle_cap_index.svg";

export default ({t, classes, searchQuery, setSearchQuery}) => {
    const title = "Bottle Cap Index";

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
    };

    return <LeftSideWidget title={title} content={
        <React.Fragment>
            <img src={bottleCapIndexLogo} alt={title} className={classes.logo}/>
            <form method="get" action="https://bottlecaps.de/db" target="_blank">
                <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                        id="bci_search_input"
                        name="q"
                        placeholder={t("search")}
                        margin="dense"
                        fullWidth
                        value={searchQuery}
                        onChange={handleSearchChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton type="submit" disabled={!searchQuery}>
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                        className={classes.input}
                    />
                </FormControl>
            </form>
        </React.Fragment>
    }/>;
};
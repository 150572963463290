export default theme => ({
    root: {
        "& > dt": {
            fontWeight: 500,
        },
        "& > dd": {
            margin: "0 0 0.5rem 0",
        },
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            flexFlow: "row",
            flexWrap: "wrap",
            width: "100%",
            overflow: "visible",
            "& > dt": {
                flex: "0 0 30%",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                paddingRight: 10,
                "&:after": {
                    content: '"..................................................................."',
                    marginLeft: 10,
                }
            },
            "& > dd": {
                flex: "0 0 70%",
                marginLeft: "auto",
                textAlign: "left",
                textOverflow: "ellipsis",
                overflow: "hidden",
            }
        },
    },
});
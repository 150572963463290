import gql from "graphql-tag";

export default gql`
query ($publicType: String!, $page: Int) {
  counterparties(publicType: $publicType, page: $page) {
    list {
      email
      location {
        city {
          translations {
            name
            language
          }
        }
        region {
          translations {
            name
            language
          }
        }
        country {
          flagUrl
          translations {
            name
            language
          }
        }
      }
      website
      zipCode
      photoUrl
      previewUrl
      translations {
        language
        fullName
        postAddress
        comments
      }
    }
    numPages
    page
  }
  mainMenuCollectionData {
    thanks
    websites
    blacklist
  }
}`;
import React from "react";
import Pagination from "./PaginationContainer";
import ContentAdWrapper from "./ContentAdWrapperContainer";
import LeftCoverCard from "./LeftCoverCardContainer";
import HorizontalCardListStub from "./HorizontalCardListStubContainer";
import {Helmet} from "react-helmet/es/Helmet";

export default ({data, numPages, page, baseUrl, loading, ad}) => {
    if (loading) {
        return <HorizontalCardListStub cardAmount={10}/>;
    }
    if (!data) {
        return null;
    }

    return (
        <React.Fragment>
            {page > 1 && (
                <Helmet>
                    <link rel="canonical" href={baseUrl}/>
                </Helmet>
            )}
            {data.map((scan, index) => (
                <React.Fragment key={index}>
                    {index === Math.ceil(data.length / 2)
                        ? <ContentAdWrapper ad={ad}/>
                        : null
                    }
                    <LeftCoverCard url={scan.url}
                                   preview={scan.preview}
                                   picture={scan.picture}
                                   title={scan.code}
                                   subTitle={scan.modified}
                                   content={scan.name}/>
                </React.Fragment>
            ))}
            <Pagination numPages={numPages} page={page} baseUrl={baseUrl}/>
        </React.Fragment>
    );
};
import {formatDateTime} from "./lib/dateHelper";

export default (data, dt, language) => {
    if (!data) {
        return;
    }

    return data.list.map(scan => ({
        code: scan.code,
        url: `/${language}${scan.url}`,
        picture: scan.pictureUrl,
        preview: scan.previewUrl,
        name: dt("name", scan.translations),
        modified: formatDateTime(scan.modified, language),
    }));
};
import React from "react";
import {Link} from "react-router-dom";
import {Breadcrumbs, Typography} from "@material-ui/core";

export default ({items, t, i18n, classes}) =>
    <Breadcrumbs className={classes.root}>
        <Link color="inherit" to={`/${i18n.language}/`}>{t("main_page")}</Link>
        {items.map((item, index) =>
            item.link === undefined || index === items.length - 1
                ? <Typography color="textPrimary" key={index}>{item.name}</Typography>
                : <Link color="inherit" key={index} to={item.link}>{item.name}</Link>
        )}
    </Breadcrumbs>
import gql from "graphql-tag";

export default gql`
{
  companyStatData {
    company {
      website
      translations {
        shortName
        language
      }
    }
    amount
  }
}`;
import React, {useState} from "react";
import {HashLink} from "react-router-hash-link";
import {List, ListItem, ListItemText, ListItemSecondaryAction, Collapse} from "@material-ui/core";
import LeftSideWidget from "./LeftSideWidgetContainer";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import LeftSideListWidgetStub from "./LeftSideListWidgetStubContainer";

const WidgetListItem = ({data, classes}) => (
    <ListItem disableGutters={true}
              component={HashLink}
              to={data.url}
              key={data.url}
              className={classes.listItem}
              smooth={true}
    >
        {data.flag
            ? <img src={data.flag} className={classes.flag} alt=""/>
            : null}
        <ListItemText primary={data.name} className={classes.listItemText}/>
        <ListItemSecondaryAction className={classes.amount}>{data.amount}</ListItemSecondaryAction>
    </ListItem>
);

export default ({t, title, listData, classes, loading = false}) => {
    const [collapsed, toggleCollapsed] = useState(true);
    const LIST_LIMIT = 20;

    if (loading || !listData) {
        return <LeftSideListWidgetStub/>;
    }
    if (listData.length === 0) {
        return null;
    }

    return (
        <LeftSideWidget title={title} content={
            <List dense={true} disablePadding={true}>
                {listData.slice(0, LIST_LIMIT).map(
                    (data, index) => <WidgetListItem data={data} classes={classes} key={index}/>)
                }
                {listData.length > LIST_LIMIT
                    ? <React.Fragment>
                        <Collapse in={!collapsed} timeout="auto" unmountOnExit>
                            {
                                listData.slice(LIST_LIMIT).map(
                                    (data, index) => <WidgetListItem data={data} classes={classes}
                                                                     key={index + LIST_LIMIT}/>
                                )
                            }
                        </Collapse>
                        <ListItem button onClick={() => toggleCollapsed(!collapsed)}
                                  disableGutters={true}
                                  className={classes.listItem}>
                            <ListItemText primary={collapsed ? t("show_all") : t("collapse")}
                                          className={classes.collapse}/>
                            {collapsed ? <ExpandMore/> : <ExpandLess/>}
                        </ListItem>
                    </React.Fragment>
                    : null
                }
            </List>
        }/>
    );
};
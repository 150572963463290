import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import compose from "recompose/compose";
import LeftSideCustomCreatedWidget from "./LeftSideCustomCreatedWidget";
import {withStyles} from "@material-ui/core/styles";
import styles from "./LeftSideCustomCreatedWidgetStyles";

export default compose(
    withRouter,
    withStyles(styles),
    withTranslation(),
)(LeftSideCustomCreatedWidget);
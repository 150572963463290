export default theme => ({
    previews: {
        maxWidth: 750,
        margin: "0 auto 40px",
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "flex",
            flexWrap: "wrap",
        },
        "& .MuiSkeleton-rect": {
            width: "20%",
            paddingTop: "calc(20% - 2px)",
            border: `1px solid ${theme.palette.background.paper}`,
        }
    },
    message: {
        marginBottom: 15,
        "& .MuiAlert-message": {
            width: "100%",
        }
    },
});
import gql from "graphql-tag";

export default gql`
  query ($slug: String!) {
    blogPostData(slug: $slug) {
      created
      modified
      url
      adminUrl
      pictureUrl
      section {
        url
        translations {
          language
          title
        }
      }
      translations {
        language
        introduction
        title
        text
        keywords
      }
    }
  }`;
import gql from "graphql-tag";

export default gql`
  {
    randomItem {
      code
      url
      pictures {
        pictureType
        pictureUrl
      }
    }
  }`;
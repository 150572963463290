import {connect} from "react-redux";
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import QUERY_ITEM_LIST_DATA from "./ItemListPageGraphql";
import ItemListPage from "./ItemListPage";
import withDataTranslation from "./lib/translationHelper";
import {withRouter} from "react-router-dom";
import moment from "moment";
import {getQueryParam} from "./lib/urlHelper";

export default compose(
    withRouter,
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_ITEM_LIST_DATA, {
        options: ({match: {params}, location}) => ({
            variables: {
                countryCode: params.countryCode,
                regionClientId: params.regionClientId,
                setClientId: params.setClientId,
                defective: params.defective === "defective",
                inside: params.inside === "inside",
                created: params.created === "created",
                createdFrom: params.createdFrom
                    ? moment(params.createdFrom, "DD.MM.YYYY").format("YYYY-MM-DD")
                    : null,
                createdTill: params.createdTill
                    ? moment(params.createdTill, "DD.MM.YYYY").format("YYYY-MM-DD")
                    : null,
                search: params.search === "search",
                searchPhrase: getQueryParam(location.search, "q"),
                searchBy: getQueryParam(location.search, "by"),
                itemCode: params.itemCode,
                language: params.language,
                page: params.page,
            }
        })
    }),
    connect(
        null,
        {
            setPageTitle,
            setBreadcrumbs
        }
    ),
)(ItemListPage);
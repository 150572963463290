import React from "react";
import {List} from "@material-ui/core";
import LeftSideWidget from "./LeftSideWidgetContainer";
import Skeleton from "@material-ui/lab/Skeleton";

export default ({classes: {listItem}}) => (
    <LeftSideWidget title={<Skeleton variant="text" width={"40%"}/>} content={
        <List dense={true} disablePadding={true}>
            {[...Array(5).keys()].map(
                (_, index) => (
                    <React.Fragment key={index}>
                        <Skeleton className={listItem} variant="text" width={"40%"}/>
                        <Skeleton className={listItem} variant="text" width={"65%"}/>
                        <Skeleton className={listItem} variant="text" width={"30%"}/>
                        <Skeleton className={listItem} variant="text" width={"50%"}/>
                    </React.Fragment>
                )
            )}
        </List>
    }/>
);
import React, {useEffect} from "react";
import PersonIcon from "@material-ui/icons/Person";
import LoginDialog from "./LoginDialogContainer";
import LogoutDialog from "./LogoutDialogContainer";

export default ({data: {user}, setUser, button, fontSize}) => {
    const Button = button;
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setUser(user);
    }, [setUser, user]);

    const Dialog = user ? LogoutDialog : LoginDialog;

    return (
        <React.Fragment>
            <Button onClick={handleClickOpen}>
                <PersonIcon fontSize={fontSize}/>
            </Button>
            <Dialog open={open} onClose={handleClose}/>
        </React.Fragment>
    );
}
import {withTranslation} from "react-i18next";
import {withTheme} from "@material-ui/core/styles";
import compose from "recompose/compose";
import LoginDialog from "./LoginDialog";
import {withApollo} from "react-apollo";

export default compose(
    withTranslation(),
    withApollo,
    withTheme
)(LoginDialog);
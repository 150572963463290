import React from "react";
import mapData from "./CityStatDataMapper";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    LinearProgress,
    Link
} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import TableStatStub from "./TableStatStubContainer";

export default ({t, dt, i18n, classes, data: {cityStatData, loading, error}, ad}) => {
    if (loading) {
        return <TableStatStub/>;
    }
    if (error) {
        return null;
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("city")}</TableCell>
                        <TableCell className={classes.hiddenOnMobile}>{t("region")}</TableCell>
                        <TableCell className={classes.hiddenOnMobile}>{t("country")}</TableCell>
                        <TableCell colSpan={2} className={classes.amountHeader}>{t("amount")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mapData(cityStatData, dt, i18n.language).map((city, index) => (
                        <React.Fragment key={index}>
                            {index === 50 && cityStatData.length > 60 && (
                                <tr key="ad_row">
                                    <td colSpan={5} className={classes.adCell}>{ad}</td>
                                </tr>
                            )}
                            <TableRow>
                                <TableCell component="th" scope="row">{city.cityName}</TableCell>
                                <TableCell className={classes.hiddenOnMobile}>
                                    {city.regionName && (
                                        <Link to={city.regionUrl} component={RouterLink}>{city.regionName}</Link>
                                    )}
                                </TableCell>
                                <TableCell className={classes.hiddenOnMobile}>
                                    <Link to={city.countryUrl} component={RouterLink} className={classes.flagLink}>
                                        {city.countryFlag && (
                                            <img src={city.countryFlag} className={classes.flag} alt=""/>
                                        )}
                                        {city.countryName}
                                    </Link>
                                </TableCell>
                                <TableCell className={classes.amountCell}>{city.amount}</TableCell>
                                <TableCell className={classes.shareCell}>
                                    <LinearProgress color="secondary" variant="determinate" value={city.share}/>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
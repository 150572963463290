export default (hostInfo, dt) => {
    if (!hostInfo) {
        return;
    }

    return {
        email: hostInfo.user.email,
        postalAddress: dt("postalAddress", hostInfo.translations)
    };
}

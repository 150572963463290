export default (data, language) => {
    if (!data) {
        return [];
    }

    return data.map((scan) => ({
        url: `/${language}${scan.url}`,
        name: scan.code,
    }));
};

import gql from "graphql-tag";

export default gql`
  query {
    blogPosts(limit: 3) {
      list {
        sort      
        url
        previewUrl
        pictureUrl
        created
        modified
        translations {
          language
          title
          introduction
        }
      }
    } 
    items(limit: 5) {
      list {
        url
        code
        created
        modified
        twistOff
        since
        companies {
          website
          translations {
            language
            shortName
          }
        }
        beverages {
          color
          translations {
            language
            name
          }
        }
        brands {
          translations {
            language
            name
          }
        }
        locations {
          country {
            flagUrl
            url
            translations {
              language
              name
            }
          }
          region {
            url
            translations {
              language
              name
            }
          }
          city {
            translations {
              language
              name
            }
          }
        }
        sets {
          url
          translations {
            language
            name
          }
        }
        marks {
          markFileUrl
          translations {
            language
            name
          }
        }
        pictures {
          pictureType
          previewUrl
          pictureUrl
        }
        translations {
          language
          comments
        }
      }
    }
  }`;
import {roundTo} from "../lib/numberHelper";

export default (countryStatData, dt, language) => {
    if (!countryStatData) {
        return;
    }

    const maxAmount = countryStatData[0].amount;

    return countryStatData
        .filter((statData) => statData.amount > 0)
        .map((statData) => ({
            countryName: dt("name", statData.location.translations),
            countryUrl: `/${language}${statData.location.url}`,
            countryFlag: statData.location.flagUrl,
            continentName: dt("name", statData.location.continent.translations),
            continentUrl: `/${language}${statData.location.continent.mapUrl}`,
            amount: statData.amount,
            share: roundTo(statData.amount * 100 / maxAmount, 1),
        }));
}

import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import compose from "recompose/compose";
import TradeGallery from "./TradeGallery";
import styles from "./TradeGalleryStyles";
import {connect} from "react-redux";
import {addBasketItem, deleteBasketItem} from "../redux/actions/trade";
import {withApollo} from "react-apollo";

export default compose(
    withStyles(styles),
    withTranslation(),
    withApollo,
    connect(
        state => ({
            basket: state.trade.basket,
            user: state.common.user,
        }),
        {
            addBasketItem,
            deleteBasketItem,
        }
    ),
)(TradeGallery);
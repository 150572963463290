import React from "react";
import {TextField} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";

export default ({classes, showMessage}) =>
    <React.Fragment>
        {showMessage && <Alert severity="info" className={classes.message}>
            <Skeleton variant="text" width={"70%"}/>
        </Alert>}
        <TextField
            multiline
            rowsMax="4"
            variant="outlined"
            disabled
            className={classes.userText}
        />
    </React.Fragment>;
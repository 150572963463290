import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import mapData from "./TradeScanListPageDataMapper";
import TradeScanList from "./components/TradeScanList";
import NotFoundPage from "./NotFoundPageContainer";

export default ({t, dt, i18n, setPageTitle, setBreadcrumbs, data: {tradeScans, loading, error}, ad}) => {
    useEffect(() => {
        setPageTitle(t("more_scans"));
        setBreadcrumbs([
            {
                name: t("trade"),
                link: `/${i18n.language}/trade/`,
            }, {
                name: t("more_scans"),
            }
        ]);
    }, [setPageTitle, setBreadcrumbs, t, i18n]);

    if (error) {
        return <NotFoundPage/>;
    }

    const getLeftContent = () => null;

    const getCentralContent = () =>
        <TradeScanList data={mapData(tradeScans, dt, i18n.language)}
                       numPages={tradeScans && tradeScans.numPages}
                       page={tradeScans && tradeScans.page}
                       baseUrl={`/${i18n.language}/trade/scans/`}
                       ad={ad}
                       loading={loading}/>;

    return <Page leftColContent={getLeftContent()} centralColContent={getCentralContent()}/>;
};
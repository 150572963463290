import React from "react";
import {AppBar, Tabs, Tab} from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/Photo";
import TwistOffIcon from "./TwistOffIconContainer";
import ZoomablePicture from "./ZoomablePictureContainer";

export default ({t, pictures, classes, itemCode, twistOff, originalAsPreview = false}) => {
    const getDefaultPictureType = () => {
        for (let picture of pictures) {
            if (!picture.default) {
                continue;
            }

            return picture.pictureType;
        }

        return "main";
    };

    const [activeTab, setActiveTab] = React.useState(`${itemCode}${getDefaultPictureType()}`);

    const handleChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    if (!pictures) {
        return <PhotoIcon/>
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.root}
                 style={
                     originalAsPreview
                         ? {
                             width: "fit-content",
                             maxWidth: "none",
                         }
                         : {}
                 }>
                {pictures.filter(picture => activeTab === `${itemCode}${picture.pictureType}`).map((picture, index) =>
                    <div className={classes.pictureWrapper} key={index}>
                        <ZoomablePicture src={picture.preview}
                                         originalUrl={picture.original}
                                         role="tabpanel"
                                         hidden={activeTab !== `${itemCode}${picture.pictureType}`}
                                         id={`nav-tabpanel-${itemCode}${picture.pictureType}`}
                                         originalAsPreview={originalAsPreview}/>
                        {twistOff && <TwistOffIcon/>}
                    </div>
                )}
                {pictures.length > 1 && (
                    <AppBar position="static"
                            elevation={0}
                            color="inherit">
                        <Tabs
                            centered
                            value={activeTab}
                            onChange={handleChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                        >
                            {pictures.map(picture =>
                                <Tab component="a"
                                     onClick={event => {
                                         event.preventDefault();
                                     }}
                                     label={t(picture.pictureType)}
                                     value={`${itemCode}${picture.pictureType}`}
                                     id={`nav-tab-${itemCode}${picture.pictureType}`}
                                     key={`${itemCode}${picture.pictureType}`}
                                     className={classes.pictureTypeTab}
                                />
                            )}
                        </Tabs>
                    </AppBar>
                )}
            </div>
        </div>
    );
}
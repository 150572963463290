import {roundTo} from "../lib/numberHelper";

export default (cityStatData, dt, language) => {
    if (!cityStatData) {
        return;
    }

    const maxAmount = cityStatData[0].amount;

    return cityStatData
        .filter((statData) => statData.amount > 0)
        .map((statData) => ({
            cityName: dt("name", statData.location.translations),
            regionName: statData.location.region && dt("name", statData.location.region.translations),
            regionUrl: statData.location.region && `/${language}${statData.location.region.url}`,
            countryName: dt("name", statData.location.country.translations),
            countryUrl: `/${language}${statData.location.country.url}`,
            countryFlag: statData.location.country.flagUrl,
            amount: statData.amount,
            share: roundTo(statData.amount * 100 / maxAmount, 1),
        }));
}

import gql from "graphql-tag";

export default gql`
  mutation {
    deleteTokenCookie {
      deleted
    }
    deleteRefreshTokenCookie {
      deleted
    }
  }`;
import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import {mapBlogPostData, mapSectionData} from "./BlogPostListPageDataMapper";
import BlogPostList from "./components/BlogPostList";
import LeftSideBlogSectionListWidget from "./components/LeftSideBlogSectionListWidgetContainer";
import NotFoundPage from "./NotFoundPageContainer";

export default ({t, dt, i18n, setPageTitle, setBreadcrumbs, data: {blogPosts, sectionData, loading, error}, match: {params}, ad}) => {
    useEffect(() => {
        let breadcrumbItems = [];
        if (params.sectionCode) {
            breadcrumbItems.push({
                name: t("blog"),
                link: `/${i18n.language}/blog/`,
            });
            const currentSection = mapSectionData(sectionData, dt);
            breadcrumbItems.push({
                name: currentSection.title,
            });
            setPageTitle(currentSection.title, loading);
        } else {
            breadcrumbItems.push({
                name: t("blog"),
            });
            setPageTitle(t("blog"));
        }
        setBreadcrumbs(breadcrumbItems);
    }, [setBreadcrumbs, setPageTitle, params.sectionCode, i18n, loading, t, dt, sectionData]);

    if (error) {
        return <NotFoundPage/>;
    }

    const getLeftContent = () => <LeftSideBlogSectionListWidget/>;

    const getCentralContent = () =>
        <BlogPostList data={mapBlogPostData(blogPosts, dt, i18n.language)}
                      numPages={blogPosts && blogPosts.numPages}
                      page={blogPosts && blogPosts.page}
                      baseUrl={sectionData
                          ? `/${i18n.language}${sectionData.url}`
                          : `/${i18n.language}/blog/`
                      }
                      ad={ad}
                      loading={loading}
        />;

    return <Page leftColContent={getLeftContent()} centralColContent={getCentralContent()}/>;
};
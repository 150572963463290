export const mapCounterpartyData = (data, dt) => {
    if (!data) {
        return;
    }

    return data.list.map(counterparty => ({
        email: counterparty.email,
        website: counterparty.website,
        zipCode: counterparty.zipCode,
        photo: counterparty.photoUrl,
        preview: counterparty.previewUrl,
        name: dt("fullName", counterparty.translations),
        postAddress: dt("postAddress", counterparty.translations),
        comments: dt("comments", counterparty.translations),
        location: counterparty.location
            ? [
                counterparty.location.city ? dt("name", counterparty.location.city.translations) : "",
                counterparty.location.region ? dt("name", counterparty.location.region.translations) : "",
                counterparty.location.country ? dt("name", counterparty.location.country.translations) : "",
            ].filter(part => part).join(", ")
            : "",
        flag: counterparty.location && counterparty.location.country
            ? counterparty.location.country.flag
            : "",
    }));
};

export const mapMenuData = (data, t, language) => {
    if (!data) {
        return [];
    }

    let contactsMenu = [];
    if (data["thanks"]) {
        contactsMenu.push({
            url: `/${language}/thanks/`,
            name: t("thanks"),
            amount: data["thanks"],
        });
    }
    if (data["websites"]) {
        contactsMenu.push({
            url: `/${language}/links/`,
            name: t("links"),
            amount: data["websites"],
        });
    }
    if (data["blacklist"]) {
        contactsMenu.push({
            url: `/${language}/blacklist/`,
            name: t("black_list"),
            amount: data["blacklist"],
        });
    }

    return contactsMenu;
};

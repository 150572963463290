import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import {withTheme} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import compose from "recompose/compose";
import QUERY_DYNAMIC_DATA from "./DynamicChartGraphql";
import DynamicWidget from "./LeftSideDynamicWidget";

export default compose(
    withRouter,
    withTranslation(),
    withTheme,
    graphql(QUERY_DYNAMIC_DATA),
)(DynamicWidget);
import {OPEN_MOBILE_MENU, CLOSE_MOBILE_MENU, TOGGLE_MOBILE_SUBMENU} from "../actionTypes";

export const openMobileMenu = () => ({
    type: OPEN_MOBILE_MENU,
});

export const closeMobileMenu = () => ({
    type: CLOSE_MOBILE_MENU,
});

export const toggleMobileSubmenu = itemCode => ({
    type: TOGGLE_MOBILE_SUBMENU,
    itemCode: itemCode,
});
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default () => (
    <React.Fragment>
        <p>
            <Skeleton variant="rect" width="65%" height={300}/>
        </p>
        {[...Array(3).keys()].map(
            (_, paragraphIndex) => (
                <p key={`paragraph_stub_${paragraphIndex}`}>
                    {[...Array(5).keys()].map(
                        (_, contentLineIndex) => (
                            <Skeleton key={`content_stub_${contentLineIndex}`} variant="text" width="100%"/>
                        )
                    )}
                    <Skeleton variant="text" width="30%"/>
                </p>
            )
        )}
    </React.Fragment>
);
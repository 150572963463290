import React, {useEffect} from "react";
import {Badge} from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {useHistory} from "react-router-dom";

export default ({i18n, user, setBasket, button, fontSize, badgeVariant, data: {basket: initBasket}, basket}) => {
    const Button = button;
    const history = useHistory();

    const clickHandler = () => {
        if (!user) {
            return;
        }
        history.push(`/${i18n.language}/trade/basket/`);
    };

    useEffect(() => {
        setBasket(user ? initBasket : []);
    }, [user, initBasket, setBasket]);

    return (
        <Button disabled={!user} onClick={clickHandler}>
            <Badge color="secondary" badgeContent={basket && basket.length} variant={badgeVariant}>
                <ShoppingCartIcon fontSize={fontSize}/>
            </Badge>
        </Button>
    );
}
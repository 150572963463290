import React from "react";
import Pagination from "./PaginationContainer";
import {Typography, Link} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ContentAdWrapper from "./ContentAdWrapperContainer";
import LeftCoverCard from "./LeftCoverCardContainer";
import HorizontalCardListStub from "./HorizontalCardListStubContainer";
import {Helmet} from "react-helmet/es/Helmet";

export default ({classes, data, numPages, page, baseUrl, loading, ad}) => {
    if (loading) {
        return <HorizontalCardListStub cardAmount={20}/>;
    }
    if (!data) {
        return null;
    }

    const contactsComponent = (location, flag, website) => (
        <React.Fragment>
            {location
                ? <Typography className={classes.flagTitle}><img src={flag} alt=""/>{location}</Typography>
                : null
            }
            {website
                ? <Link href={website} target="_blank" color="secondary">{website}</Link>
                : null
            }
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {page > 1 && (
                <Helmet>
                    <link rel="canonical" href={baseUrl}/>
                </Helmet>
            )}
            {data.map((counterparty, index) => (
                <React.Fragment key={index}>
                    {index === 10 && data.length > 15
                        ? <ContentAdWrapper ad={ad}/>
                        : null
                    }
                    <LeftCoverCard
                        title={counterparty.name}
                        subTitle={contactsComponent(counterparty.location, counterparty.flag, counterparty.website)}
                        preview={counterparty.preview}
                        picture={counterparty.photo}
                        content={counterparty.comments}
                        url={counterparty.website}
                        noPicture={<PersonIcon/>}/>
                </React.Fragment>
            ))}
            <Pagination numPages={numPages} page={page} baseUrl={baseUrl}/>
        </React.Fragment>
    );
};
import {lighten} from "@material-ui/core";

export default theme => ({
    card: {
        marginBottom: 25,
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up(400)]: {
            flexDirection: "row",
        },
        "& > .MuiSvgIcon-root": {
            minWidth: 151,
            width: 151,
            height: 151,
            color: lighten(theme.palette.primary.main, 0.8),
            margin: "0 auto",
            [theme.breakpoints.up(400)]: {
                margin: 0,
            },
        },
    },
    details: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    content: {
        flex: "1 0 auto",
        paddingTop: 0,
    },
    pictureWrapper: {
        maxHeight: 500,
        overflow: "hidden",
        [theme.breakpoints.up(400)]: {
            minWidth: 151,
            width: 151,
            maxHeight: 220,
        }
    },
    picture: {
        width: "fit-content",
        margin: "auto",
        objectFit: "cover",
        [theme.breakpoints.up(400)]: {
            width: "100%",
            height: "100%",
        }
    }
});
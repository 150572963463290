import {connect} from "react-redux";
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import {withTheme} from "@material-ui/core/styles";
import {withRouter} from "react-router-dom";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import QUERY_MENU_DATA from "./StatPageGraphql";
import StatPage from "./StatPage";

export default compose(
    withRouter,
    withTranslation(),
    withTheme,
    graphql(QUERY_MENU_DATA),
    connect(
        null,
        {
            setPageTitle,
            setBreadcrumbs
        }
    ),
)(StatPage);
import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import CountryList from "./components/CountryListContainer";
import mapData from "./CollectionPageDataMapper";
import LeftSideListWidget from "./components/LeftSideListWidgetContainer";
import LeftSideDynamicWidget from "./components/LeftSideDynamicWidgetContainer";

export default ({t, dt, i18n, setPageTitle, setBreadcrumbs, data: {groupedCountries, totalItemAmount, loading}, ad}) => {
    const mappedData = mapData(groupedCountries, t, dt, i18n.language, totalItemAmount);

    useEffect(() => {
        const pageTitle = t("collection");
        setPageTitle(pageTitle);
        setBreadcrumbs([{
            name: pageTitle,
        }]);
    }, [setPageTitle, setBreadcrumbs, i18n, t]);

    const getLeftContent = () =>
        <React.Fragment>
            <LeftSideDynamicWidget title={t("dynamic")}/>
            <LeftSideListWidget title={t("continents")}
                                listData={mappedData.map(continent => ({
                                    url: `#${continent.code}`,
                                    name: continent.name,
                                    amount: continent.itemAmount,
                                }))}
                                loading={loading}/>
        </React.Fragment>;

    const getCentralContent = () => <CountryList groupedCountries={mappedData} ad={ad} loading={loading}/>;

    return <Page leftColContent={getLeftContent()} centralColContent={getCentralContent()}/>;
};
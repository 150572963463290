export default theme => ({
    itemCard: {
        marginBottom: 25,
        display: "flex",
        flexFlow: "column",
        [theme.breakpoints.up(400)]: {
            flexFlow: "row",
            height: 150,
        },
    },
    content: {
        width: "100%",
        "& .MuiSkeleton-text": {
            maxWidth: "100%",
        }
    },
    media: {
        margin: "0 auto",
        width: "100%",
        maxWidth: "100%",
        height: 200,
        [theme.breakpoints.up(400)]: {
            width: 150,
            maxWidth: 150,
            minWidth: 150,
        },
        "& .MuiSkeleton-rect": {
            width: "100%",
            height: "100%",
        }
    },
});
import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import caLocale from "date-fns/locale/ca";
import deLocale from "date-fns/locale/de";
import esLocale from "date-fns/locale/es";
import itLocale from "date-fns/locale/it";
import nlLocale from "date-fns/locale/nl";
import plLocale from "date-fns/locale/pl";
import ptLocale from "date-fns/locale/pt";
import ukLocale from "date-fns/locale/uk";
import moment from "moment";

const DATE_FNS_LOCALE_MAP = {
    ca: caLocale,
    de: deLocale,
    en: enLocale,
    es: esLocale,
    fr: frLocale,
    it: itLocale,
    nl: nlLocale,
    pl: plLocale,
    pt: ptLocale,
    ru: ruLocale,
    uk: ukLocale,
}

const DATE_FORMAT_LOCALE_MAP = {
    ca: "d/M/yyyy",
    de: "d.M.yyyy",
    en: "M/d/yyyy",
    es: "d/M/yyyy",
    fr: "dd/MM/yyyy",
    it: "d/M/yyyy",
    nl: "d-M-yyyy",
    pl: "d.MM.yyyy",
    pt: "dd/MM/yyyy",
    ru: "dd.MM.yyyy",
    uk: "dd.MM.yyyy",
};

export const getDateFnsLocale = language => DATE_FNS_LOCALE_MAP[language] || enLocale;

export const getDateFormat = locale => {
    if (locale in DATE_FORMAT_LOCALE_MAP) {
        return DATE_FORMAT_LOCALE_MAP[locale];
    }
    return DATE_FORMAT_LOCALE_MAP["en"];
}

export const timestampToDate = (timestamp, language) => {
    if (!Number.isInteger(timestamp)) {
        return timestamp;
    }

    return new Intl.DateTimeFormat(language).format(new Date(timestamp * 1000));
};

export const formatDateTime = (dateTime, language) => {
    return new Intl.DateTimeFormat(language).format(new Date(dateTime));
};

export const getMaxItemCreated = items => {
    if (!items) {
        return null;
    }
    return items.list
        .map(item => item.created)
        .reduce(
            (minCreated, created) => moment(created) < moment(minCreated) ? moment(created) : minCreated,
            moment(10000000000000)
        );
};

export const getMinItemCreated = items => {
    if (!items) {
        return null;
    }
    return items.list
        .map(item => item.created)
        .reduce(
            (maxCreated, created) => moment(created) > moment(maxCreated) ? moment(created) : maxCreated,
            moment(0)
        );
};
export default theme => ({
    amountHeader: {
        textAlign: "center",
    },
    shareCell: {
        minWidth: 100,
    },
    amountCell: {
        textAlign: "right",
    },
    flag: {
        verticalAlign: "text-bottom",
        marginRight: 5,
    },
    flagLink: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 150,
        display: "inline-block",
        textOverflow: "ellipsis",
    },
    hiddenOnMobile: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "table-cell",
        }
    },
    adCell: {
        padding: "0!important",
    }
});
import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import TradeGallery from "./components/TradeGalleryContainer";
import {mapItemData} from "./TradeCountryPageDataMapper";
import LeftSideTradeScanBasketWidget from "./components/LeftSideTradeScanBasketWidgetContainer";
import NotFoundPage from "./NotFoundPageContainer";

export default ({t, dt, i18n, setPageTitle, setBreadcrumbs, data: {items, loading, error}}) => {
    useEffect(() => {
        const pageName = t("my_basket");
        setBreadcrumbs([
            {
                name: t("trade"),
                link: `/${i18n.language}/trade/`,
            }, {
                name: pageName,
                link: undefined,
            }
        ]);
        setPageTitle(pageName);
    }, [setBreadcrumbs, setPageTitle, t, i18n]);

    const getBaseUrl = () => `/${i18n.language}/trade/basket/`;

    if (error) {
        return <NotFoundPage/>;
    }

    const getLeftContent = () => <LeftSideTradeScanBasketWidget/>;

    const getCentralContent = () =>
        <TradeGallery items={mapItemData(items, dt)}
                      numPages={items && items.numPages}
                      page={items && items.page}
                      baseUrl={getBaseUrl()}
                      loading={loading}/>;

    return <Page leftColContent={getLeftContent()} centralColContent={getCentralContent()}/>;
};
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_SET_AMOUNTS from "./LeftSideCompanySetListWidgetGraphql";
import LeftSideCompanySetListWidget from "./LeftSideCompanySetListWidget";
import withDataTranslation from "../lib/translationHelper";

export default compose(
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_SET_AMOUNTS, {
        options: ({setClientId}) => ({
            variables: {
                setClientId: setClientId,
            }
        })
    }),
)(LeftSideCompanySetListWidget);
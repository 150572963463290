import React, {useEffect} from "react";
import Map from "./components/MapContainer"
import Page from "./components/PageContainer";
import LeftSideListWidget from "./components/LeftSideListWidgetContainer";
import makeDataMapping from "./MapPageDataMapper";
import NotFoundPage from "./NotFoundPageContainer";

const staticMaps = {
    "eu": require("./maps/eu.svg"),
    "as": require("./maps/as.svg"),
    "na": require("./maps/na.svg"),
    "sa": require("./maps/sa.svg"),
    "af": require("./maps/af.svg"),
    "oc": require("./maps/oc.svg"),
    "chn": require("./maps/as/chn.svg"),
    "deu": require("./maps/eu/deu.svg"),
    "esp": require("./maps/eu/esp.svg"),
    "fra": require("./maps/eu/fra.svg"),
    "pol": require("./maps/eu/pol.svg"),
    "rus": require("./maps/eu/rus.svg"),
    "rus-center": require("./maps/eu/rus/center.svg"),
    "rus-far-east": require("./maps/eu/rus/far-east.svg"),
    "rus-north-caucasus": require("./maps/eu/rus/north-caucasus.svg"),
    "rus-northwest": require("./maps/eu/rus/northwest.svg"),
    "rus-siberia": require("./maps/eu/rus/siberia.svg"),
    "rus-south": require("./maps/eu/rus/south.svg"),
    "rus-ural": require("./maps/eu/rus/ural.svg"),
    "rus-volga": require("./maps/eu/rus/volga.svg"),
    "ukr": require("./maps/eu/ukr.svg"),
    "usa": require("./maps/na/usa.svg"),
    "usa-north-east": require("./maps/na/usa/north-east.svg"),
    "usa-mid-west": require("./maps/na/usa/mid-west.svg"),
    "usa-west": require("./maps/na/usa/west.svg"),
    "usa-south": require("./maps/na/usa/south.svg"),
    "can": require("./maps/na/can.svg"),
    "jpn": require("./maps/as/jpn.svg"),
    "jpn-tohoku": require("./maps/as/jpn/tohoku.svg"),
    "jpn-kanto": require("./maps/as/jpn/kanto.svg"),
    "jpn-chubu": require("./maps/as/jpn/chubu.svg"),
    "jpn-kyushu": require("./maps/as/jpn/kyushu.svg"),
    "jpn-chugoku": require("./maps/as/jpn/chugoku.svg"),
    "jpn-shikoku": require("./maps/as/jpn/shikoku.svg"),
    "jpn-kansai": require("./maps/as/jpn/kansai.svg"),
    "aus": require("./maps/oc/aus.svg"),
    "ita": require("./maps/eu/ita.svg"),
};

export default ({t, dt, i18n, setPageTitle, setBreadcrumbs, data: {mapData, locationAncestors, loading, error}, match: {params}}) => {
    useEffect(() => {
        let current_location;
        let title = "";
        if (locationAncestors && locationAncestors.length > 0) {
            current_location = locationAncestors[locationAncestors.length - 1];
            title = `${dt("name", current_location.translations)} – ${t("map")}`;
        }
        if (params.macroRegion) {
            current_location = null;
            title = `${t(`map_title_${params.macroRegion}`)} – ${t("map")}`;
        }
        if (!params.continent) {
            title = t("world_map");
        }
        setPageTitle(title, loading);

        let breadcrumbItems = [{
            name: t("world_map"),
            link: `/${i18n.language}/maps/`,
        }];

        if (locationAncestors) {
            locationAncestors.forEach(location => (
                breadcrumbItems.push({
                    name: dt("name", location.translations),
                    link: location !== current_location ? `/${i18n.language}${location.mapUrl}` : undefined,
                })
            ));
        }
        if (params.macroRegion) {
            breadcrumbItems.push({
                name: t(`map_title_${params.macroRegion}`),
                link: undefined,
            });
        }
        setBreadcrumbs(breadcrumbItems);
    }, [
        loading, setPageTitle, setBreadcrumbs, locationAncestors, params.continent, params.macroRegion, dt,
        i18n.language, t
    ]);

    if (error) {
        return <NotFoundPage/>;
    }

    const getSideTitle = () => {
        if (params.macroRegion || params.country) {
            return t("regions");
        } else if (params.continent) {
            return t("countries");
        } else {
            return t("continents");
        }
    };

    const getMapStatic = () => staticMaps[`${params.country}-${params.macroRegion}`]
        || staticMaps[params.country]
        || staticMaps[params.continent]
        || require("./maps/world.svg");

    const leftContent = <LeftSideListWidget title={getSideTitle()}
                                            listData={makeDataMapping(mapData, dt, i18n.language)}
                                            loading={loading}/>;
    const centralContent = <Map mapData={mapData} mapStatic={getMapStatic()} loading={loading}/>;

    return <Page leftColContent={leftContent} centralColContent={centralContent}/>;
}
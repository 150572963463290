import gql from "graphql-tag";

export default gql`
  query ($code: String!) {
    tradeScanData(code: $code) {
      code
      modified
      pictureUrl
      originalWidth
      originalHeight
      translations {
        language
        name
      }
    }
  }`;
import gql from "graphql-tag";

export default gql`
  query ($setClientId: Int, $countryCode: String){
    countrySets(setClientId: $setClientId, countryCode: $countryCode) {
      amount
      set {
        url
        translations {
          name
          language
        }
      }
    }
  }`;
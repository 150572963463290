import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs} from "./redux/actions";
import NotFoundPage from "./NotFoundPage";
import {withStyles} from "@material-ui/core/styles";
import styles from "./NotFoundPageStyles";

export default compose(
    withStyles(styles),
    withTranslation(),
    connect(
        null,
        {
            setBreadcrumbs
        }
    ),
)(NotFoundPage);
import React from "react";
import {Paper} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Alert from "@material-ui/lab/Alert";

export default ({classes}) => (
    <React.Fragment>
        <Alert severity="warning" className={classes.message}>
            <Skeleton variant="text" width="100%"/>
            <Skeleton variant="text" width="20%"/>
        </Alert>
        <Paper className={classes.previews}>
            {[...Array(50)].map((_, index) =>
                <Skeleton variant="rect" key={index}/>
            )}
        </Paper>
    </React.Fragment>
);
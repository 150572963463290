import React from "react";
import {Button} from "@material-ui/core";

export default ({t, user, editUrl, classes}) =>
    user && user.isOwner && (
        <div className={classes.root}>
            <Button variant="contained" color="secondary" href={editUrl}>
                {t("edit")}
            </Button>
        </div>
    )
import {combineReducers} from "redux";
import common from "./common";
import mobileMenu from "./mobileMenu";
import trade from "./trade";

export default combineReducers({
    common,
    mobileMenu,
    trade,
});

import React from "react";
import {KeyboardDatePicker} from "@material-ui/pickers";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {getDateFormat} from "../lib/dateHelper";
import DateFnsUtils from "@date-io/date-fns";
import {getDateFnsLocale} from "../lib/dateHelper";

export default ({label, value, onChange, minDate, maxDate, t, i18n, classes}) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFnsLocale(i18n.language)}>
        <KeyboardDatePicker
            autoOk
            disableToolbar
            format={getDateFormat(i18n.language)}
            variant="inline"
            margin="normal"
            label={label}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange}
            className={classes.root}
            inputProps={{readOnly: true}}
            maxDateMessage={t("date_after_max_message")}
            minDateMessage={t("date_before_min_message")}
        />
    </MuiPickersUtilsProvider>
)
export default theme => ({
    root: {
        padding: "15px 0",
        "& a": {
            color: theme.palette.text.primary,
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
            }
        }
    }
});
import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import SetList from "./components/SetListContainer";
import mapData from "./SetListPageDataMapper";
import LeftSideListWidget from "./components/LeftSideListWidgetContainer";
import NotFoundPage from "./NotFoundPageContainer";

export default ({t, dt, i18n, setPageTitle, setBreadcrumbs, data: {groupedSets, countrySets, loading, error}, ad}) => {
    const mappedData = mapData(groupedSets, countrySets, t, dt, i18n.language);

    useEffect(() => {
        const pageTitle = t("sets");
        setPageTitle(pageTitle);
        setBreadcrumbs([
            {
                name: t("collection"),
                link: `/${i18n.language}/collection/`,
            }, {
                name: pageTitle,
                link: undefined,
            }
        ]);
    }, [setPageTitle, setBreadcrumbs, t, i18n]);

    if (error) {
        return <NotFoundPage/>;
    }

    const getLeftContent = () => <LeftSideListWidget
        title={t("countries")}
        listData={mappedData.map(country => ({
            url: `#${country.code}`,
            name: country.name,
            flag: country.flag,
        }))}
        loading={loading}/>;

    const getCentralContent = () => <SetList groupedSets={mappedData} ad={ad} loading={loading}/>;

    return <Page leftColContent={getLeftContent()} centralColContent={getCentralContent()}/>;
};
import {connect} from "react-redux";
import {graphql, withApollo} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {QUERY_TRADE_SCAN_BASKET} from "./TradeScanBasketSelectionGraphql";
import TradeScanBasketSelection from "./TradeScanBasketSelection";
import {withStyles} from "@material-ui/core/styles";
import styles from "./TradeScanBasketSelectionStyles";

export default compose(
    withApollo,
    withStyles(styles),
    withTranslation(),
    graphql(QUERY_TRADE_SCAN_BASKET, {
        options: ({tradeScanCode}) => ({
            variables: {
                code: tradeScanCode,
            }
        })
    }),
    connect(
        state => ({
            user: state.common.user,
        })
    ),
)(TradeScanBasketSelection);
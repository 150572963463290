import {connect} from "react-redux";
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import QUERY_BASKET_DATA from "./BasketPageGraphql";
import BasketPage from "./BasketPage";
import withDataTranslation from "./lib/translationHelper";
import {withRouter} from "react-router-dom";

export default compose(
    withRouter,
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_BASKET_DATA, {
        options: ({match: {params}}) => ({
            variables: {
                page: params.page,
            }
        })
    }),
    connect(
        null,
        {
            setPageTitle,
            setBreadcrumbs
        }
    ),
)(BasketPage);
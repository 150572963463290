import moment from "moment";
import {timestampToDate} from "../lib/dateHelper";

export default (data, variant, t, language) => {
    if (!data) {
        return [];
    }

    if (variant === "last_month") {
        return data.map((itemAmount) => {
            const periodBegin = moment.unix(itemAmount.period);
            return {
                url: `/${language}/collection/created/${periodBegin.format("DD.MM.YYYY")}-${periodBegin.format("DD.MM.YYYY")}/`,
                name: timestampToDate(itemAmount.period, language),
                amount: itemAmount.amount,
            };
        });
    }
    if (variant === "last_year") {
        return data.map((itemAmount) => {
            const periodBegin = moment.unix(itemAmount.period);
            const periodEnd = moment.unix(itemAmount.period).month(periodBegin.month() + 1).add(-1, "days");
            const monthTranslation = t(`month${periodBegin.month() + 1}`);
            return {
                url: `/${language}/collection/created/${periodBegin.format("DD.MM.YYYY")}-${periodEnd.format("DD.MM.YYYY")}/`,
                name: `${monthTranslation} ${periodBegin.year()}`,
                amount: itemAmount.amount,
            };
        });
    }
    if (variant === "all") {
        return data.map((itemAmount) => {
            const periodBegin = moment.unix(itemAmount.period);
            const periodEnd = moment.unix(itemAmount.period).year(periodBegin.year() + 1).add(-1, "days");
            return {
                url: `/${language}/collection/created/${periodBegin.format("DD.MM.YYYY")}-${periodEnd.format("DD.MM.YYYY")}/`,
                name: periodBegin.year(),
                amount: itemAmount.amount,
            };
        });
    }

    return [];
};

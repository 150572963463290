import {sortByName} from "../lib/arrayHelper";

export default (data, t, dt, language, country) => {
    if (!data) {
        return [];
    }

    let result = data.map(region => ({
        url: `/${language}${region.location.url}`,
        name: dt("name", region.location.translations),
        amount: region.amount,
    }));

    sortByName(result);

    if (country) {
        const knownRegionAmount = data.reduce((sum, region) => sum + region.amount, 0);
        const unknownRegionAmount = country.itemAmount - knownRegionAmount;
        if (unknownRegionAmount && knownRegionAmount) {
            result.push({
                url: `/${language}/collection/region/unknown/${country.code}/`,
                name: t("unknown"),
                amount: unknownRegionAmount,
            });
        }

    }

    return result;
};

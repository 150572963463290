import {createMuiTheme} from "@material-ui/core";
import {defaultThemeObject} from "./default";

export default createMuiTheme({
    ...defaultThemeObject,
    palette: {
        primary: {
            main: "#516d3d",
        },
        secondary: {
            main: "#c9a200",
        },
        background: {
            default: "#fff",
            paper: "#fff",
        },
        text: {
            primary: "#030",
            secondary: "#333",
        }
    },
    mainContainer: {
        borderColor: "#eee",
        backgroundColor: "#a9bd86",
    }
});
import {SUPPORTED_LANGUAGES} from "./translationHelper";

export const MAP_CONTINENT_RE = "(eu|as|na|sa|af|oc)";
export const MAP_MACRO_REGION_RE = "(center|northwest|volga|south|north-caucasus|ural|siberia|far-east|north-east|west|mid-west|tohoku|kanto|chubu|kyushu|chugoku|shikoku|kansai)";
export const STAT_SECTION_RE = "(years|cities|conditions|companies|beverages|regions|brands|countries|counterparties)";
export const COUNTERPARTY_PUBLIC_TYPE_RE = "(thanks|links|blacklist)";
export const PAGE_EXCEPT_FIRST_RE = "([2-9]|[1-9]\\d{0,3})";
export const SLUG_RE = "([a-z0-9\\-]+)";
export const TRADE_SCAN_CODE_RE = "([a-z0-9\\-_]+)";
export const COUNTRY_CODE_RE = "([a-z]{3}|unknown)";
export const CLIENT_ID_RE = "(\\d+)";
export const REGION_CLIENT_ID_RE = "(\\d+|[a-z]{3}|unknown)";
export const DATE_RE = "([0-3]\\d\\.[0-1]\\d\\.[1-2]\\d{3})";

export const getLangRegExp = languages => `(${languages.join("|")})`;

export const getQueryParam = (query, param) => (new URLSearchParams(query)).get(param);

export const getCurrentHost = () => `${window.location.protocol}//${window.location.hostname}`

export const internalLinkClickHandler = history => event => {
    if (event.target.tagName !== "A") {
        return;
    }
    if (!event.target.href.startsWith(window.location.origin)) {
        return;
    }
    const baseUrl = event.target.href.substr(window.location.origin.length);
    if (!baseUrl.match(new RegExp(`^/(${getLangRegExp(SUPPORTED_LANGUAGES)}/.*)?$`))) {
        return;
    }

    history.push(baseUrl);
    event.preventDefault();
}
import {sortByName} from "./lib/arrayHelper";

export default (data, t, language) => {
    if (!data) {
        return;
    }

    let result = [];
    if (data["stats"]["beverages"]) {
        result.push({
            name: t("beverages"),
            url: `/${language}/stats/beverages/`,
            amount: data["stats"]["beverages"]
        })
    }
    if (data["stats"]["brands"]) {
        result.push({
            name: t("brands"),
            url: `/${language}/stats/brands/`,
            amount: data["stats"]["brands"]
        })
    }
    if (data["stats"]["companies"]) {
        result.push({
            name: t("companies"),
            url: `/${language}/stats/companies/`,
            amount: data["stats"]["companies"]
        })
    }
    if (data["stats"]["cities"]) {
        result.push({
            name: t("cities"),
            url: `/${language}/stats/cities/`,
            amount: data["stats"]["cities"]
        })
    }
    if (data["stats"]["regions"]) {
        result.push({
            name: t("regions"),
            url: `/${language}/stats/regions/`,
            amount: data["stats"]["regions"]
        })
    }
    if (data["stats"]["countries"]) {
        result.push({
            name: t("countries"),
            url: `/${language}/stats/countries/`,
            amount: data["stats"]["countries"]
        })
    }
    if (data["stats"]["conditions"]) {
        result.push({
            name: t("quality"),
            url: `/${language}/stats/conditions/`,
            amount: data["stats"]["conditions"]
        })
    }
    if (data["stats"]["years"]) {
        result.push({
            name: t("years"),
            url: `/${language}/stats/years/`,
            amount: data["stats"]["years"]
        })
    }
    if (data["stats"]["counterparties"]) {
        result.push({
            name: t("collectors"),
            url: `/${language}/stats/counterparties/`,
            amount: data["stats"]["counterparties"]
        })
    }
    sortByName(result);

    return result;
}

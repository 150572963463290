export default theme => ({
    root: {
        "& .recharts-surface": {
            overflow: "visible",
        },
        "& .recharts-pie-label-line": {
            strokeWidth: 1.5,
        }
    },
    innerLabel: {
        fill: "#888",
        mixBlendMode: "difference",
    },
    outerLabel: {
        fill: theme.palette.text.primary,
    }
});
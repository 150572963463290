import React, {useEffect} from "react";
import Contacts from "./components/ContactsContainer";
import Page from "./components/PageContainer";
import LeftSideDynamicWidget from "./components/LeftSideDynamicWidgetContainer";
import StandalonePage from "./components/StandalonePageContentContainer";

export default ({t, i18n, setPageTitle, setBreadcrumbs, ad}) => {
    useEffect(() => {
        setPageTitle(t("about_me"));
        setBreadcrumbs([{
            name: t("about_me"),
            undefined,
        }]);
    }, [i18n, setBreadcrumbs, setPageTitle, t]);

    const centralContent = (
        <React.Fragment>
            <StandalonePage pageType="about"/>
            {ad}
            <h2>{t("contacts")}</h2>
            <Contacts/>
        </React.Fragment>
    );

    const leftContent = <LeftSideDynamicWidget title={t("statistic")}/>;

    return <Page leftColContent={leftContent} centralColContent={centralContent}/>;
};
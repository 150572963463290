import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_PAGE_CONTENT from "./StandalonePageContentGraphql";
import StandalonePage from "./StandalonePageContent";
import withDataTranslation from "../lib/translationHelper";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

export default compose(
    withRouter,
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_PAGE_CONTENT, {
        options: ({pageType}) => ({
            variables: {
                pageType: pageType,
            }
        })
    }),
    connect(
        state => ({
            user: state.common.user,
        })
    ),
)(StandalonePage);
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import {withTheme} from "@material-ui/core/styles";
import compose from "recompose/compose";
import QUERY_STAT_DATA from "./YearStatGraphql";
import YearStat from "./YearStat";

export default compose(
    withTheme,
    withTranslation(),
    graphql(QUERY_STAT_DATA)
)(YearStat);
export default () => ({
    root: {
        padding: 0,
        marginLeft: 5,
        minWidth: "auto",
        borderRadius: 8,
        "& .MuiButton-label": {
            padding: 5,
        }
    }
});
import React from "react";
import mapData from "./BrandStatDataMapper";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    LinearProgress
} from "@material-ui/core";
import TableStatStub from "./TableStatStubContainer";

export default ({t, dt, classes, data: {brandStatData, loading, error}, ad}) => {
    if (loading) {
        return <TableStatStub/>;
    }
    if (error) {
        return null;
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("brand")}</TableCell>
                        <TableCell colSpan={2} className={classes.amountHeader}>{t("amount")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mapData(brandStatData, dt).map((brand, index) => (
                        <React.Fragment key={index}>
                            {index === 50 && brandStatData.length > 60 && (
                                <tr key="ad_row">
                                    <td colSpan={3} className={classes.adCell}>{ad}</td>
                                </tr>
                            )}
                            <TableRow>
                                <TableCell component="th" scope="row">{brand.name}</TableCell>
                                <TableCell className={classes.amountCell}>{brand.amount}</TableCell>
                                <TableCell className={classes.shareCell}>
                                    <LinearProgress color="secondary" variant="determinate" value={brand.share}/>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
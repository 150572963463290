import {sortByName} from "../lib/arrayHelper";
import unknownFlag from "../img/unknown_flag.svg";

export default (data, t, dt, language, totalTradeItemAmount) => {
    if (!data) {
        return [];
    }

    let result = data.map(country => ({
        url: `/${language}${country.location.tradeUrl}`,
        flag: country.location.flagUrl,
        name: dt("name", country.location.translations),
        amount: country.amount,
    }));
    sortByName(result);

    const unknownAmount = totalTradeItemAmount - data.reduce((sum, country) => sum + country.amount, 0);
    if (unknownAmount > 0) {
        result.push({
            url: `/${language}/trade/country/unknown/`,
            flag: unknownFlag,
            name: t("unknown"),
            amount: unknownAmount,
        });
    }

    return result;
};

import gql from "graphql-tag";

export default gql`
  query ($setClientId: Int!){
    companySets(setClientId: $setClientId) {
      amount
      set {
        url
        translations {
          name
          language
        }
      }
    }
  }`;
export default () => ({
    itemWrapper: {
        position: "relative",
        padding: "5px 0",
    },
    itemTitle: {
        position: "absolute",
        left: 0,
        top: 0,
        background: "rgba(255, 255, 255, 0.6)",
        padding: 2,
        marginTop: 5,
        lineHeight: 1,
    },
    scanPreview: {
        width: "100%",
    }
});
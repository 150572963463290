export const SET_SITE_NAME = "SET_SITE_NAME";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_PAGE_TITLE = "SET_PAGE_TITLE";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";
export const SET_USER = "SET_USER";
export const OPEN_MOBILE_MENU = "OPEN_MOBILE_MENU";
export const CLOSE_MOBILE_MENU = "CLOSE_MOBILE_MENU";
export const TOGGLE_MOBILE_SUBMENU = "TOGGLE_MOBILE_SUBMENU";
export const SET_BASKET = "SET_BASKET";
export const ADD_BASKET_ITEM = "ADD_BASKET_ITEM";
export const DELETE_BASKET_ITEM = "DELETE_BASKET_ITEM";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";
import React, {useCallback, useEffect} from "react";
import Page from "./components/PageContainer";
import {mapItemData, mapLocationAncestorData, mapSetData} from "./ItemListPageDataMapper";
import CollectionGallery from "./components/CollectionGalleryContainer";
import moment from "moment";
import {formatDateTime, getMaxItemCreated, getMinItemCreated} from "./lib/dateHelper";
import LeftSideDynamicWidget from "./components/LeftSideDynamicWidgetContainer";
import LeftSideCreatedListWidget from "./components/LeftSideCreatedListWidgetContainer";
import LeftSideInsideAmountListWidget from "./components/LeftSideInsideAmountListWidgetContainer";
import LeftSideCompanySetListWidget from "./components/LeftSideCompanySetListWidgetContainer";
import LeftSideCountrySetListWidget from "./components/LeftSideCountrySetListWidgetContainer";
import LeftSideCustomCreatedWidget from "./components/LeftSideCustomCreatedWidgetContainer";
import LeftSideRegionListWidget from "./components/LeftSideRegionListWidgetContainer";
import LeftSideDefectiveAmountListWidget from "./components/LeftSideDefectiveAmountListWidgetContainer";
import NotFoundPage from "./NotFoundPageContainer";
import {getQueryParam} from "./lib/urlHelper";
import LeftSideSearchFilterWidget from "./components/LeftSideSearchFilterWidgetContainer";
import LeftSideBottleCapIndexWidget from "./components/LeftSideBottleCapIndexWidgetContainer";

export default ({
                    t,
                    dt,
                    i18n,
                    setPageTitle,
                    setBreadcrumbs,
                    data: {items, locationAncestors, set, loading, error},
                    ad,
                    match: {params},
                    location
                }) => {
    const formatDate = useCallback(
        date => formatDateTime(date, i18n.language),
        [i18n.language]
    );

    const createdFrom = params.createdFrom
        ? moment(params.createdFrom, "DD.MM.YYYY")
        : params.created === "created"
            ? getMinItemCreated(items)
            : null;
    const createdTill = params.createdTill
        ? moment(params.createdTill, "DD.MM.YYYY")
        : params.created === "created"
            ? getMaxItemCreated(items)
            : null;

    useEffect(() => {
        const mappedLocationAncestors = mapLocationAncestorData(locationAncestors, dt, i18n.language);
        let pageName;
        let breadcrumbs = [];

        if (mappedLocationAncestors && mappedLocationAncestors.length > 0) {
            breadcrumbs.push({
                name: t("collection"),
                link: `/${i18n.language}/collection/`,
            });
            if (params.inside) {
                breadcrumbs.push({
                    name: t("inside_pictures"),
                    link: `/${i18n.language}/collection/inside/`,
                });
                const country = mappedLocationAncestors.pop();
                breadcrumbs.push({
                    name: country.name,
                    link: undefined,
                });
                pageName = `${t("inside_pictures")}: ${country.name}`
            } else if (params.defective) {
                breadcrumbs.push({
                    name: t("defective"),
                    link: `/${i18n.language}/collection/defective/`,
                });
                const country = mappedLocationAncestors.pop();
                breadcrumbs.push({
                    name: country.name,
                    link: undefined,
                });
                pageName = `${t("defective")}: ${country.name}`
            } else {
                mappedLocationAncestors.map(location => breadcrumbs.push(location));
                pageName = mappedLocationAncestors.pop().name;
                if (params.regionClientId === "unknown") {
                    breadcrumbs.push({
                        name: t("unknown"),
                        link: undefined,
                    });
                    pageName += `: ${t("unknown")}`;
                }
            }
            setPageTitle(pageName, loading);
        } else if (params.itemCode) {
            pageName = `${t("code")}: ${params.itemCode}`;
            setPageTitle(pageName);
            breadcrumbs.push({
                name: t("collection"),
                link: `/${i18n.language}/collection/`,
            });
            breadcrumbs.push({
                name: pageName,
                link: undefined,
            });
        } else if (params.countryCode === "unknown") {
            pageName = t("unknown");
            setPageTitle(pageName);
            breadcrumbs.push({
                name: t("collection"),
                link: `/${i18n.language}/collection/`,
            });
            breadcrumbs.push({
                name: pageName,
                link: undefined,
            });
        } else if (params.setClientId) {
            const mappedSetData = mapSetData(set, dt);
            pageName = mappedSetData ? mappedSetData.name : "";
            setPageTitle(pageName, loading);
            breadcrumbs.push({
                name: t("collection"),
                link: `/${i18n.language}/collection/`,
            });
            breadcrumbs.push({
                name: t("sets"),
                link: `/${i18n.language}/collection/sets/`,
            });
            breadcrumbs.push({
                name: pageName,
                link: undefined,
            });
        } else if (params.defective) {
            pageName = t("defective");
            setPageTitle(pageName);
            breadcrumbs.push({
                name: t("collection"),
                link: `/${i18n.language}/collection/`,
            });
            breadcrumbs.push({
                name: pageName,
                link: undefined,
            });
        } else if (params.created && createdFrom && createdTill) {
            pageName = `${t("created")} ${formatDate(createdFrom)}`;
            if (createdFrom.date() !== createdTill.date()) {
                pageName += ` - ${formatDate(createdTill)}`;
            }
            setPageTitle(pageName);
            breadcrumbs.push({
                name: t("collection"),
                link: `/${i18n.language}/collection/`,
            });
            breadcrumbs.push({
                name: pageName,
                link: undefined,
            });
        } else if (params.inside) {
            pageName = `${t("inside_pictures")}`;
            breadcrumbs.push({
                name: t("collection"),
                link: `/${i18n.language}/collection/`,
            });
            breadcrumbs.push({
                name: pageName,
                undefined,
            });
            setPageTitle(pageName);
        } else if (params.search) {
            const q = getQueryParam(location.search, "q");
            if (q) {
                const by = getQueryParam(location.search, "by");
                pageName = `${t("search")}`;
                if (by) {
                    const translation = t(`by_${by}`);
                    if (translation) {
                        pageName += ` ${translation}`;
                    }
                }
                pageName += `: ${q}`;
                breadcrumbs.push({
                    name: pageName,
                    undefined,
                });
                setPageTitle(pageName);
            }
        }

        setBreadcrumbs(breadcrumbs);
    }, [
        locationAncestors, dt, t, i18n.language, setBreadcrumbs, setPageTitle, loading, createdFrom, createdTill,
        formatDate, set, params.countryCode, params.created, params.defective, params.inside, params.regionClientId,
        params.setClientId, params.search, params.itemCode, location.search
    ]);

    const getBaseUrl = () => {
        if (!params) {
            return;
        }
        if (params.inside) {
            if (params.countryCode) {
                return `/${i18n.language}/collection/inside/${params.countryCode}/`;
            }
            return `/${i18n.language}/collection/inside/`;
        }
        if (params.defective) {
            if (params.countryCode) {
                return `/${i18n.language}/collection/defective/${params.countryCode}/`;
            }
            return `/${i18n.language}/collection/defective/`;
        }
        if (params.regionClientId) {
            if (params.regionClientId === "unknown") {
                return `/${i18n.language}/collection/region/${params.regionClientId}/${params.countryCode}/`;
            }
            return `/${i18n.language}/collection/region/${params.regionClientId}/`;
        }
        if (params.countryCode) {
            return `/${i18n.language}/collection/country/${params.countryCode}/`;
        }
        if (params.setClientId) {
            return `/${i18n.language}/collection/set/${params.setClientId}/`;
        }
        if (params.created) {
            if (createdTill) {
                return `/${i18n.language}/collection/created/${createdFrom.format("DD.MM.YYYY")}-${createdTill.format("DD.MM.YYYY")}/`;
            }
            if (createdFrom) {
                return `/${i18n.language}/collection/created/${createdFrom.format("DD.MM.YYYY")}/`;
            }
            return `/${i18n.language}/collection/created/`;
        }
        if (params.search) {
            return `/${i18n.language}/collection/search/`;
        }

        return `/${i18n.language}/collection/`;
    };

    if (error) {
        return <NotFoundPage/>;
    }

    const getDefaultPictureType = () => {
        if (set) {
            return set.pictureType;
        }
        if (params.inside) {
            return "inside";
        }

        return "main";
    };

    const getLeftContent = () => {
        if (params.inside) {
            return <LeftSideInsideAmountListWidget/>;
        }
        if (params.defective) {
            return <LeftSideDefectiveAmountListWidget/>;
        }
        if (params.setClientId) {
            return (
                <React.Fragment>
                    <LeftSideCompanySetListWidget setClientId={params.setClientId}/>
                    <LeftSideCountrySetListWidget setClientId={params.setClientId}/>
                </React.Fragment>
            )
        }
        if (params.created) {
            return (
                <React.Fragment>
                    <LeftSideDynamicWidget title={t("dynamic")}/>
                    <LeftSideCustomCreatedWidget createdFrom={createdFrom} createdTill={createdTill}/>
                    <LeftSideCreatedListWidget variant="last_month"/>
                    <LeftSideCreatedListWidget variant="last_year"/>
                    <LeftSideCreatedListWidget variant="all"/>
                </React.Fragment>
            )
        }
        if (params.countryCode) {
            return (
                <React.Fragment>
                    <LeftSideRegionListWidget/>
                    <LeftSideCountrySetListWidget countryCode={params.countryCode}/>
                </React.Fragment>
            );
        }
        if (params.regionClientId) {
            return <LeftSideRegionListWidget/>;
        }
        if (params.search) {
            return (
                <React.Fragment>
                    <LeftSideSearchFilterWidget/>
                    <LeftSideBottleCapIndexWidget/>
                </React.Fragment>
            )
        }

        return null;
    };

    const getUrlQuery = () => {
        if (params.search) {
            const q = getQueryParam(location.search, "q");
            if (q) {
                let query = `?q=${q}`;
                const by = getQueryParam(location.search, "by");
                if (by) {
                    query += `&by=${by}`;
                }
                return query;
            }
        }

        return "";
    };

    const getCentralContent = () =>
        <CollectionGallery items={mapItemData(items, dt, i18n.language, getDefaultPictureType())}
                           numPages={items && items.numPages}
                           page={items && items.page}
                           baseUrl={getBaseUrl()}
                           urlQuery={getUrlQuery()}
                           ad={ad}
                           loading={loading}
                           originalAsPreview={params.itemCode}
                           showShareButtons={params.itemCode}/>;

    return <Page leftColContent={getLeftContent()} centralColContent={getCentralContent()}/>;
};
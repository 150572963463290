import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import LeftSideSearchFilterWidget from "./LeftSideSearchFilterWidget";

export default compose(
    withTranslation(),
    connect(
        state => ({
            searchQuery: state.common.searchQuery,
        })
    )
)(LeftSideSearchFilterWidget);
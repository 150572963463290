import React, {useCallback, useEffect} from "react";
import {TextField, InputAdornment, IconButton, Tooltip} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {Save, Delete} from "@material-ui/icons";
import {MUTATION_TRADE_SCAN_BASKET, QUERY_TRADE_SCAN_BASKET} from "./TradeScanBasketSelectionGraphql";
import QUERY_TRADE_SCAN_BASKET_LIST from "./LeftSideTradeScanBasketWidgetGraphql";
import TradeScanBasketSelectionStub from "./TradeScanBasketSelectionStubContainer";

export default ({t, user, client, data: {tradeScanBasketMessage, loading}, classes, tradeScanCode, showMessage = false}) => {
    const [userText, setUserText] = React.useState("");
    const [userTextDeletion, setUserTextDeletion] = React.useState(false);

    const handleSave = useCallback(() => {
        client.mutate({
            variables: {
                message: userText,
                scanCode: tradeScanCode,
            },
            mutation: MUTATION_TRADE_SCAN_BASKET,
            refetchQueries: [{
                query: QUERY_TRADE_SCAN_BASKET,
                variables: {
                    code: tradeScanCode
                }
            }, {
                query: QUERY_TRADE_SCAN_BASKET_LIST,
            }],
        });
    }, [client, userText, tradeScanCode]);

    const handleDelete = () => {
        setUserText("");
        setUserTextDeletion(true);
    };

    const getTextFieldId = () => `trade_scan_user_text_${tradeScanCode}`;

    const handleChange = () => setUserText(document.getElementById(getTextFieldId()).value);

    useEffect(() => {
        if (tradeScanBasketMessage === undefined) {
            return;
        }
        setUserText(tradeScanBasketMessage);
    }, [tradeScanBasketMessage, setUserText]);

    useEffect(() => {
        if (!userTextDeletion) {
            return;
        }
        handleSave();
        setUserTextDeletion(false);
    }, [userTextDeletion, handleSave]);

    if (!user) {
        return null;
    }
    if (loading) {
        return <TradeScanBasketSelectionStub showMessage={showMessage}/>;
    }

    return (
        <React.Fragment>
            {showMessage && <Alert severity="info" className={classes.message}>{t("trade_scan_info_message")}</Alert>}
            <TextField
                id={getTextFieldId()}
                label={t("your_selection")}
                multiline
                rowsMax="4"
                value={userText}
                onChange={handleChange}
                variant="outlined"
                className={classes.userText}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <Tooltip title={t("delete")} arrow>
                                <IconButton color="primary" onClick={handleDelete} className={classes.deleteButton}>
                                    <Delete/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("save")} arrow>
                                <IconButton color="primary" onClick={handleSave}>
                                    <Save/>
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                }}
            />
        </React.Fragment>
    );
};
import {fade} from "@material-ui/core/styles";

export default theme => ({
    grow: {
        width: "100%",
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    search: {
        flexGrow: 1,
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    personalButtons: {
        display: "flex",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(2, 1, 2, 2),
        transition: theme.transitions.create("width"),
    },
    showMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    }
})
import {SET_BASKET, ADD_BASKET_ITEM, DELETE_BASKET_ITEM} from "../actionTypes";

const initialState = {
    basket: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BASKET: {
            return {
                ...state,
                basket: action.basket,
            };
        }
        case ADD_BASKET_ITEM: {
            return {
                ...state,
                basket: [...state.basket, action.itemCode],
            };
        }
        case DELETE_BASKET_ITEM: {
            return {
                ...state,
                basket: state.basket.filter(itemCode => itemCode !== action.itemCode),
            };
        }
        default:
            return state;
    }
}
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import CollectionPage from "./CollectionPage";
import {withRouter} from "react-router-dom";
import withDataTranslation from "./lib/translationHelper";
import {graphql} from "react-apollo";
import QUERY_COUNTRIES from "./CollectionPageGraphql";

export default compose(
    withRouter,
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_COUNTRIES),
    connect(
        null,
        {
            setPageTitle,
            setBreadcrumbs
        }
    ),
)(CollectionPage);
import {connect} from "react-redux";
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import QUERY_TRADE_SCAN_CONTENT from "./TradeScanPageGraphql";
import TradeScanPage from "./TradeScanPage";
import withDataTranslation from "./lib/translationHelper";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import styles from "./TradeScanPageStyles";

export default compose(
    withRouter,
    withStyles(styles),
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_TRADE_SCAN_CONTENT, {
        options: ({match: {params}}) => ({
            variables: {
                code: params.tradeScanCode,
            }
        })
    }),
    connect(
        state => ({
            user: state.common.user,
        }),
        {
            setPageTitle,
            setBreadcrumbs
        }
    ),
)(TradeScanPage);
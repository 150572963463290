import React from "react";
import {Paper} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import HorizontalCardListStub from "./HorizontalCardListStubContainer";

export default ({classes}) => (
    <React.Fragment>
        <Paper className={classes.previews}>
            {[...Array(20)].map((_, index) =>
                <Skeleton variant="rect" key={index}/>
            )}
        </Paper>
        <div className={classes.paginationStub}/>
        <HorizontalCardListStub cardAmount={20}/>
    </React.Fragment>
);

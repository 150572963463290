import React from "react";
import LeftSideWidget from "./LeftSideWidgetContainer";
import moment from "moment";
import DatePicker from "./DatePickerContainer";

export default ({t, i18n, history, classes, createdFrom, createdTill}) => {
    const formatUrlDate = date => moment(date).format("DD.MM.YYYY");

    const openDateRangePage = (from, till) => {
        history.push(`/${i18n.language}/collection/created/${formatUrlDate(from)}-${formatUrlDate(till)}/`)
    };

    return (
        <LeftSideWidget title={t("custom_period")} noHideOverflow={true} contentClassName={classes.root} content={
            <React.Fragment>
                <DatePicker
                    label={t("date_from")}
                    value={createdFrom}
                    maxDate={createdTill}
                    onChange={date => openDateRangePage(date, createdTill)}
                />
                <DatePicker
                    label={t("date_to")}
                    value={createdTill}
                    minDate={createdFrom}
                    onChange={date => openDateRangePage(createdFrom, date)}
                />
            </React.Fragment>
        }/>
    );
};
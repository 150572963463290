export default theme => ({
    wrapper: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.up(400)]: {
            width: "auto",
        }
    },
    root: {
        margin: "0 auto",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        width: "fit-content",
        minWidth: 150,
        height: "100%",
        [theme.breakpoints.up(400)]: {
            width: 150,
            maxWidth: 150,
        },
        "& .MuiTabs-root": {
            minHeight: 34,
        },
        "& .MuiTab-root": {
            textTransform: "none",
            minWidth: 72,
            padding: "4px 4px",
            minHeight: 34,
        },
        "& .MuiTabs-flexContainer": {
            justifyContent: "center",
        }
    },
    pictureWrapper: {
        position: "relative",
    },
    pictureTypeTab: {
        "&:hover": {
            color: theme.palette.secondary.light,
        }
    }
});
import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import QUERY_USER_DATA from "./UserButtonGraphql";
import MUTATION_CLEAR_AUTH_COOKIES from "./LogoutDialogGraphql";

export default ({t, open, onClose, user, theme, client}) => {

    if (!user) {
        return null;
    }

    const logout = () => {
        client.mutate({
            mutation: MUTATION_CLEAR_AUTH_COOKIES,
            refetchQueries: [{
                query: QUERY_USER_DATA,
            }],
        })
            .then(() => {
                onClose();
            });
    };

    return (
        <Dialog
            fullScreen={useMediaQuery(theme.breakpoints.down("sm"))}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{t("authentication")}</DialogTitle>
            <DialogContent>
                <Typography>{t("you_are_logged_in_as")} {user.username}</Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={logout} color="primary">{t("logout")}</Button>
            </DialogActions>
        </Dialog>
    );
}

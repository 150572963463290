import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_DEFECTIVE_ITEM_AMOUNTS from "./LeftSideDefectiveAmountListWidgetGraphql";
import LeftSideDefectiveAmountListWidget from "./LeftSideDefectiveAmountListWidget";
import withDataTranslation from "../lib/translationHelper";

export default compose(
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_DEFECTIVE_ITEM_AMOUNTS),
)(LeftSideDefectiveAmountListWidget);
import React from "react";
import {AreaChart, Area, XAxis, Tooltip, ResponsiveContainer} from "recharts";
import {timestampToDate} from "../lib/dateHelper";
import {fade, lighten} from "@material-ui/core";
import LeftSideWidget from "./LeftSideWidgetContainer";

export default ({title, t, i18n, data: {dynamicData}, theme: {palette, typography}, history}) => {
    const lastAmount = dynamicData ? dynamicData[dynamicData.length - 1]["amount"] : null;

    const openDynamicPage = () => {
        history.push(`/${i18n.language}/stats/`)
    };

    return (
        <LeftSideWidget title={title} noContentPadding={true} content={
            <ResponsiveContainer width="100%" aspect={1.5}>
                <AreaChart data={dynamicData}
                           style={{cursor: "pointer"}}
                           onClick={openDynamicPage}
                           margin={{top: 0, right: 0, bottom: 0, left: 0}}>
                    {dynamicData
                        ? <Area type="monotone"
                                dataKey="amount"
                                name={t("amount")}
                                animationDuration={1000}
                                stroke={palette.secondary.main}
                                fill={fade(palette.secondary.main, 0.3)}/>
                        : null}

                    <XAxis stroke={lighten(palette.text.primary, 0.3)}
                           dataKey="period"
                           type="number"
                           domain={["dataMin", "dataMax"]}
                           height="0"
                           tick={false}/>
                    <Tooltip labelFormatter={(timestamp) => timestampToDate(timestamp, i18n.language)}/>
                    <text x={16} y={40} fontSize={typography.h4.fontSize} textAnchor="left">{lastAmount}</text>
                </AreaChart>
            </ResponsiveContainer>
        }/>
    );
};
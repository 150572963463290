import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {SUPPORTED_LANGUAGES} from "./lib/translationHelper";

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init(
        {
            backend: {
                loadPath: "/locales/{{lng}}/{{ns}}.json",
            },
            fallbackLng: "en",
            whitelist: SUPPORTED_LANGUAGES,
            load: "languageOnly",
            interpolation: {
                escapeValue: false,
            },
            parseMissingKeyHandler: () => "",
            detection: {
                order: ["path", "querystring", "cookie", "localStorage", "navigator", "htmlTag"],
                lookupCookie: "i18next",
                lookupLocalStorage: "i18nextLng",
                lookupFromPathIndex: 0,
                lookupQuerystring: "lang",
                caches: ["localStorage", "cookie"],
                excludeCacheFor: ["cimode"],
                cookieMinutes: 10,
                cookieDomain: "canaco.org",
                checkWhitelist: true
            }
        }, null
    )
    .then(null);

export default i18n;
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import MenuData from "./MenuDataContainer";
import compose from "recompose/compose";
import {openMobileMenu, closeMobileMenu, toggleMobileSubmenu} from "../redux/actions/mobileMenu";
import MobileMenu from "./MobileMenu";
import styles from "./MobileMenuStyles";

export default compose(
    withStyles(styles),
    connect(
        state => ({
            opened: state.mobileMenu.opened,
            submenuExpand: state.mobileMenu.submenuExpand,
        }), {
            openMobileMenu,
            closeMobileMenu,
            toggleMobileSubmenu,
        }
    ),
    MenuData
)(MobileMenu);
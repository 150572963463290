import {
    SET_SITE_NAME,
    SET_PAGE_TITLE,
    SET_BREADCRUMBS,
    SET_USER,
    SET_LANGUAGES,
    SET_SEARCH_QUERY
} from "../actionTypes";

export const setSiteName = text => ({
    type: SET_SITE_NAME,
    name: text,
});

export const setLanguages = languages => ({
    type: SET_LANGUAGES,
    languages: languages,
});

export const setPageTitle = (text, loading = false) => ({
    type: SET_PAGE_TITLE,
    title: text,
    loading: loading,
});

export const setBreadcrumbs = items => ({
    type: SET_BREADCRUMBS,
    items: items,
});

export const setUser = user => ({
    type: SET_USER,
    user: user,
});

export const setSearchQuery = query => ({
    type: SET_SEARCH_QUERY,
    query: query,
});
import React from "react";
import Pagination from "./PaginationContainer";
import ContentAdWrapper from "./ContentAdWrapperContainer";
import LeftCoverCard from "./LeftCoverCardContainer";
import HorizontalCardListStub from "./HorizontalCardListStubContainer";
import {Helmet} from "react-helmet/es/Helmet";

export default ({data, numPages, page, baseUrl, loading, ad}) => {
    if (loading) {
        return <HorizontalCardListStub cardAmount={10}/>;
    }
    if (!data) {
        return null;
    }

    return (
        <React.Fragment>
            {page > 1 && (
                <Helmet>
                    <link rel="canonical" href={baseUrl}/>
                </Helmet>
            )}
            {data.map((post, index) => (
                <React.Fragment key={index}>
                    <LeftCoverCard url={post.url}
                                   preview={post.preview}
                                   picture={post.picture}
                                   title={post.title}
                                   subTitle={post.created}
                                   content={post.introduction}/>
                    {index === Math.floor(data.length / 2) && <ContentAdWrapper ad={ad}/>}
                </React.Fragment>
            ))}
            <Pagination numPages={numPages} page={page} baseUrl={baseUrl}/>
        </React.Fragment>
    );
};
import unknownItemPicture from "./img/unknown_item_picture.svg";

export const mapItemData = (data, dt) => {
    if (!data) {
        return;
    }

    return data.list.map(item => ({
        code: item.code,
        twistOff: item.twistOff,
        companies: item.companies.map(company => dt("shortName", company.translations)),
        beverages: item.beverages.map(beverage => dt("name", beverage.translations)),
        brands: item.brands.map(brand => dt("name", brand.translations)),
        locations: item.locations.map((locationParts) => mapLocation(locationParts, dt)),
        picture: getPicture(item.pictures),
    }));
};

export const mapCountryData = (data, dt) => {
    if (!data) {
        return;
    }

    return dt("name", data.translations);
};

const getPicture = pictures => {
    if (pictures.length === 0) {
        return {
            preview: unknownItemPicture,
        };
    }

    for (let picture of pictures) {
        if (picture.pictureType !== "main") {
            continue;
        }

        return mapPicture(picture);
    }

    return mapPicture(pictures[0]);
};

const mapPicture = picture => ({
    original: picture.pictureUrl,
    preview: picture.previewUrl,
});

const mapLocation = ({region, city}, dt) => {
    let parts = [];
    if (city) {
        parts.push(dt("name", city.translations))
    }
    if (region) {
        parts.push(dt("name", region.translations))
    }

    return parts.join(", ");
};
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default ({classes: {list}}) => (
    [...Array(5).keys()].map(
        (_, groupIndex) => (
            <React.Fragment key={`continent_stub_${groupIndex}`}>
                <h2><Skeleton variant="text" width={"20%"}/></h2>
                <ul className={list}>
                    {[...Array(40).keys()].map(
                        (_, itemListIndex) => (
                            <li key={`country_stub_${itemListIndex}`}>
                                <Skeleton variant="text" width={["20%", "35%", "50%", "40%"][itemListIndex % 4]}/>
                            </li>
                        )
                    )}
                </ul>
            </React.Fragment>
        )
    )
);
import {roundTo} from "../lib/numberHelper";

export default (counterpartyStatData, dt, language) => {
    if (!counterpartyStatData) {
        return;
    }

    const maxAmount = counterpartyStatData[0].amount;

    return counterpartyStatData
        .filter(statData => statData.amount > 0)
        .map(statData => ({
            fullName: dt("fullName", statData.counterparty.translations),
            cityName: dt("name", statData.counterparty.location.translations),
            countryItemAmount: statData.counterparty.location.country && statData.counterparty.location.country.itemAmount,
            countryName: dt("name", statData.counterparty.location.country.translations),
            countryUrl: `/${language}${statData.counterparty.location.country.url}`,
            countryFlag: statData.counterparty.location.country.flagUrl,
            amount: statData.amount,
            share: roundTo(statData.amount * 100 / maxAmount, 1),
        }));
}

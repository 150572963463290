import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import MainPage from "./MainPage";
import {graphql} from "react-apollo";
import QUERY_LAST_BLOG_POST_LISTS from "./MainPageGraphql";
import withDataTranslation from "./lib/translationHelper";
import {withStyles} from "@material-ui/core/styles";
import styles from "./MainPageStyles";

export default compose(
    withStyles(styles),
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_LAST_BLOG_POST_LISTS),
    connect(
        state => ({
            siteName: state.common.siteName,
        }),
        {
            setBreadcrumbs,
            setPageTitle,
        }
    )
)(MainPage);
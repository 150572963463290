import React from "react";
import LeftSideListWidget from "./LeftSideListWidgetContainer";
import mapMenuData from "./LeftSideBlogSectionListWidgetDataMapper";

export default ({t, dt, i18n, data: {mainMenuBlogData, loading}}) =>
    <LeftSideListWidget title={t("sections")}
                        listData={mainMenuBlogData
                            ? mapMenuData(mainMenuBlogData.sections, dt, i18n.language)
                            : null
                        }
                        loading={loading}/>;
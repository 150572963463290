import React from "react";
import {
    FacebookShareButton,
    MailruShareButton,
    OKShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    MailruIcon,
    OKIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WhatsappIcon,
    EmailIcon
} from "react-share";
import {getCurrentHost} from "../lib/urlHelper";

export default ({title, description, imageUrl, classes, history, buttonSize = 32, noMargin = false}) => {
    const pageUrl = getCurrentHost() + history.location.pathname;
    return (
        <div className={!noMargin && classes.root}>
            <VKShareButton title={title} image={imageUrl} url={pageUrl} noParse>
                <VKIcon size={buttonSize} round/>
            </VKShareButton>
            <FacebookShareButton url={pageUrl}>
                <FacebookIcon size={buttonSize} round/>
            </FacebookShareButton>
            <WhatsappShareButton title={title} url={pageUrl}>
                <WhatsappIcon size={buttonSize} round/>
            </WhatsappShareButton>
            <TelegramShareButton title={title} url={pageUrl}>
                <TelegramIcon size={buttonSize} round/>
            </TelegramShareButton>
            <TwitterShareButton title={title} url={pageUrl}>
                <TwitterIcon size={buttonSize} round/>
            </TwitterShareButton>
            <MailruShareButton title={title} description={description} imageUrl={imageUrl} url={pageUrl}>
                <MailruIcon size={buttonSize} round/>
            </MailruShareButton>
            <OKShareButton title={title} description={description} image={imageUrl} url={pageUrl}>
                <OKIcon size={buttonSize} round/>
            </OKShareButton>
            <ViberShareButton title={title} url={pageUrl}>
                <ViberIcon size={buttonSize} round/>
            </ViberShareButton>
            <RedditShareButton title={title} url={pageUrl}>
                <RedditIcon size={buttonSize} round/>
            </RedditShareButton>
            <EmailShareButton subject={title} body={description} url={pageUrl}>
                <EmailIcon size={buttonSize} round/>
            </EmailShareButton>
        </div>
    );
}
import React from "react";

export const SUPPORTED_LANGUAGES = ["ca", "de", "en", "es", "fr", "it", "nl", "pl", "pt", "ru", "uk"];

export const LANGUAGE_TRANSLATION_KEYS = {
    ca: "catalan",
    de: "german",
    en: "english",
    es: "spanish",
    fr: "french",
    it: "italian",
    nl: "dutch",
    pl: "polish",
    pt: "portuguese",
    ru: "russian",
    uk: "ukrainian"
}

export default WrappedComponent => {
    class HOC extends React.Component {
        /**
         * Finds closest language in availableLanguages array
         * @link https://en.wikipedia.org/wiki/Mutual_intelligibility
         * @link https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
         */
        getClosestLanguage = (language, availableLanguages) => {
            if (typeof availableLanguages !== "object") {
                return;
            }
            if (availableLanguages.length === 1) {
                return availableLanguages[0];
            }
            if (availableLanguages.includes(language)) {
                return language;
            }

            const mutuallyIntelligibleLanguages = {
                ru: ["be", "uk"],
                be: ["ru", "uk"],
                es: ["gl", "pt", "ca", "it"],
                ca: ["oc", "it", "es", "pt"],
                nl: ["af", "li", "fy"],
                en: ["gd"],
                fr: ["ca", "ro", "it", "es", "pt"],
                gl: ["pt", "es", "ca"],
                de: ["lb"],
                ga: ["gd"],
                it: ["co", "fr", "ca", "es", "sc", "pt"],
                lb: ["de"],
                li: ["nl", "af"],
                pl: ["sk", "cs"],
                pt: ["gl", "es", "ca", "it"],
                az: ["tr"],
                tr: ["az"],
                et: ["fi"],
                fi: ["et"],
                mt: ["sc"],
                af: ["nl"],
                bg: ["mk"],
                co: ["it", "sc"],
                hr: ["sr", "sl"],
                cs: ["sk", "pl"],
                da: ["no", "sv"],
                mk: ["bg"],
                no: ["da", "sv"],
                oc: ["ca"],
                ro: ["fr", "it"],
                sc: ["co", "it"],
                sr: ["hr", "sl", "mc"],
                sk: ["cs", "pl"],
                sl: ["hr", "sr"],
                sv: ["da", "no"],
                uk: ["be", "ru"],
                zu: ["xh"],
            };

            const closeLangs = mutuallyIntelligibleLanguages[language];
            if (closeLangs !== undefined) {
                for (let key in closeLangs) {
                    if (!closeLangs.hasOwnProperty(key)) {
                        continue;
                    }
                    if (availableLanguages.includes(closeLangs[key])) {
                        return closeLangs[key];
                    }
                }
            }

            const languageFamilies = [
                [// Indo-European, Balto-Slavic
                    "pl", "cs", "sk", "sr", "hr", "bs", "bg", "sl", "mk", "be", "ru", "uk", "lv", "lt", "cu",
                ],
                [// Indo-European, Germanic
                    "is", "no", "sv", "da", "gd", "en", "nl", "de", "af", "fo", "lb", "li", "nb", "nn", "fy", "yi",
                ],
                [// Indo-European, Romance
                    "fr", "ca", "it", "pt", "rm", "ro", "es", "sc", "an", "co", "gl", "la", "oc", "wa",
                ],
                [// Indo-European, Indo-Iranian
                    "as", "ae", "bn", "bh", "dv", "gu", "hi", "ks", "ku", "mr", "ne", "or", "os", "pa", "pi", "fa",
                    "ps", "sa", "sd", "si", "tg", "ur",
                ],
                [// Indo-European, Celtic
                    "br", "kw", "ga", "gv", "cy",
                ],
                [// Uralic
                    "et", "fi", "hu", "kv", "se"
                ],
                [// Turkic
                    "az", "ba", "cv", "kk", "ky", "tk", "tr", "tt", "ug", "uz"
                ],
                [// Afro-Asiatic
                    "aa", "am", "ar", "ha", "he", "mt", "om", "so", "ti"
                ],
                [// Algonquian
                    "cr", "oj"
                ],
                [// Austroasiatic
                    "km", "vi"
                ],
                [// Austronesian
                    "ch", "fj", "ho", "id", "jv", "mg", "ms", "mi", "mh", "na", "sm", "su", "tl", "to", "ty"
                ],
                [// Creole
                    "bi", "ht", "sg"
                ],
                [// Dravidian
                    "kn", "ml", "ta", "te"
                ],
                [// Eskimo–Aleut
                    "ik", "iu", "kl"
                ],
                [// Niger–Congo
                    "ak", "bm", "ny", "ee", "ff", "hz", "ig", "ki", "rw", "kg", "kj", "lg", "ln", "lu", "nd", "ng",
                    "nr", "rn", "sn", "st", "sw", "ss", "tn", "ts", "tw", "ve", "wo", "xh", "yo", "zu"
                ],
                [// Northeast Caucasian
                    "av", "ce", "ab"
                ],
                [// Sino-Tibetan
                    "my", "zh", "dz", "ii", "bo"
                ],
                [// Tai–Kadai
                    "lo", "th", "za"
                ],
            ];
            for (let familyKey in languageFamilies) {
                if (!languageFamilies[familyKey].includes(language)) {
                    continue;
                }
                for (let key in availableLanguages) {
                    if (!availableLanguages.hasOwnProperty(key)) {
                        continue;
                    }
                    if (languageFamilies[familyKey].includes(availableLanguages[key])) {
                        return availableLanguages[key];
                    }
                }
            }

            const languageAlphabets = [
                [// Latin
                    "en", "lv", "po", "ro", "sk", "sv", "tg", "tr", "cs", "is", "no", "sv", "da", "gd", "nl", "cy",
                    "de", "af", "fo", "lb", "li", "nb", "nn", "fy", "yi", "fr", "ca", "it", "pt", "rm", "ro", "es",
                    "sc", "an", "co", "gl", "la", "oc", "wa", "br", "kw", "ga", "gv",
                ],
                [// Cyrillic
                    "ru", "uk", "be", "bg", "kk", "ky", "mk", "mn", "os", "sr", "cu",
                ],
            ];
            for (let languageAlphabetsKey in languageAlphabets) {
                if (!languageAlphabets[languageAlphabetsKey].includes(language)) {
                    continue;
                }
                for (let key in availableLanguages) {
                    if (!availableLanguages.hasOwnProperty(key)) {
                        continue;
                    }
                    if (languageAlphabets[languageAlphabetsKey].includes(availableLanguages[key])) {
                        return availableLanguages[key];
                    }
                }
            }

            const internationalLanguages = ["en", "es", "fr", "ru", "pt", "de", "ar", "zh"];
            for (let key in availableLanguages) {
                if (!availableLanguages.hasOwnProperty(key)) {
                    continue;
                }
                if (internationalLanguages.includes(availableLanguages[key])) {
                    return availableLanguages[key];
                }
            }

            return availableLanguages[0];
        };

        /**
         * @param fieldName Key for translation
         * @param translations Array of available translations
         * @returns {string}
         */
        translateData = (fieldName, translations) => {
            if (translations === undefined) {
                return '';
            }

            const availableLanguages = translations.map(tr => tr.language);
            const translateTo = this.getClosestLanguage(this.props.i18n.language, availableLanguages);
            for (const key in translations) {
                if (!translations.hasOwnProperty(key)) {
                    continue;
                }
                if (translations[key].language === translateTo) {
                    return translations[key][fieldName];
                }
            }
            return '';
        };

        render = () =>
            <WrappedComponent
                {...this.props}
                dt={this.translateData}
            />
    }

    return HOC;
}

export default () => ({
    root: {
        minWidth: 690,
    },
    shareCell: {
        width: 100,
    },
    amountCell: {
        width: 100,
        textAlign: "right",
    },
});
import React from "react";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

const stubWidths = ["30%", "45%", "40%", "52%", "33%"];

export default ({classes}) =>
    <TableContainer component={Paper}>
        <Table size="small" className={classes.root}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Skeleton width="30%"/>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <Skeleton width="40%"/>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {[...Array(100)].map((_, index) =>
                    <TableRow key={`stub_line_${index}`}>
                        <TableCell component="th" scope="row">
                            <Skeleton width={stubWidths[index % 5]}/>
                        </TableCell>
                        <TableCell className={classes.amountCell}>
                            <Skeleton width={stubWidths[(index + 2) % 5]}/>
                        </TableCell>
                        <TableCell className={classes.shareCell}>
                            <Skeleton width={stubWidths[(index + 3) % 5]}/>
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </TableContainer>;
export default theme => ({
    introduction: {
        fontWeight: 500,
    },
    blogPostContent: {
        "& a": {
            color: theme.palette.primary.main,
            fontWeight: "bolder",
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
            }
        }
    },
    created: {
        fontWeight: 500,
    }
});
import React, {useEffect, useState} from "react";
import LeftSideWidget from "./LeftSideWidgetContainer";
import QUERY_RANDOM_ITEM from "./LeftSideRandomItemWidgetGraphql";
import mapData from "./LeftSideRandomItemWidgetDataMapper";
import {Link as RouterLink} from "react-router-dom";
import {Link} from "@material-ui/core";

export default ({t, i18n, classes, client}) => {
    const [itemData, setItemData] = useState();
    const REFRESH_TIME = 20000;

    const loadRandomItem = () => {
        client.query({
            query: QUERY_RANDOM_ITEM,
            fetchPolicy: "network-only",
        })
            .then(response => setItemData(mapData(response.data.randomItem, i18n.language)));
    }

    useEffect(() => {
        loadRandomItem();
        let intervalId = setInterval(loadRandomItem, REFRESH_TIME);
        return () => clearInterval(intervalId);
    }, []);

    if (!itemData) {
        return null;
    }

    return <LeftSideWidget title={t("random_from_collection")}
                           contentClassName={classes.wrapper}
                           content={
                               <Link to={itemData.url} component={RouterLink}>
                                   <img src={itemData.original}
                                        className={classes.picture}
                                        alt={itemData.code}/>
                               </Link>
                           }/>;
};
import gql from "graphql-tag";

export default gql`
{
  brandStatData {
    brand {
      translations {
        name
        language
      }
    }
    amount
  }
}`;
import React from "react";
import {Link} from "react-router-dom";
import {Drawer, List, ListItem, ListItemText, IconButton, Collapse, Divider} from "@material-ui/core";
import {Menu as MenuIcon, ExpandLess, ExpandMore} from "@material-ui/icons";

export default function TemporaryDrawer(
    {
        opened,
        submenuExpand,
        openMobileMenu,
        closeMobileMenu,
        toggleMobileSubmenu,
        classes,
        menuItems
    }) {
    const closeMenu = event => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        closeMobileMenu();
    };

    const MenuItem = ({item, depth = 1}) => {
            if (item.children.length === 0) {
                return (
                    <React.Fragment>
                        <ListItem button
                                  onClick={
                                      (e) => {
                                          item.extraAction();
                                          closeMenu(e);
                                      }
                                  }
                                  onKeyDown={
                                      (e) => {
                                          item.extraAction();
                                          closeMenu(e);
                                      }
                                  }
                                  className={classes[`nested${depth}`]}
                                  component={Link}
                                  to={item.url}
                        >
                            <ListItemText primary={item.name}/>
                        </ListItem>
                        <Divider/>
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment>
                    <ListItem button onClick={() => toggleMobileSubmenu(item.code)} className={classes[`nested${depth}`]}>
                        <ListItemText primary={item.name}/>
                        {submenuExpand[item.code] ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Divider/>
                    <Collapse in={submenuExpand[item.code]} timeout="auto" unmountOnExit>
                        {item.children.map(
                            (subItem, index) => <MenuItem item={subItem} depth={depth + 1} key={index}/>)
                        }
                    </Collapse>
                </React.Fragment>
            );
        }
    ;

    return (
        <React.Fragment>
            <IconButton edge="start" className={classes.menuButton} onClick={openMobileMenu}>
                <MenuIcon/>
            </IconButton>
            <Drawer open={opened} onClose={closeMenu}>
                <div className={classes.list} role="presentation">
                    <List>
                        {menuItems.map((item, index) =>
                            <MenuItem item={item} key={index}/>
                        )}
                    </List>
                </div>
            </Drawer>
        </React.Fragment>
    );
}

import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import compose from "recompose/compose";
import Breadcrumb from "./Breadcrumb";
import styles from "./BreadcrumbStyles";
import {withTranslation} from "react-i18next";

export default compose(
    withTranslation(),
    withStyles(styles),
    connect(
        state => {
            return {
                items: state.common.breadcrumbItems,
            };
        }
    )
)(Breadcrumb)
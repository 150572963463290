/**
 * Converts 8 symbol hex color representation to css rgba notation
 * For example 804000ff -> rgba(128, 64, 0, 1)
 * @param hex
 */
export const mapColor = (hex) => {
    let [r, g, b, a] = hex.match(/.{1,2}/g).map((h) => parseInt(h, 16));
    a = a / 255;

    // Make transparent colors slightly visible
    if (a <= 0.2) {
        a = 0.2
    }

    // Make opaque colors slightly transparent
    if (a >= 0.7) {
        a = 0.7
    }

    return `rgba(${r}, ${g}, ${b}, ${a})`;
};
import React from "react";
import mapData from "./CountryStatDataMapper";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    LinearProgress,
    Link
} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import TableStatStub from "./TableStatStubContainer";

export default ({t, dt, i18n, classes, data: {countryStatData, loading, error}, ad}) => {
    if (loading) {
        return <TableStatStub/>;
    }
    if (error) {
        return null;
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("country")}</TableCell>
                        <TableCell className={classes.hiddenOnMobile}>{t("continent")}</TableCell>
                        <TableCell colSpan={2} className={classes.amountHeader}>{t("amount")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mapData(countryStatData, dt, i18n.language).map((country, index) => (
                        <React.Fragment key={index}>
                            {index === 50 && countryStatData.length > 60 && (
                                <tr key="ad_row">
                                    <td colSpan={4} className={classes.adCell}>{ad}</td>
                                </tr>
                            )}
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Link to={country.countryUrl} component={RouterLink} className={classes.flagLink}>
                                        {country.countryFlag
                                            ? <img src={country.countryFlag} className={classes.flag} alt=""/>
                                            : null
                                        } {country.countryName}
                                    </Link>
                                </TableCell>
                                <TableCell className={classes.hiddenOnMobile}>
                                    <Link to={country.continentUrl} component={RouterLink}>
                                        {country.continentName}
                                    </Link>
                                </TableCell>
                                <TableCell className={classes.amountCell}>{country.amount}</TableCell>
                                <TableCell className={classes.shareCell}>
                                    <LinearProgress color="secondary" variant="determinate" value={country.share}/>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
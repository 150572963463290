import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";

export default ({t, i18n, setBreadcrumbs, classes}) => {
    useEffect(() => {
        setBreadcrumbs([{
            name: t("page_not_found"),
            link: undefined,
        }]);
    }, [setBreadcrumbs, t, i18n]);

    return (
        <Grid item xs={12} md className={classes.root}>
            <h1>{t("page_not_found")}</h1>
        </Grid>
    );
};
import {SET_BASKET, ADD_BASKET_ITEM, DELETE_BASKET_ITEM} from "../actionTypes";

export const setBasket = basket => ({
    type: SET_BASKET,
    basket: basket,
});

export const addBasketItem = itemCode => ({
    type: ADD_BASKET_ITEM,
    itemCode: itemCode,
});

export const deleteBasketItem = itemCode => ({
    type: DELETE_BASKET_ITEM,
    itemCode: itemCode,
});
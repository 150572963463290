import React from "react";
import {Link} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";
import {Link as RouterLink} from "react-router-dom";

const Submenu = ({items, classes, depth = 2}) => {
    if (!items) {
        return null;
    }

    return (
        <div className={`${classes.multiLevelMenu} ${classes[`depth${depth}`]}`}>
            <ul className={classes.menu}>
                {items.map((item, index) =>
                    <li className={classes.multiItem} key={index}>
                        <Link className={classes.multiLink} component={RouterLink} to={item.url}>
                            <React.Fragment>
                                {item.name}
                                {item.children.length > 0 && <ExpandMore/>}
                            </React.Fragment>
                        </Link>
                        <Submenu items={item.children} classes={classes} depth={depth + 1}/>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ({menuItems, classes}) =>
    <nav className={classes.root}>
        <div className={`${classes.multiLevelMenu} ${classes.depth1}`}>
            {menuItems.map((item, index) =>
                <ul className={classes.menu} key={index}>
                    <li className={classes.multiItem}>
                        <Link className={classes.multiLink} component={RouterLink} to={item.url}>{item.name}</Link>
                        <Submenu items={item.children} classes={classes}/>
                    </li>
                </ul>
            )}
        </div>
    </nav>;
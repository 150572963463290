import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import compose from "recompose/compose";
import LeftSideRandomItemWidget from "./LeftSideRandomItemWidget";
import styles from "./LeftSideRandomItemWidgetStyles";
import {withApollo} from "react-apollo";

export default compose(
    withStyles(styles),
    withTranslation(),
    withApollo,
)(LeftSideRandomItemWidget);
import {formatDateTime} from "./lib/dateHelper";
import {getCurrentHost} from "./lib/urlHelper";

export const mapBlogPostData = (data, dt, language) => {
    if (!data) {
        return;
    }

    return {
        created: formatDateTime(data.created, language),
        modified: formatDateTime(data.modified, language),
        adminUrl: data.adminUrl,
        url: `${getCurrentHost()}/${language}${data.url}`,
        picture: data.pictureUrl,
        section: data.section
            ? {
                title: dt("title", data.section.translations),
                url: `/${language}${data.section.url}`,
            }
            : "",
        title: dt("title", data.translations),
        introduction: dt("introduction", data.translations),
        text: dt("text", data.translations),
        keywords: dt("keywords", data.translations),
    };
};

import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import compose from "recompose/compose";
import DesktopHeader from "./DesktopHeader";
import styles from "./DesktopHeaderStyles";
import {withRouter} from "react-router-dom";
import {setSearchQuery} from "../redux/actions";

export default compose(
    withRouter,
    withStyles(styles),
    withTranslation(),
    connect(
        state => ({
            siteName: state.common.siteName,
            pageTitle: state.common.pageTitle,
            searchQuery: state.common.searchQuery,
        }), {
            setSearchQuery,
        },
    )
)(DesktopHeader);
import {sortByName} from "../lib/arrayHelper";

export default (data, dt, language) => {
    if (!data) {
        return [];
    }

    let result = data.map((section) => ({
        url: `/${language}${section.url}`,
        name: dt("title", section.translations),
    }));
    sortByName(result);

    return result;
};

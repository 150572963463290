export default (data, language) => {
    if (!data) {
        return [];
    }

    return data.map(scan => ({
        url: `/${language}${scan.url}`,
        preview: scan.previewUrl,
        original: scan.pictureUrl,
        code: scan.code,
    }));
};
import gql from "graphql-tag";

export default gql`
{
  counterpartyStatData {
    counterparty {
      translations {
        fullName
        language
      }
      location {
        translations {
          name
          language
        }
        country {
          itemAmount
          url
          flagUrl
          translations {
            name
            language
          }
        }
      }
    }
    amount
  }
}`;
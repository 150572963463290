import gql from "graphql-tag";

export default gql`
    query ($continent: String, $country: String, $macroRegion: String, $currentLocation: String){
      mapData(continentCode: $continent, countryCode: $country, macroRegionCode: $macroRegion) {
        location {
          code,
          url,
          flagUrl,
          translations {
            language,
            name
          }
        },
        amount,
        extended
      },
      locationAncestors(code: $currentLocation) {
        code,
        mapUrl,
        translations {
          name,
          language
        }
      }
    }`;

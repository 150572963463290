import React, {useEffect, useState} from "react";
import DesktopHeader from "./components/DesktopHeaderContainer";
import MobileHeader from "./components/MobileHeaderContainer";
import Footer from "./components/FooterContainer";
import {Helmet} from "react-helmet/es/Helmet";
import {Container, Grid} from "@material-ui/core";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import ScrollUpButton from "react-scroll-up-button";
import AboutPage from "./AboutPageContainer";
import YandexAd from "./components/YandexAd";
import {YANDEX_AD_SLOT_HORIZONTAL, YANDEX_AD_SLOT_VERTICAL} from "./constants";
import MapPage from "./MapPageContainer";
import {
    COUNTERPARTY_PUBLIC_TYPE_RE,
    COUNTRY_CODE_RE,
    MAP_CONTINENT_RE,
    MAP_MACRO_REGION_RE,
    PAGE_EXCEPT_FIRST_RE,
    CLIENT_ID_RE,
    SLUG_RE,
    STAT_SECTION_RE,
    TRADE_SCAN_CODE_RE,
    DATE_RE,
    REGION_CLIENT_ID_RE,
    getLangRegExp
} from "./lib/urlHelper";
import StatPage from "./StatPageContainer";
import CounterpartyListPage from "./CounterpartyListPageContainer";
import BlogPostListPage from "./BlogPostListPageContainer";
import BlogPostPage from "./BlogPostPageContainer";
import TradeScanListPage from "./TradeScanListPageContainer";
import TradeScanPage from "./TradeScanPageContainer";
import mapData from "./AppWrapperDataMapper";
import {setFavicon} from "./lib/domHelper";
import ItemListPage from "./ItemListPageContainer";
import SetListPage from "./SetListPageContainer";
import CollectionPage from "./CollectionPageContainer";
import MainPage from "./MainPageContainer";
import TradePage from "./TradePageContainer";
import TradeCountryPage from "./TradeCountryPageContainer";
import BasketPage from "./BasketPageContainer";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from "@material-ui/core/styles";
import * as themes from "./themes";
import useStyles from "./AppWrapperStyles";
import NotFoundPage from "./NotFoundPageContainer";
import {SUPPORTED_LANGUAGES} from "./lib/translationHelper";
import gtag from "./lib/gtag";

const horizontalAd = <YandexAd adSlot={YANDEX_AD_SLOT_HORIZONTAL}/>;

export default ({i18n, dt, windowTitle, setSiteName, setLanguages, data: {hostInfo, loading}, history}) => {
    const mappedData = mapData(hostInfo, dt);
    const [theme, setTheme] = useState(themes.default);
    const classes = useStyles(theme)();
    const location = useLocation();

    useEffect(
        () => {
            gtag('event', 'page_view', {
                page_location: location.href,
                page_path: location.pathname
            });
        },
        [location]
    );

    useEffect(() => {
        const mappedData = mapData(hostInfo, dt);
        if (!mappedData) {
            return;
        }
        setSiteName(mappedData.siteName);
        setFavicon(mappedData.icon, loading);
        if (themes[mappedData.theme]) {
            setTheme(themes[mappedData.theme]);
        }
        setLanguages(mappedData.languages);
    }, [hostInfo, setLanguages, setSiteName, dt, loading]);

    useEffect(() => {
        gtag("js", new Date());
        gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }, []);

    const l = `:language${getLangRegExp(SUPPORTED_LANGUAGES)}`;

    const currentLanguage = i18n.language.substr(0, 2);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <div className={classes.outer}>
                <Helmet>
                    {mappedData.theme && <meta name="theme-color"
                                               content={themes[mappedData.theme].palette.primary.main}/>
                    }

                    <title>{windowTitle}</title>
                </Helmet>
                <div className={classes.inner}>
                    <MobileHeader/>
                    <DesktopHeader logo={mappedData.logo}/>
                    <Container className={classes.container}>
                        <Grid container spacing={5}>
                            <Switch>
                                {!loading && !hostInfo && (
                                    <Route component={() => {
                                        window.location.href = `https://canaco.org/${currentLanguage}/`;
                                        return null;
                                    }}/>
                                )}
                                <Route exact path={`/${l}/`}>
                                    <MainPage ad={horizontalAd}/>
                                </Route>
                                <Route exact path={`/${l}/about/`}>
                                    <AboutPage ad={horizontalAd}/>
                                </Route>
                                <Route exact path={`/${l}/collection/`}>
                                    <CollectionPage ad={horizontalAd}/>
                                </Route>
                                <Route exact path={`/${l}/collection/item/:itemCode${CLIENT_ID_RE}/`}>
                                    <ItemListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact
                                       path={`/${l}/collection/country/:countryCode${COUNTRY_CODE_RE}/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <ItemListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact
                                       path={`/${l}/collection/:inside(inside)/:countryCode${COUNTRY_CODE_RE}?/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <ItemListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact
                                       path={`/${l}/collection/region/:regionClientId${REGION_CLIENT_ID_RE}/:countryCode([a-z]{3})?/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <ItemListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact path={`/${l}/collection/:sets(sets)/`}>
                                    <SetListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact path={`/${l}/collection/:search(search)/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <ItemListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact
                                       path={`/${l}/collection/set/:setClientId${CLIENT_ID_RE}/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <ItemListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact
                                       path={`/${l}/collection/:defective(defective)/:countryCode${COUNTRY_CODE_RE}?/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <ItemListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact
                                       path={`/${l}/collection/:created(created)/:createdFrom${DATE_RE}?(-)?:createdTill${DATE_RE}?/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <ItemListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact path={`/${l}/stats/:statSection${STAT_SECTION_RE}?/`}>
                                    <StatPage ad={horizontalAd}/>
                                </Route>
                                <Route exact
                                       path={`/${l}/:counterpartyPublicType${COUNTERPARTY_PUBLIC_TYPE_RE}/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <CounterpartyListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact path={`/${l}?/blog/section/${SLUG_RE}/${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <Switch>
                                        <Route exact
                                               path={`/${l}/blog/section/:sectionCode${SLUG_RE}/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                            <BlogPostListPage ad={horizontalAd}/>
                                        </Route>
                                        <Route>
                                            <Redirect to={`/${currentLanguage}${history.location.pathname}`}/>
                                        </Route>
                                    </Switch>
                                </Route>
                                <Route exact path={`/${l}/blog/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <BlogPostListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact path={`/${l}?/blog/post/${SLUG_RE}/`}>
                                    <Switch>
                                        <Route exact path={`/${l}/blog/post/:blogPostCode${SLUG_RE}/`}>
                                            <BlogPostPage ad={horizontalAd}/>
                                        </Route>
                                        <Route>
                                            <Redirect to={`/${currentLanguage}${history.location.pathname}`}/>
                                        </Route>
                                    </Switch>
                                </Route>
                                <Route exact path={`/${l}/trade/`}>
                                    <TradePage ad={horizontalAd}/>
                                </Route>
                                <Route exact
                                       path={`/${l}/trade/country/:countryCode${COUNTRY_CODE_RE}/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <TradeCountryPage/>
                                </Route>
                                <Route exact path={`/${l}/trade/basket/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <BasketPage/>
                                </Route>
                                <Route exact path={`/${l}/trade/scans/:page${PAGE_EXCEPT_FIRST_RE}?/`}>
                                    <TradeScanListPage ad={horizontalAd}/>
                                </Route>
                                <Route exact path={`/${l}/trade/scan/:tradeScanCode${TRADE_SCAN_CODE_RE}?/`}>
                                    <TradeScanPage ad={horizontalAd}/>
                                </Route>
                                <Route exact component={MapPage}
                                       path={`/${l}/maps/:continent${MAP_CONTINENT_RE}?/:country([a-z]{3})?/:macroRegion${MAP_MACRO_REGION_RE}?/`}/>
                                {i18n.isInitialized && <Route exact path="/">
                                    <Redirect to={`/${currentLanguage}/`}/>
                                </Route>}
                                {!loading && <Route><NotFoundPage/></Route>}
                            </Switch>
                            <Grid item xs={12} md className={classes.rightColumn}>
                                <div>
                                    <YandexAd adSlot={YANDEX_AD_SLOT_VERTICAL}/>
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                    <Footer text={mappedData.footerText}/>
                    <ScrollUpButton/>
                </div>
            </div>
        </ThemeProvider>
    );
};
import gql from "graphql-tag";

export default gql`
query ($sectionCode: String, $page: Int) {
  blogPosts(sectionCode: $sectionCode, page: $page) {
    list {
      sort      
      url
      previewUrl
      pictureUrl
      created
      modified
      translations {
        title
        introduction
        language
      }
    }
    numPages
    page
  }
  sectionData(sectionCode: $sectionCode) {
    slug
    url
    translations {
      language
      title
    }
  }  
}`;
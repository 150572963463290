import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer} from "recharts";

export default ({mappedData, classes}) => {
    if (!mappedData) {
        return null;
    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({name, cx, cy, midAngle, innerRadius, outerRadius, percent}) => {
        const nameRadius = outerRadius + 22;
        const nameX = cx + nameRadius * Math.cos(-midAngle * RADIAN);
        const nameY = cy + nameRadius * Math.sin(-midAngle * RADIAN);

        const percentRadius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const percentX = cx + percentRadius * Math.cos(-midAngle * RADIAN);
        const percentY = cy + percentRadius * Math.sin(-midAngle * RADIAN);

        const humanReadableShare = `${(percent * 100).toPrecision(2)}%`;

        return (
            <React.Fragment>
                <text x={nameX} y={nameY} className={classes.outerLabel} textAnchor={nameX > cx ? 'start' : 'end'}
                      dominantBaseline="central">
                    {name}
                </text>
                {percent > 0.02
                    ? <text x={percentX} y={percentY} fill="white" textAnchor="middle" dominantBaseline="central"
                            className={classes.innerLabel}>
                        {humanReadableShare}
                    </text>
                    : null
                }
            </React.Fragment>
        );
    };

    return (
        <ResponsiveContainer id="drink_chart" width="100%" height="auto" aspect="1" className={classes.root}>
            <PieChart>
                <Pie data={mappedData} dataKey="amount" nameKey="name" cx="50%" cy="50%" innerRadius="45%"
                     outerRadius="65%" label={renderCustomizedLabel} stroke="none" isAnimationActive={false}
                     minAngle={2.8}>
                    {
                        mappedData.map(
                            (entry, index) => (<Cell key={`cell_${index}`} fill={entry.color}/>)
                        )
                    }
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};
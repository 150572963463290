import React from "react";
import mapData from "./RegionStatDataMapper";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    LinearProgress,
    Link
} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import TableStatStub from "./TableStatStubContainer";

export default ({t, dt, i18n, classes, data: {regionStatData, loading, error}, ad}) => {
    if (loading) {
        return <TableStatStub/>;
    }
    if (error) {
        return null;
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("region")}</TableCell>
                        <TableCell className={classes.hiddenOnMobile}>{t("country")}</TableCell>
                        <TableCell colSpan={2} className={classes.amountHeader}>{t("amount")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mapData(regionStatData, dt, i18n.language).map((region, index) => (
                        <React.Fragment key={index}>
                            {index === 50 && regionStatData.length > 60 && (
                                <tr key="ad_row">
                                    <td colSpan={4} className={classes.adCell}>{ad}</td>
                                </tr>
                            )}
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    <Link to={region.regionUrl} component={RouterLink}>{region.regionName}</Link>
                                </TableCell>
                                <TableCell className={classes.hiddenOnMobile}>
                                    <Link to={region.countryUrl} component={RouterLink} className={classes.flagLink}>
                                        {region.countryFlag && (
                                            <img src={region.countryFlag} className={classes.flag} alt=""/>
                                        )} {region.countryName}
                                    </Link>
                                </TableCell>
                                <TableCell className={classes.amountCell}>{region.amount}</TableCell>
                                <TableCell className={classes.shareCell}>
                                    <LinearProgress color="secondary" variant="determinate" value={region.share}/>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
import {roundTo} from "../lib/numberHelper";

export default (regionStatData, dt, language) => {
    if (!regionStatData) {
        return;
    }

    const maxAmount = regionStatData[0].amount;

    return regionStatData
        .filter((statData) => statData.amount > 0)
        .map((statData) => ({
            regionName: dt("name", statData.location.translations),
            regionUrl: `/${language}${statData.location.url}`,
            countryName: dt("name", statData.location.country.translations),
            countryUrl: `/${language}${statData.location.country.url}`,
            countryFlag: statData.location.country.flagUrl,
            amount: statData.amount,
            share: roundTo(statData.amount * 100 / maxAmount, 1),
        }));
}

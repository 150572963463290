import React from "react";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from "recharts";
import {lighten} from "@material-ui/core";

export default ({t, theme: {palette}, data: {yearStatData, loading, error}}) => {
    if (loading || error) {
        return null;
    }

    const RotatedAxisTick = ({x, y, fill, payload}) => (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill={fill} transform="rotate(-25)">{payload.value}</text>
        </g>
    );

    return (
        <ResponsiveContainer id="dynamic_chart" width="100%" height="auto" aspect="1">
            <BarChart
                data={yearStatData}
                margin={{top: 5, right: 0, bottom: 35, left: 0}}
            >
                <CartesianGrid stroke={lighten(palette.text.primary, 0.7)}
                               fill={palette.background.paper}
                               strokeDasharray="2 3"/>
                <XAxis dataKey="year" tick={<RotatedAxisTick fill={palette.text.primary}/>}/>
                <YAxis tick={{fill: palette.text.primary}}
                       stroke={lighten(palette.text.primary, 0.3)}/>
                <Tooltip cursor={{fill: palette.background.default}}/>
                <Bar dataKey="amount" fill={lighten(palette.secondary.main, 0.15)} name={t("amount")}/>
            </BarChart>
        </ResponsiveContainer>
    );
};
import {fade} from "@material-ui/core/styles";

export default theme => ({
    top: {
        display: "flex",
        background: `linear-gradient(
            to bottom, 
            ${fade(theme.mainContainer.backgroundColor, 0.1)} 0%, 
            ${fade(theme.palette.background.default, 0.1)} 80%, 
            transparent 100%
        )`,
        [theme.breakpoints.up("md")]: {
            minHeight: 135,
        },
    },
    rightCell: {
        display: "none",
        height: 54,
        [theme.breakpoints.up("md")]: {
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
        },
    },
    logo: {
        maxHeight: 120,
        maxWidth: 194,
        display: "flex"
    },
    logoCell: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    titleWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    basketWrapper: {
        display: "flex",
        flexFlow: "row",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        paddingTop: 15,
    },
    showDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    bottom: {
        [theme.breakpoints.up("md")]: {
            marginBottom: 20,
        },
    },
    searchInput: {
        paddingRight: 0,
    },
    title: {
        fontWeight: 300,
        textAlign: "center",
    }
});
import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import LeftSideListWidget from "./components/LeftSideListWidgetContainer";
import mapData from "./StatPageDataMapper";
import DynamicChart from "./components/DynamicChartContainer";
import BeverageChart from "./components/BeverageChartContainer";
import ConditionChart from "./components/ConditionChartContainer";
import CityStat from "./components/CityStatContainer";
import RegionStat from "./components/RegionStatContainer";
import CountryStat from "./components/CountryStatContainer";
import BrandStat from "./components/BrandStatContainer";
import CompanyStat from "./components/CompanyStatContainer";
import YearStat from "./components/YearStatContainer";
import CounterpartyStat from "./components/CounterpartyStatContainer";

export default ({t, i18n, setPageTitle, setBreadcrumbs, data: {mainMenuCollectionData, loading}, ad, match: {params}}) => {
    useEffect(() => {
        let pageTitle = t("statistic");
        let breadcrumbItems = [];
        let statSectionName;
        if (params.statSection === "beverages") {
            statSectionName = t("beverages");
        } else if (params.statSection === "conditions") {
            statSectionName = t("quality");
        } else if (params.statSection === "cities") {
            statSectionName = t("cities");
        } else if (params.statSection === "regions") {
            statSectionName = t("regions");
        } else if (params.statSection === "countries") {
            statSectionName = t("countries");
        } else if (params.statSection === "brands") {
            statSectionName = t("brands");
        } else if (params.statSection === "companies") {
            statSectionName = t("companies");
        } else if (params.statSection === "years") {
            statSectionName = t("years");
        } else if (params.statSection === "counterparties") {
            statSectionName = t("counterparties");
        }
        if (statSectionName) {
            breadcrumbItems.push({
                name: t("statistic"),
                link: `/${i18n.language}/stats/`,
            });
            breadcrumbItems.push({
                name: statSectionName,
            });
            pageTitle = `${statSectionName} – ${pageTitle}`;
        } else {
            breadcrumbItems.push({
                name: t("statistic"),
                link: undefined,
            });
        }
        setPageTitle(pageTitle);
        setBreadcrumbs(breadcrumbItems);
    }, [setBreadcrumbs, setPageTitle, t, i18n, params.statSection]);

    const getCentralContent = () => {
        if (!params) {
            return null;
        }
        if (params.statSection === "beverages") {
            return withAd(<BeverageChart/>)
        }
        if (params.statSection === "conditions") {
            return withAd(<ConditionChart/>)
        }
        if (params.statSection === "cities") {
            return <CityStat ad={ad}/>
        }
        if (params.statSection === "regions") {
            return <RegionStat ad={ad}/>
        }
        if (params.statSection === "countries") {
            return <CountryStat ad={ad}/>
        }
        if (params.statSection === "brands") {
            return <BrandStat ad={ad}/>
        }
        if (params.statSection === "companies") {
            return <CompanyStat ad={ad}/>
        }
        if (params.statSection === "years") {
            return withAd(<YearStat/>)
        }
        if (params.statSection === "counterparties") {
            return withAd(<CounterpartyStat ad={ad}/>)
        }

        return withAd(<DynamicChart/>)
    };

    const withAd = (content) => (
        <React.Fragment>
            {content}
            {ad}
        </React.Fragment>
    );

    const leftContent = (
        <LeftSideListWidget title={t("statistic")}
                            listData={mapData(mainMenuCollectionData, t, i18n.language)}
                            loading={loading}/>
    );

    return <Page leftColContent={leftContent} centralColContent={getCentralContent()}/>;
};
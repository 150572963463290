import {makeStyles} from "@material-ui/core/styles";
import bgOverlay from "./img/background_overlay.svg";

export default theme => makeStyles({
    "@global": {
        "a:not(.MuiButton-root.MuiTypography-colorSecondary)": {
            color: theme.palette.primary.main,
            "&:hover": {
                color: theme.palette.primary.light,
            },
            "&.MuiTypography-colorSecondary:hover": {
                color: theme.palette.secondary.light,
            }
        }
    },
    outer: {
        backgroundColor: theme.mainContainer.backgroundColor,
        backgroundImage: `url(${bgOverlay})`,
        backgroundSize: 1100,
        backgroundRepeat: "repeat",
        backgroundPositionX: -50,
        minHeight: "100vh",
    },
    inner: {
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
        width: "100%",
        maxWidth: 1280,
        margin: "0 auto",
        position: "relative",
        borderWidth: 0,
        borderColor: theme.mainContainer.borderColor,
        borderStyle: "solid",
        [theme.breakpoints.up("lg")]: {
            borderWidth: "0 1px",
        },
        [theme.breakpoints.up("md")]: {
            boxShadow: theme.mainContainer.innerShadow,
        },
    },
    container: {
        paddingBottom: 230,
        [theme.breakpoints.up("sm")]: {
            paddingBottom: 120,
        }
    },
    rightColumn: {
        order: 2,
        marginBottom: 50,
        zIndex: 3,
        [theme.breakpoints.up("md")]: {
            order: 3,
            width: 160,
            minWidth: 160,
            flexGrow: 0,
            boxSizing: "content-box",
        },
        "&>div": {
            position: "sticky",
            width: "100%",
            top: 20,
        }
    }
});
import gql from "graphql-tag";

export default gql`
{
  mainMenuCollectionData {
    stats {
      beverages
      brands
      companies
      cities
      regions
      countries
      conditions
      years
      counterparties
    }      
  }
}`;
import {lighten} from "@material-ui/core";

export const createColor = (index, baseColor) => {
    return lighten(baseColor, (index + 1) * 0.15);
};

export default (statData, t, baseColor) => {
    if (!statData) {
        return;
    }
    return statData
        .filter((md) => md.amount > 0)
        .map((md, index) => ({
            name: t(`condition_${md.condition}`),
            amount: md.amount,
            color: createColor(index, baseColor),
        }));
}

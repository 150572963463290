import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_BLOG_SECTIONS from "./LeftSideBlogSectionListWidgetGraphql";
import LeftSideBlogSectionListWidget from "./LeftSideBlogSectionListWidget";
import withDataTranslation from "../lib/translationHelper";

export default compose(
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_BLOG_SECTIONS),
)(LeftSideBlogSectionListWidget);
import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import LeftSideDynamicWidget from "./components/LeftSideDynamicWidgetContainer";
import StandalonePage from "./components/StandalonePageContentContainer";
import {mapBlogPostData} from "./BlogPostListPageDataMapper";
import BlogPostList from "./components/BlogPostList";
import {mapItemData} from "./ItemListPageDataMapper";
import CollectionGallery from "./components/CollectionGalleryContainer";
import LeftSideCreatedListWidget from "./components/LeftSideCreatedListWidgetContainer";
import LeftSideRandomItemWidget from "./components/LeftSideRandomItemWidgetContainer";

export default ({t, dt, i18n, setBreadcrumbs, setPageTitle, siteName, ad, data: {blogPosts, items, loading}, classes}) => {
    useEffect(() => {
        setPageTitle(siteName || t('main_page'));
        setBreadcrumbs([]);
    }, [t, setBreadcrumbs, setPageTitle, siteName, i18n.language]);

    const centralContent = (
        <React.Fragment>
            <StandalonePage pageType="main"/>
            {blogPosts && blogPosts.list.length > 0 && (
                <h2 className={classes.subHeader}>{t("recent_publications")}</h2>
            )}
            <BlogPostList data={mapBlogPostData(blogPosts, dt, i18n.language)}
                          numPages={1}
                          loading={loading}
            />
            {ad}
            {items && items.list.length > 0 && (
                <h2 className={classes.subHeader}>{t("recent_in_collection")}</h2>
            )}
            <CollectionGallery items={mapItemData(items, dt, i18n.language, "main")}
                               numPages={1}
                               loading={loading}/>
        </React.Fragment>
    );

    const leftContent = (
        <React.Fragment>
            <LeftSideRandomItemWidget/>
            <LeftSideDynamicWidget title={t("statistic")}/>
            <LeftSideCreatedListWidget variant="last_month"/>
        </React.Fragment>
    );

    return <Page leftColContent={leftContent} centralColContent={centralContent}/>;
};
import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import {mapBlogPostData} from "./BlogPostPageDataMapper";
import LeftSideBlogSectionListWidget from "./components/LeftSideBlogSectionListWidgetContainer";
import NotFoundPage from "./NotFoundPageContainer";
import AdminButtons from "./components/AdminButtonsContainer";
import ShareButtons from "./components/ShareButtonsContainer";
import {Helmet} from "react-helmet/es/Helmet";
import {internalLinkClickHandler} from "./lib/urlHelper";

export default ({
                    t,
                    dt,
                    i18n,
                    setPageTitle,
                    setBreadcrumbs,
                    user,
                    windowTitle,
                    data: {blogPostData, loading, error},
                    classes,
                    ad,
                    history
                }) => {
    const mappedData = mapBlogPostData(blogPostData, dt, i18n.language);

    useEffect(() => {
        let blogPostTitle = "";
        let breadcrumbs = [{
            name: t("blog"),
            link: `/${i18n.language}/blog/`,
        }];
        if (mappedData) {
            if (mappedData.section) {
                breadcrumbs.push({
                    name: mappedData.section.title,
                    link: mappedData.section.url,
                });
            }
            blogPostTitle = mappedData.title;
            breadcrumbs.push({
                name: blogPostTitle,
            });
        }
        setBreadcrumbs(breadcrumbs);
        setPageTitle(`${blogPostTitle} – ${t("blog")}`, loading);
    }, [setPageTitle, setBreadcrumbs, t, i18n, mappedData, loading]);

    if (error) {
        return <NotFoundPage/>;
    }

    const centralContent = (
        <React.Fragment>
            {mappedData && (
                <React.Fragment>
                    <Helmet>
                        <meta property="og:title" content={mappedData.title}/>
                        {mappedData.introduction && (
                            <meta property="og:description" content={mappedData.introduction}/>
                        )}
                        <meta property="og:url" content={mappedData.url}/>
                        <meta property="og:locale" content={i18n.language}/>
                        {mappedData.picture && (
                            <meta property="og:image" content={mappedData.picture}/>
                        )}
                    </Helmet>
                    {mappedData.introduction && (
                        <p className={classes.introduction}>{mappedData.introduction}</p>
                    )}
                    <div className={classes.blogPostContent}
                         onClick={internalLinkClickHandler(history)}
                         dangerouslySetInnerHTML={{
                             __html: unescape(mappedData.text)
                         }}/>
                    <p className={classes.created}>{mappedData.created}</p>
                    <ShareButtons title={windowTitle}
                                  description={mappedData.introduction}
                                  imageUrl={mappedData.picture}/>
                    <AdminButtons editUrl={mappedData.adminUrl} user={user}/>
                </React.Fragment>
            )}
            {ad}
        </React.Fragment>
    );

    const leftContent = <LeftSideBlogSectionListWidget/>;

    return <Page leftColContent={leftContent} centralColContent={centralContent}/>;
};
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_TRADE_SCAN_BASKET_LIST from "./LeftSideTradeScanBasketWidgetGraphql";
import LeftSideTradeScanBasketWidget from "./LeftSideTradeScanBasketWidget";
import {withStyles} from "@material-ui/core/styles";
import styles from "./LeftSideTradeScanBasketWidgetStyles";

export default compose(
    withStyles(styles),
    withTranslation(),
    graphql(QUERY_TRADE_SCAN_BASKET_LIST),
)(LeftSideTradeScanBasketWidget);
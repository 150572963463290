export default () => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& h1": {
            marginTop: 40,
            marginBottom: 150,
        }
    }
});
import React from "react";
import mapData from "./CompanyStatDataMapper";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    LinearProgress,
    Link
} from "@material-ui/core";
import TableStatStub from "./TableStatStubContainer";

export default ({t, dt, classes, data: {companyStatData, loading, error}, ad}) => {
    if (loading) {
        return <TableStatStub/>;
    }
    if (error) {
        return null;
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("company")}</TableCell>
                        <TableCell colSpan={2} className={classes.amountHeader}>{t("amount")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mapData(companyStatData, dt).map((company, index) => (
                        <React.Fragment key={index}>
                            {index === 50 && companyStatData.length > 60 && (
                                <tr key="ad_row">
                                    <td colSpan={4} className={classes.adCell}>{ad}</td>
                                </tr>
                            )}
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    {company.website
                                        ? (
                                            <Link href={company.website} target="_blank" color="secondary">
                                                {company.name}
                                            </Link>
                                        )
                                        : company.name}
                                </TableCell>
                                <TableCell className={classes.amountCell}>{company.amount}</TableCell>
                                <TableCell className={classes.shareCell}>
                                    <LinearProgress color="secondary" variant="determinate" value={company.share}/>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
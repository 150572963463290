import React, {useEffect} from "react";
import SVG from "react-inlinesvg";
import Alert from "@material-ui/lab/Alert";

export default ({t, dt, i18n, classes, mapData, mapStatic, loading, history}) => {

    let clickEventListenerSet = true;

    function mapClickListener(e) {
        if (!e.target.className.baseVal) {
            return;
        }

        if (!(e.target.className.baseVal.indexOf("map__land_") === -1)
            && !(e.target.className.baseVal.indexOf("map__click-area") === -1)
        ) {
            return;
        }

        const url = e.target.getAttribute("data-href");
        if (!url) {
            return;
        }

        const svgNode = document.getElementById("map");
        if (svgNode) {
            svgNode.classList.add("map_loading");
        }

        history.push(`/${i18n.language}${url}${window.location.search}`);
    }

    const getAmountCategory = (amount) => {
        const roundOrder = Math.pow(10, Math.floor(Math.log10(amount)));
        return Math.min(
            roundOrder * Math.round(amount / roundOrder),
            10000
        );
    };

    const svgDomInit = () => {
        const svgNode = document.getElementById("map");
        if (!svgNode) {
            return;
        }

        if (!clickEventListenerSet) {
            svgNode.addEventListener("click", mapClickListener);
            clickEventListenerSet = true;
        }

        let width, height;
        [, , width, height] = svgNode.getAttribute("viewBox").split(" ");
        const proportion = width / height;
        if (proportion < 1) {
            svgNode.style["max-width"] = proportion * 750;
        }

        if (!mapData || loading) {
            return;
        }

        const data_title_ids = mapData.map((md) => `map_title_${md.location.code}`);
        svgNode.querySelectorAll("[id^='map_title_']").forEach(
            (titleNode) => {
                if (!titleNode) {
                    return;
                }
                titleNode.innerHTML = "";
                if (!data_title_ids.includes(titleNode.id)) {
                    titleNode.innerHTML = t(titleNode.id);
                }
            }
        );

        mapData.forEach((md) => {
            const landNode = svgNode.getElementById(`map_land_${md.location.code}`);
            if (!landNode) {
                return;
            }

            const clickAreaNode = svgNode.getElementById(`map_click-area_${md.location.code}`);
            let title = dt("name", md.location.translations);

            const titleNode = svgNode.getElementById(`map_title_${md.location.code}`);
            if (titleNode) {
                const limit = titleNode.getAttribute("data-limit");
                if (limit && limit < title.length) {
                    titleNode.innerHTML = `${title.substring(0, limit).trimEnd()}.`;
                } else {
                    titleNode.innerHTML = title;
                }
            }

            if (md.amount > 0) {
                landNode.classList.add(`map__land_amount-${getAmountCategory(md.amount)}`);
                landNode.classList.add("map__land_active");
                clickAreaNode && clickAreaNode.classList.add("map__land_active");
                title += ` (${t("amount")}: ${md.amount})`;
                let landUrl = landNode.getAttribute("data-href");
                if (!landUrl) {
                    landUrl = md.location.url;
                }
                landNode.setAttribute("data-href", landUrl);
            } else {
                title += ` (${t("nothing")})`;
            }

            const pointerNode = svgNode.getElementById(`map_pointer_${md.location.code}`);
            pointerNode && pointerNode.classList.add("map__pointer_show");

            landNode.innerHTML = `<title>${title}</title>`;
            clickAreaNode && (clickAreaNode.innerHTML = `<title>${title}</title>`);
        });

        svgNode.classList.remove("map_loading");
    };

    useEffect(svgDomInit, [mapData, loading, t]);

    return (
        <React.Fragment>
            <SVG src={mapStatic} id="map"
                 onLoad={
                     () => {
                         clickEventListenerSet = false;
                         svgDomInit();
                     }
                 }
                 className={`${classes.root} MuiPaper-elevation1 MuiPaper-rounded map_loading`}/>
            <Alert severity="info" className={classes.showDesktop}>{t("move_cursor_over_region")}</Alert>
        </React.Fragment>
    );
}

import gql from "graphql-tag";

export default gql`
    query {
      hostInfo {
        user {
          email
        }
        translations {
          language
          postalAddress
        }
      }
    }`;

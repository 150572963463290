import gql from "graphql-tag";

export default gql`
query {  
  mainMenuBlogData {
    sections {
      url
      translations {
        language
        title
      }
    }
  }
}`;
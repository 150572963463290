import {fade} from "@material-ui/core";

export default theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    menuButton: {
        color: 'inherit',
        marginRight: theme.spacing(2),
    },
    nested1: {
        color: theme.palette.text.primary,
    },
    nested2: {
        paddingLeft: theme.spacing(6),
        backgroundColor: fade(theme.palette.primary.main, 0.03),
        color: fade(theme.palette.text.primary, 0.9),
    },
    nested3: {
        paddingLeft: theme.spacing(8),
        backgroundColor: "#f3f3f3",
        color: fade(theme.palette.text.primary, 0.9),
    },
});
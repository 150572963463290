export default () => ({
    dialog: {
        "& .MuiDialog-paper": {
            margin: 0,
            maxHeight: "100vh",
        },
    },
    picture: {
        display: "block",
        cursor: "zoom-in",
        imageOrientation: "none",
    },
    zoomPicture: {
        display: "block",
        width: "fit-content",
        imageOrientation: "none",
    }
});
export default theme => ({
    leftColumn: {
        order: 3,
        marginBottom: 50,
        [theme.breakpoints.up("md")]: {
            order: 1,
        }
    },
    centralColumn: {
        order: 1,
        marginBottom: 50,
        [theme.breakpoints.up("md")]: {
            order: 2,
            maxWidth: "calc(75% - 180px)",
        },
        "& img": {
            maxWidth: "100%",
        }
    },
})
import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import mapData from "./TradeScanPageDataMapper";
import {Paper} from "@material-ui/core";
import TradeScanImage from "./components/TradeScanImageContainer";
import LeftSideTradeScanListWidget from "./components/LeftSideTradeScanListWidgetContainer";
import Alert from "@material-ui/lab/Alert";
import TradeScanBasketSelection from "./components/TradeScanBasketSelectionContainer";
import NotFoundPage from "./NotFoundPageContainer";

export default ({t, dt, i18n, user, setPageTitle, setBreadcrumbs, data: {tradeScanData, loading, error}, classes, ad, match: {params}}) => {
    const mappedData = mapData(tradeScanData, dt, i18n.language);

    useEffect(() => {
        let pageTitle = t("scan");
        setPageTitle(mappedData
            ? `${pageTitle}: ${mappedData.name || mappedData.code}`
            : "", loading);
        setBreadcrumbs([
            {
                name: t("trade"),
                link: `/${i18n.language}/trade/`,
            }, {
                name: t("more_scans"),
                link: `/${i18n.language}/trade/scans/`,
            }, {
                name: mappedData ? `${t("scan")} ${mappedData.code}` : "",
            }
        ]);
    }, [t, i18n, mappedData, setPageTitle, setBreadcrumbs, loading]);

    if (error) {
        return <NotFoundPage/>;
    }

    const centralContent = (
        <React.Fragment>
            {!user && <Alert severity="info" className={classes.message}>{t("please_authenticate")}</Alert>}
            <TradeScanBasketSelection tradeScanCode={params.tradeScanCode} showMessage/>
            < Paper className={classes.root}>
                <TradeScanImage {...mappedData}/>
            </Paper>
            {ad}
        </React.Fragment>
    );

    const leftContent = <LeftSideTradeScanListWidget/>;

    return <Page leftColContent={leftContent} centralColContent={centralContent}/>;
};
import {roundTo} from "../lib/numberHelper";

export default (brandStatData, dt) => {
    if (!brandStatData) {
        return;
    }

    const maxAmount = brandStatData[0].amount;

    return brandStatData
        .filter((statData) => statData.amount > 0)
        .map((statData) => ({
            name: dt("name", statData.brand.translations),
            amount: statData.amount,
            share: roundTo(statData.amount * 100 / maxAmount, 1),
        }));
}

import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import withDataTranslation from "../lib/translationHelper";
import {withStyles} from "@material-ui/core/styles";
import compose from "recompose/compose";
import QUERY_STAT_DATA from "./CounterpartyStatGraphql";
import CounterpartyStat from "./CounterpartyStat";
import styles from "./CounterpartyStatStyles";

export default compose(
    withStyles(styles),
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_STAT_DATA)
)(CounterpartyStat);
import {sortByName} from "./lib/arrayHelper";
import unknownFlag from "./img/unknown_flag.svg"

export default (data, t, dt, language, totalItemAmount) => {
    if (!data) {
        return [];
    }

    let result = data.map(continent => ({
        url: `/${language}${continent.url}`,
        name: dt("name", continent.translations),
        code: continent.code,
        itemAmount: continent.itemAmount,
        countries: mapCountries(continent.countries, dt, language),
    }));
    sortByName(result);

    const unknownItemAmount = totalItemAmount - data.reduce((sum, continent) => sum + continent.itemAmount, 0);
    if (unknownItemAmount > 0) {
        result.push({
            name: t("unknown"),
            code: "unknown",
            itemAmount: unknownItemAmount,
            countries: [{
                url: `/${language}/collection/country/unknown/`,
                flag: unknownFlag,
                name: t("unknown"),
                itemAmount: unknownItemAmount,
            }]
        });
    }

    return result;
};

const mapCountries = (countries, dt, language) => {
    let result = countries.map(country => ({
        url: `/${language}${country.url}`,
        flag: country.flagUrl,
        name: dt("name", country.translations),
        itemAmount: country.itemAmount,
    }));
    sortByName(result);

    return result;
};
import gql from "graphql-tag";

export default gql`
query ($page: Int) {
  tradeScans(page: $page) {
    list {
      code
      url
      previewUrl
      pictureUrl
      modified
      translations {
        language
        name
      }
    }
    numPages
    page
  }
}`;
import {createMuiTheme} from "@material-ui/core/styles";

export const defaultThemeObject = {
    palette: {
        primary: {
            main: "#666",
        },
        secondary: {
            main: "#909090",
        },
        background: {
            default: "#eee",
            paper: "#fff",
        },
        text: {
            primary: "#333",
            secondary: "#333",
        }
    },
    typography: {
        h1: {
            fontWeight: 300,
        },
        h2: {
            fontWeight: 300,
        },
        h3: {
            fontWeight: 300,
            paddingTop: 30,
            paddingBottom: 16,
        },
        h4: {
            fontWeight: 400,
        },
        h5: {
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
        },
        body2: {
            fontSize: "1rem",
        },
    },
    spacing: factor => `${0.25 * factor}rem`,
    mainContainer: {
        borderColor: "#eee",
        backgroundColor: "#888",
        innerShadow: "inset 0 -22px 22px 0 rgba(0,0,0,0.65)",
    }
};

export default createMuiTheme(defaultThemeObject);
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_TRADE_COUNTRIES from "./LeftSideTradeCountryListWidgetGraphql";
import LeftSideTradeCountryListWidget from "./LeftSideTradeCountryListWidget";
import withDataTranslation from "../lib/translationHelper";

export default compose(
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_TRADE_COUNTRIES),
)(LeftSideTradeCountryListWidget);
export default () => ({
    message: {
        marginBottom: 15,
        "& .MuiAlert-message": {
            width: "100%",
        }
    },
    userText: {
        width: "100%",
        marginBottom: 15,
    },
});
import React from "react";
import ReactImageMagnify from "react-image-magnify";

export default ({t, name, picture, width, height, classes}) => {
    if (!picture) {
        return null;
    }

    return <ReactImageMagnify {...{
        enlargedImagePosition: "over",
        isHintEnabled: true,
        shouldHideHintAfterFirstActivation: false,
        hintTextMouse: t("hover_to_zoom"),
        hintTextTouch: t("long_touch_to_zoom"),
        enlargedImageClassName: classes.bigImage,
        smallImage: {
            alt: name,
            isFluidWidth: true,
            src: picture
        },
        largeImage: {
            src: picture,
            width: width > 750 ? width : "100%",
            height: width > 750 ? height : "100%",
        }
    }} />;
};
import {formatDateTime} from "./lib/dateHelper";

export default (data, dt, language) => {
    if (!data) {
        return;
    }

    return {
        code: data.code,
        picture: data.pictureUrl,
        width: data.originalWidth,
        height: data.originalHeight,
        modified: formatDateTime(data.modified, language),
        name: dt("name", data.translations),
    };
};

export default theme => ({
    list: {
        marginBottom: 30,
        listStyle: "none",
        padding: 0,
        [theme.breakpoints.up("sm")]: {
            columnCount: 2,
        },
        "& li": {
            marginBottom: 5,
        }
    }
});
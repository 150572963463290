import {sortByName} from "../lib/arrayHelper";

export default (data, dt, language) => {
    if (!data) {
        return [];
    }

    let result = data.map((setAmount) => ({
        url: `/${language}${setAmount.set.url}`,
        name: dt("name", setAmount.set.translations),
        amount: setAmount.amount,
    }));
    sortByName(result);

    return result;
};

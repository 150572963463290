import React from "react";
import {sortByName} from "../lib/arrayHelper";
import {Skeleton} from "@material-ui/lab";

export const Menu = (url, name, children = [], extraAction = () => null) => ({
    name: name,
    url: url,
    code: url
        .split("/")
        .filter(x => x)
        .map(x => x[0].toUpperCase() + x.substr(1))
        .join(""),
    children: children,
    extraAction: extraAction,
});

export default ({data, t, dt, i18n: {language: l}, user}) => {
    if (data.loading) {
        return [...Array(5)].map((_, index) =>
            Menu("", <Skeleton variant="text" width={[90, 70, 80, 50, 90][index]}/>)
        );
    }

    const collectionData = data["mainMenuCollectionData"];
    const tradeData = data["mainMenuTradeData"];
    const blogData = data["mainMenuBlogData"];

    let menus = [];

    let collectionMenu = [];
    collectionMenu.push(Menu(`/${l}/collection/`, t("countries")));
    if (collectionData) {
        if (collectionData["sets"]) {
            collectionMenu.push(Menu(`/${l}/collection/sets/`, t("sets")));
        }
        if (collectionData["inside"]) {
            collectionMenu.push(Menu(`/${l}/collection/inside/`, t("inside_pictures")));
        }
        if (collectionData["defective"]) {
            collectionMenu.push(Menu(`/${l}/collection/defective/`, t("defective")));
        }
    }

    let statMenu = [];
    if (collectionData) {
        if (collectionData["stats"]["companies"]) {
            statMenu.push(Menu(`/${l}/stats/companies/`, t("companies")));
        }
        if (collectionData["stats"]["brands"]) {
            statMenu.push(Menu(`/${l}/stats/brands/`, t("brands")));
        }
        if (collectionData["stats"]["beverages"]) {
            statMenu.push(Menu(`/${l}/stats/beverages/`, t("beverages")));
        }
        if (collectionData["stats"]["countries"]) {
            statMenu.push(Menu(`/${l}/stats/countries/`, t("countries")));
        }
        if (collectionData["stats"]["regions"]) {
            statMenu.push(Menu(`/${l}/stats/regions/`, t("regions")));
        }
        if (collectionData["stats"]["cities"]) {
            statMenu.push(Menu(`/${l}/stats/cities/`, t("cities")));
        }
        if (collectionData["stats"]["years"]) {
            statMenu.push(Menu(`/${l}/stats/years/`, t("years")));
        }
        if (collectionData["stats"]["conditions"]) {
            statMenu.push(Menu(`/${l}/stats/conditions/`, t("quality")));
        }
        if (collectionData["stats"]["counterparties"]) {
            statMenu.push(Menu(`/${l}/stats/counterparties/`, t("collectors")));
        }
    }
    collectionMenu.push(Menu(`/${l}/stats/`, t("statistic"), statMenu));

    collectionMenu.push(Menu(`/${l}/collection/created/`, t("creation_period")));
    menus.push(Menu(`/${l}/collection/`, t("collection"), collectionMenu));

    if (collectionData && (collectionData["trade"] || tradeData["scans"])) {
        let tradeMenu = [];
        if (collectionData["trade"]) {
            tradeMenu.push(Menu(`/${l}/trade/`, t("countries")));
        }
        if (tradeData["scans"]) {
            tradeMenu.push(Menu(`/${l}/trade/scans/`, t("more_scans")));
        }
        if (user) {
            tradeMenu.push(Menu(`/${l}/trade/basket/`, t("my_basket")));
        }
        menus.push(Menu(`/${l}/trade/`, t("trade"), tradeMenu));
    }

    if (collectionData && collectionData["continentMaps"].length > 0) {
        let continentMapMenu = collectionData["continentMaps"].map(
            continent => {
                let countryMapMenu = collectionData["countryMaps"]
                    .filter(country => country.parent.code === continent.code)
                    .map(country => Menu(
                        `/${l}${country.mapUrl}`,
                        dt("name", country.translations))
                    );
                sortByName(countryMapMenu);
                return Menu(`/${l}${continent.mapUrl}`, dt("name", continent.translations), countryMapMenu)
            }
        );
        sortByName(continentMapMenu);
        menus.push(Menu(`/${l}/maps/`, t("world_map"), continentMapMenu));
    }

    if (blogData && blogData["blog"]) {
        let blogMenu = [];
        blogMenu.push(Menu(`/${l}/blog/`, t('recent_publications')));
        let sections = blogData["sections"].map((section) => ({
            name: dt("title", section.translations),
            url: section.url,
        }));
        sortByName(sections);
        const sectionMenu = sections.map((section) => Menu(`/${l}${section.url}`, section.name));
        if (sectionMenu.length > 0) {
            blogMenu.push(Menu(`/${l}/blog/section/`, t('sections'), sectionMenu));
        }
        menus.push(Menu(`/${l}/blog/`, t("blog"), blogMenu));
    }

    let contactsMenu = [];
    contactsMenu.push(Menu(`/${l}/about/`, t("about_me")));
    if (collectionData) {
        if (collectionData["thanks"]) {
            contactsMenu.push(Menu(`/${l}/thanks/`, t("thanks")));
        }
        if (collectionData["websites"]) {
            contactsMenu.push(Menu(`/${l}/links/`, t("links")));
        }
        if (collectionData["blacklist"]) {
            contactsMenu.push(Menu(`/${l}/blacklist/`, t("black_list")));
        }
    }
    menus.push(Menu(`/${l}/about/`, t("contacts"), contactsMenu));

    return menus;
};
import {Card, CardContent, CardHeader} from "@material-ui/core";
import React from "react";

export default ({title, content, noContentPadding = false, noHideOverflow = false, classes, contentClassName}) => (
    <Card className={classes.root} style={noHideOverflow ? {overflow: "unset"} : {}}>
        <CardHeader title={title} className={classes.header}/>
        {noContentPadding
            ? <div>{content}</div>
            : <CardContent className={`classes.content ${contentClassName || ""}`}>{content}</CardContent>
        }
    </Card>
);
import gql from "graphql-tag";

export default gql`
  query {
    hostInfo {
      hostName
      theme
      iconUrl
      logoUrl
      languages {
        code
        name
      }
      translations {
        language
        siteName
        footerText
        postalAddress
      }
    }
  }`;
import {sortByName} from "../lib/arrayHelper";

export default (data, dt, language) => {
    if (!data) {
        return [];
    }

    let result = data.map((locationAmount) => ({
        url: `/${language}${locationAmount.location.insideUrl}`,
        flag: locationAmount.location.flagUrl,
        name: dt("name", locationAmount.location.translations),
        amount: locationAmount.amount,
    }));
    sortByName(result);

    return result;
};

import gql from "graphql-tag";

export default gql`
  {
    groupedSets {
      url
      flagUrl
      code
      translations {
        name
        language
      }
      sets {
        url
        pictureType
        itemAmount
        completeAmount
        translations {
          name
          language
        }
      }
    }
    countrySets (countryCode: "unknown") {
      set {
        url
        pictureType
        itemAmount
        completeAmount
        translations {
          name
          language
        }
      }
    }
  }`;
import React from "react";
import {Link} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import ContentAdWrapper from "./ContentAdWrapperContainer";
import GroupedListStub from "./GroupedListStubContainer";

export default ({groupedCountries, classes, ad, loading}) => {
    if (loading) {
        return <GroupedListStub/>;
    }
    if (!groupedCountries) {
        return null;
    }

    return groupedCountries.map((continent, index) => (
        <React.Fragment key={`continent_group_${index}`}>
            {index === Math.ceil(groupedCountries.length / 2) && <ContentAdWrapper ad={ad}/>}
            <h2 className={classes.continent} id={continent.code}>
                {continent.url
                    ? <Link component={RouterLink} to={continent.url}>{continent.name}</Link>
                    : continent.name
                }
            </h2>
            <ul className={classes.countryList}>
                {continent.countries.map((country, index) => (
                    <li key={`set_${index}`}>
                        <Link component={RouterLink} to={country.url}>
                            {country.flag && <img src={country.flag} alt={country.name}/>}
                            {country.name}
                        </Link>
                        <span className={classes.itemAmount}>{country.itemAmount}</span>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    ));
};
import gql from "graphql-tag";

export default gql`
  query ($page: Int, $countryCode: String, $regionClientId: String, $setClientId: Int, $defective: Boolean, 
         $created: Boolean, $createdFrom: Date, $createdTill: Date, $inside: Boolean, $search: Boolean, 
         $searchPhrase: String, $searchBy: String, $language: String, $itemCode: String) {
    items(page: $page, countryCode: $countryCode, regionClientId: $regionClientId, setClientId: $setClientId, 
          defective: $defective, created: $created, createdFrom: $createdFrom, createdTill: $createdTill, 
          inside: $inside, search: $search, searchPhrase: $searchPhrase, searchBy: $searchBy, language: $language,
          itemCode: $itemCode) {
      list {
        url
        code
        created
        modified
        twistOff
        since
        companies {
          website
          translations {
            language
            shortName
          }
        }
        beverages {
          color
          translations {
            language
            name
          }
        }
        brands {
          translations {
            language
            name
          }
        }
        locations {
          country {
            flagUrl
            url
            translations {
              language
              name
            }
          }
          region {
            url
            translations {
              language
              name
            }
          }
          city {
            translations {
              language
              name
            }
          }
        }
        sets {
          url
          translations {
            language
            name
          }
        }
        marks {
          markFileUrl
          translations {
            language
            name
          }
        }
        pictures {
          pictureType
          previewUrl
          pictureUrl
        }
        translations {
          language
          comments
        }
      }
      numPages
      page
    }
    locationAncestors(code: $countryCode, regionClientId: $regionClientId) {
      url
      translations {
        name
        language
      }
    }
    set(clientId: $setClientId) {
      pictureType
      translations {
        language
        name
      }
    }
  }`;
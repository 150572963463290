import gql from "graphql-tag";

export default gql`
  query ($pageType: String!) {
    pageContent(pageType: $pageType) {
      adminUrl
      translations {
        language
        content
      }
    }
  }`;
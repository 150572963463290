import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import LeftSideTradeCountryListWidget from "./components/LeftSideTradeCountryListWidgetContainer";
import TradeGallery from "./components/TradeGalleryContainer";
import {mapCountryData, mapItemData} from "./TradeCountryPageDataMapper";
import NotFoundPage from "./NotFoundPageContainer";

export default ({t, dt, i18n, setPageTitle, setBreadcrumbs, data: {items, country, loading, error}, match: {params}}) => {
    useEffect(() => {
        let pageName = t("trade");
        let breadcrumbs = [
            {
                name: t("trade"),
                link: `/${i18n.language}/trade/`,
            }
        ];

        if (!loading) {
            const countryName = country ? mapCountryData(country, dt) : t("unknown");
            pageName += `: ${countryName}`;
            breadcrumbs.push({
                name: countryName,
                link: undefined,
            });
        }

        setBreadcrumbs(breadcrumbs);
        setPageTitle(pageName, loading);
    }, [loading, setBreadcrumbs, setPageTitle, country, t, dt, i18n]);

    if (error) {
        return <NotFoundPage/>;
    }

    const getBaseUrl = () => {
        if (!params) {
            return;
        }
        return `/${i18n.language}/trade/country/${params.countryCode}/`;
    };

    const getLeftContent = () => {
        return <LeftSideTradeCountryListWidget/>;
    };

    const getCentralContent = () =>
        <TradeGallery items={mapItemData(items, dt)}
                      numPages={items && items.numPages}
                      page={items && items.page}
                      baseUrl={getBaseUrl()}
                      loading={loading}/>;

    return <Page leftColContent={getLeftContent()} centralColContent={getCentralContent()}/>;
};
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {connect} from "react-redux";
import {setLanguages, setSiteName} from "./redux/actions";
import AppWrapper from "./AppWrapper";
import {graphql} from "react-apollo";
import QUERY_HOST_DATA from "./AppWrapperGraphql";
import withDataTranslation from "./lib/translationHelper";
import {withRouter} from "react-router-dom";

export default compose(
    withRouter,
    withTranslation(),
    withDataTranslation,
    connect(
        state => ({
            windowTitle: state.common.windowTitle,
        }),
        {
            setSiteName,
            setLanguages,
        },
    ),
    graphql(QUERY_HOST_DATA),
)(AppWrapper);
import React, {useState} from "react";
import {Paper, Switch, Tooltip, Typography} from "@material-ui/core";
import Pagination from "./PaginationContainer";
import TradeGalleryStub from "./TradeGalleryStubContainer";
import ZoomablePicture from "./ZoomablePictureContainer";
import TwistOffIcon from "./TwistOffIconContainer";
import MUTATION_TOGGLE_BASKET from "./TradeGalleryGraphql";
import Alert from "@material-ui/lab/Alert";
import {Helmet} from "react-helmet/es/Helmet";

export default ({t, items, numPages, page, baseUrl, loading, classes, basket, addBasketItem, deleteBasketItem, user, client}) => {
    const [currentItem, setCurrentItem] = useState();

    if (!items || loading) {
        return <TradeGalleryStub/>;
    }

    const toggleBasketItem = itemCode => () => {
        client.mutate({
            variables: {
                itemCode: itemCode,
            },
            mutation: MUTATION_TOGGLE_BASKET,
        })
            .then(({data}) => {
                if (data.toggleBasketItem.state) {
                    addBasketItem(itemCode);
                } else {
                    deleteBasketItem(itemCode);
                }
            });
    };

    const TooltipContent = ({item}) => (
        <React.Fragment>
            <h1>{t("code")}: {item.code}</h1>
            {item.companies.map((company, index) => <Typography key={index}>{company}</Typography>)}
            {item.brands.map((brand, index) => <Typography key={index}>{brand}</Typography>)}
            {item.beverages.map((beverage, index) => <Typography key={index}>{beverage}</Typography>)}
            {item.locations.map((location, index) => <Typography key={index}>{location}</Typography>)}
        </React.Fragment>
    );

    const itemInBasket = item => basket && basket.includes(item.code);

    if (items && items.length === 0) {
        return <Alert severity="info">{t("empty")}</Alert>;
    }

    return (
        <React.Fragment>
            {page > 1 && (
                <Helmet>
                    <link rel="canonical" href={baseUrl}/>
                </Helmet>
            )}
            <Alert severity="warning" className={classes.message}>{t("collection_picture_warning")}</Alert>
            {!user && <Alert severity="info" className={classes.message}>{t("please_authenticate")}</Alert>}
            <Paper className={classes.previews}>
                {items.map(item => (
                    <Tooltip title={<TooltipContent item={item}/>}
                             arrow
                             placement="right"
                             key={`trade_item_${item.code}`}
                             open={currentItem === item.code}
                    >
                        <div className={classes.previewWrapper}
                             onMouseMove={() => setCurrentItem(item.code)}
                             onMouseLeave={() => setCurrentItem(null)}>
                            <ZoomablePicture src={item.picture.preview}
                                             originalUrl={item.picture.original}
                                             alt={item.code}
                            />
                            <Typography
                                className={`${classes.itemTitle} ${itemInBasket(item) && classes.pickedItemTitle}`}>
                                {item.code}
                            </Typography>
                            {item.twistOff
                                ? <TwistOffIcon/>
                                : null
                            }
                            {user && <Switch checked={itemInBasket(item)}
                                             onChange={toggleBasketItem(item.code)}
                                             name={`basket_button_${item.code}`}
                                             className={classes.basketButton}/>
                            }
                        </div>
                    </Tooltip>
                ))}
            </Paper>
            <Pagination numPages={numPages} page={page} baseUrl={baseUrl}/>
        </React.Fragment>
    );
}
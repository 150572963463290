import gql from "graphql-tag";

export default gql`
  {
    allTradeCountries {
      location {
        tradeUrl
        flagUrl
        translations {
          name
          language
        }
      }
      amount
    }
    totalTradeItemAmount
  }`;
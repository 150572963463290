import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import {mapCounterpartyData, mapMenuData} from "./CounterpartyListPageDataMapper";
import CounterpartyList from "./components/CounterpartyListContainer";
import LeftSideListWidget from "./components/LeftSideListWidgetContainer";
import NotFoundPage from "./NotFoundPageContainer";

export default ({t, dt, i18n, setPageTitle, setBreadcrumbs, data: {counterparties, mainMenuCollectionData, loading, error}, match: {params}, ad}) => {
    useEffect(() => {
        let breadcrumbItems = [];
        let listTypeName = "";
        if (params.counterpartyPublicType === "thanks") {
            listTypeName = t("thanks");
        } else if (params.counterpartyPublicType === "links") {
            listTypeName = t("links");
        } else if (params.counterpartyPublicType === "blacklist") {
            listTypeName = t("black_list");
        }
        breadcrumbItems.push({
            name: listTypeName,
        });
        setPageTitle(listTypeName);
        setBreadcrumbs(breadcrumbItems);
    }, [i18n, params.counterpartyPublicType, t, setPageTitle, setBreadcrumbs]);

    if (error) {
        return <NotFoundPage/>;
    }

    const getLeftContent = () =>
        <LeftSideListWidget title={t("contacts")}
                            listData={mapMenuData(mainMenuCollectionData, t, i18n.language)}
                            loading={loading}/>;

    const getCentralContent = () =>
        <CounterpartyList data={mapCounterpartyData(counterparties, dt)}
                          numPages={counterparties && counterparties.numPages}
                          page={counterparties && counterparties.page}
                          baseUrl={`/${i18n.language}/${params.counterpartyPublicType}/`}
                          ad={ad}
                          loading={loading}
        />;

    return <Page leftColContent={getLeftContent()} centralColContent={getCentralContent()}/>;
};
import gql from "graphql-tag";

export default gql`
{
  countryStatData {
    location {
      url
      flagUrl
      translations {
        name
        language
      }
      continent {
        mapUrl
        translations {
          name
          language
        }
      }
    }
    amount
  }
}`;
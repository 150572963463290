import compose from "recompose/compose";
import UserButton from "./UserButton";
import {graphql} from "react-apollo";
import QUERY_USER_DATA from "./UserButtonGraphql";
import {connect} from "react-redux";
import {setUser} from "../redux/actions";

export default compose(
    connect(
        null,
        {
            setUser
        }
    ),
    graphql(QUERY_USER_DATA)
)(UserButton);
import React from "react";
import {AppBar, IconButton, InputAdornment, InputBase, Toolbar, Typography} from "@material-ui/core";
import {Search as SearchIcon} from "@material-ui/icons";
import MobileMenu from "./MobileMenuContainer";
import MobileUserButton from "./MobileUserButton";
import MobileBasketButton from "./MobileBasketButton";

export default ({t, i18n, siteName, classes, searchQuery, setSearchQuery, history, location}) => {
    const handleSearchMouseDown = () => {
        const newSearch = `/${i18n.language}/collection/search/?q=${encodeURI(searchQuery)}`;
        if (location.search === newSearch) {
            return;
        }
        history.push(newSearch);
    };

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
    };

    return <AppBar position="static" className={classes.showMobile}>
        <Toolbar>
            <MobileMenu/>
            <Typography className={classes.title} variant="h6" noWrap>
                {siteName}
            </Typography>
            <div className={"grow"}/>
            <div className={classes.search}>
                <InputBase
                    placeholder={t("search")}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    fullWidth
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton onMouseDown={handleSearchMouseDown}
                                        color="inherit"
                                        disabled={!searchQuery}>
                                <SearchIcon/>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </div>
            <div className={classes.personalButtons}>
                <MobileUserButton/>
                <MobileBasketButton/>
            </div>
        </Toolbar>
    </AppBar>;
};
import gql from "graphql-tag";

export default gql`
  {
    groupedCountries {
      url
      code
      itemAmount
      translations {
        name
        language
      }
      countries {
        url
        flagUrl
        itemAmount
        translations {
          name
          language
        }
      }
    }
    totalItemAmount
  }`;
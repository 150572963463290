import React from "react";
import {Link} from "@material-ui/core";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {Link as RouterLink} from "react-router-dom";
import ContentAdWrapper from "./ContentAdWrapperContainer";
import GroupedListStub from "./GroupedListStubContainer";

export default ({groupedSets, classes, ad, loading}) => {
    if (loading) {
        return <GroupedListStub/>;
    }
    if (!groupedSets) {
        return null;
    }

    const getGroupHeader = country =>
        <React.Fragment>
            {country.flag && <img src={country.flag} alt={country.name}/>}
            {country.name}
        </React.Fragment>;

    return groupedSets.map((country, index) => (
        <React.Fragment key={`set_group_${index}`}>
            {index === Math.ceil(groupedSets.length / 2) && <ContentAdWrapper ad={ad}/>}
            <h2 className={classes.country} id={country.code}>
                {country.url
                    ? <Link component={RouterLink} to={country.url} children={getGroupHeader(country)}/>
                    : getGroupHeader(country)
                }
            </h2>
            <ul className={classes.setList}>
                {country.sets.map((set, index) => (
                    <li key={`set_${index}`}>
                        <Link component={RouterLink} to={set.url}>
                            {set.name}
                            {set.pictureType === "inside" && <RotateLeftIcon/>}
                        </Link>
                        <span className={classes.amount}>{set.amount}</span>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    ));
};
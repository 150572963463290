import React from "react";
import {Card, CardContent, CardMedia} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

export default ({cardAmount, classes}) => [...Array(cardAmount)].map((_, index) =>
    <Card className={classes.itemCard} key={`horizontal_card_stub_${index}`}>
        <CardMedia className={classes.media}>
            <Skeleton variant="rect"/>
        </CardMedia>
        <CardContent className={classes.content}>
            <Skeleton variant="text" width={40}/>
            <Skeleton variant="text" width={470}/>
            <Skeleton variant="text" width={120}/>
            <Skeleton variant="text" width={330}/>
            <Skeleton variant="text" width={300}/>
        </CardContent>
    </Card>
);

export default theme => ({
    previews: {
        maxWidth: 750,
        flexWrap: "wrap",
        margin: "0 auto 40px",
        overflow: "hidden",
        display: "flex",
    },
    previewWrapper: {
        display: "flex",
        position: "relative",
        width: "20%",
        paddingTop: "20%",
        [theme.breakpoints.down(620)]: {
            width: "25%",
            paddingTop: "25%",
        },
        [theme.breakpoints.down(470)]: {
            width: "33.3%",
            paddingTop: "33.3%",
        },
        [theme.breakpoints.down(320)]: {
            width: "50%",
            paddingTop: "50%",
        },
        [theme.breakpoints.down(170)]: {
            width: "100%",
            paddingTop: "100%",
        },
        "& img": {
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
        }
    },
    itemTitle: {
        position: "absolute",
        left: 0,
        top: 0,
        background: "rgba(255, 255, 255, 0.6)",
        padding: 2,
        lineHeight: 1,
    },
    pickedItemTitle: {
        background: theme.palette.secondary.main,
        color: "#fff",
    },
    basketButton: {
        position: "absolute",
        left: 0,
        bottom: 0,
    },
    message: {
        marginBottom: 15,
    },
    "@global": {
        ".MuiTooltip-popper": {
            zIndex: 1299,
        }
    }
});
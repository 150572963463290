export default () => ({
    message: {
        marginBottom: 15,
    },
    userText: {
        width: "100%",
        marginBottom: 15,
        "& .MuiInputBase-root": {
            paddingRight: 0,
        }
    },
    deleteButton: {
        marginRight: -10,
    },
});
import {fade} from "@material-ui/core/styles";

export default theme => ({
    root: {
        width: "100%",
    },
    multiLevelMenu: {
        width: "100%",
        position: "relative",
        color: theme.palette.text.primary,
        "&$depth1": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            backgroundColor: fade(theme.palette.primary.main, 0.1),
            "& > $menu": {
                "& > $multiItem": {
                    "&:hover": {
                        backgroundColor: fade(theme.palette.primary.main, 0.7),
                        color: "#fff",
                    }
                }
            }
        },
        "&$depth2": {
            visibility: "hidden",
            boxShadow: theme.shadows[2],
            justifyContent: "center",
            backgroundColor: "#ebebeb",
            position: "absolute",
            left: 0,
            top: "100%",
            zIndex: 1,
            minHeight: 56,
            "& > $menu": {
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "center",
                "& > $multiItem": {
                    position: "relative",
                    "&:hover": {
                        backgroundColor: fade(theme.palette.primary.main, 0.7),
                        color: "#fff",
                    }
                }
            }
        },
        "&$depth3": {
            visibility: "hidden",
            opacity: 0,
            transition: [
                theme.transitions.create("opacity"),
                theme.transitions.create("visibility"),
            ].join(", "),
            flexDirection: "column",
            position: "absolute",
            left: 0,
            top: "100%",
            boxShadow: theme.shadows[2],
            minWidth: 200,
            width: "max-content",
            "& > $menu": {
                backgroundColor: "#e0e0e0",
                "& > $multiItem": {
                    float: "none",
                    backgroundColor: "#e0e0e0",
                    zIndex: 1,
                    "&:hover": {
                        backgroundColor: fade(theme.palette.primary.main, 0.7),
                        color: "#fff",
                    },
                    "& > $multiLink": {
                        padding: theme.spacing(3),
                    }
                }
            }
        }
    },
    menu: {
        margin: 0,
        padding: 0,
        listStyle: "none",
    },
    multiItem: {
        "&:hover": {
            "& > $multiLevelMenu": {
                display: "flex",
                visibility: "visible",
                opacity: 1,
            },
            "& > $multiLink": {
                zIndex: 1,
            }
        },
    },
    multiLink: {
        color: "inherit !important",
        display: "flex",
        padding: "1rem 0.7rem",
        textDecoration: "none!important",
    },
    depth1: {},
    depth2: {},
    depth3: {},
});
import gql from "graphql-tag";

export default gql`
{
  beverageStatData {
    amount
    beverage {
      color
      translations {
        language
        name
      }
    }
  }
}`;
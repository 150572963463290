import React from "react";
import mapData from "./ContactsDataMapper";

export default ({t, dt, data: {hostInfo, loading}, classes}) => {
    if (loading || !hostInfo) {
        return null;
    }

    const mappedData = mapData(hostInfo, dt);

    return (
        <dl className={classes.root}>
            <dt>{t('email')}</dt>
            <dd>{mappedData.email}</dd>
            {mappedData.postalAddress
                ? <React.Fragment>
                    <dt>{t('postal_address')}</dt>
                    <dd>{mappedData.postalAddress.split("\n").map(
                        (line, index) => <React.Fragment key={index}>{line}<br/></React.Fragment>
                    )}</dd>
                </React.Fragment>
                : null
            }
        </dl>
    );
}
import gql from "graphql-tag";

export default gql`
  {
    insideCountryAmountData {
      amount
      location {
        flagUrl
        insideUrl
        translations {
          name
          language
        }
      }  
    }
  }`;
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_SET_AMOUNTS from "./LeftSideCountrySetListWidgetGraphql";
import LeftSideCountrySetListWidget from "./LeftSideCountrySetListWidget";
import withDataTranslation from "../lib/translationHelper";

export default compose(
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_SET_AMOUNTS, {
        options: ({setClientId, countryCode}) => ({
            variables: {
                setClientId: setClientId,
                countryCode: countryCode,
            }
        })
    }),
)(LeftSideCountrySetListWidget);
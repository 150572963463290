export default theme => ({
    listItem: {
        color: theme.palette.text.primary,
        "&:hover": {
            textDecoration: "underline",
        }
    },
    collapse: {
        fontStyle: "italic",
        margin: 0,
    },
    listItemText: {
        margin: 0,
    },
    amount: {
        right: 0,
    },
    flag: {
        marginRight: 4,
    }
});
import gql from "graphql-tag";

export const QUERY_TRADE_SCAN_BASKET = gql`
  query ($code: String!) {
    tradeScanBasketMessage(scanCode: $code)
  }`;

export const MUTATION_TRADE_SCAN_BASKET = gql`
  mutation ($message: String!, $scanCode: String!) {
    saveTradeScanBasket(message: $message, scanCode: $scanCode) {
      state
    }
  }`;
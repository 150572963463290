import {lighten} from "@material-ui/core/styles";

export default theme => ({
    root: {
        marginBottom: 20,
        background: "rgba(255, 255, 255, 0.4)",
    },
    header: {
        backgroundColor: lighten(theme.palette.primary.main, 0.8),
        paddingTop: 10,
        paddingBottom: 10,
    },
    content: {
        paddingTop: 10,
        paddingBottom: "10px !important",
    },
});
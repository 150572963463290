import React from "react";
import LeftSideWidget from "./LeftSideWidgetContainer";
import mapData from "./LeftSideTradeScanBasketWidgetDataMapper";
import LeftSideListWidgetStub from "./LeftSideListWidgetStubContainer";
import TradeScanBasketSelection from "./TradeScanBasketSelectionContainer";
import {Link} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import ZoomablePicture from "./ZoomablePictureContainer";

export default ({t, i18n, data: {allTradeScans, loading}, classes}) => {
    if (loading) {
        return <LeftSideListWidgetStub/>;
    }
    if (!allTradeScans || allTradeScans.length === 0) {
        return null;
    }

    const mappedData = mapData(allTradeScans, i18n.language);

    const TradeScanBasketItem = ({scan}) => {
        return (
            <div className={classes.itemWrapper}>
                <Link component={RouterLink}
                      to={scan.url}
                      className={classes.itemTitle}>{scan.code}</Link>
                <picture className={classes.scanPreview}>
                    <source srcSet={scan.preview} media="(min-width: 960px)"/>
                    <ZoomablePicture src={scan.original} originalUrl={scan.original} alt={scan.code} marginBottom={8}/>
                </picture>
                <TradeScanBasketSelection tradeScanCode={scan.code}/>
            </div>
        );
    };

    return <LeftSideWidget title={t("more_scans")}
                           content={mappedData.map(
                               scan => <TradeScanBasketItem scan={scan} key={`trade_scan_basket_item_${scan.code}`}/>
                           )}/>;
};
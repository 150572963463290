import noLogo from "./img/no_logo.svg";
import {snakeToCamel} from "./lib/stringHelper";

export default (data, dt) => {
    if (!data) {
        return {
            footerText: "",
        };
    }

    return {
        hostName: data.hostName,
        theme: snakeToCamel(data.theme),
        icon: data.iconUrl,
        logo: data.logoUrl || noLogo,
        languages: data.languages,
        siteName: dt("siteName", data.translations),
        footerText: dt("footerText", data.translations),
        postalAddress: dt("postalAddress", data.translations),
    };
};
import React from "react";
import mapData from "./StandalonePageContentDataMapper";
import ContentStub from "./ContentStub";
import AdminButtons from "./AdminButtonsContainer";
import {internalLinkClickHandler} from "../lib/urlHelper";

export default ({dt, user, data: {pageContent, loading}, history}) => {
    if (loading) {
        return <ContentStub/>;
    }

    const mappedData = mapData(pageContent, dt);
    if (!mappedData) {
        return null;
    }

    return (
        <React.Fragment>
            <div onClick={internalLinkClickHandler(history)}
                 dangerouslySetInnerHTML={{
                     __html: unescape(mappedData.content)
                 }}/>
            <AdminButtons editUrl={pageContent.adminUrl} user={user}/>
        </React.Fragment>
    );
};
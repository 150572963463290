import {connect} from "react-redux";
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import QUERY_BLOG_POST_CONTENT from "./BlogPostPageGraphql";
import BlogPostPage from "./BlogPostPage";
import withDataTranslation from "./lib/translationHelper";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import styles from "./BlogPostPageStyles";

export default compose(
    withRouter,
    withStyles(styles),
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_BLOG_POST_CONTENT, {
        options: ({match: {params}}) => ({
            variables: {
                slug: params.blogPostCode,
            }
        })
    }),
    connect(
        state => ({
            user: state.common.user,
            windowTitle: state.common.windowTitle,
        }),
        {
            setPageTitle,
            setBreadcrumbs
        }
    ),
)(BlogPostPage);
import gql from "graphql-tag";

export default gql`
{
  cityStatData {
    location {      
      translations {
        name
        language
      }
      country {
        url
        flagUrl
        translations {
          name
          language
        }
      }
      region {
        url
        translations {
          name
          language
        }
      }
    }
    amount
  }
}`;
export default () => ({
    root: {
        overflow: "hidden",
    },
    bigImage: {
        maxWidth: "none! important",
    },
    message: {
        marginBottom: 15,
    },
});
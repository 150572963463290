import {sortByName} from "./lib/arrayHelper";

export default (mapData, dt, language) => {
    if (!mapData) {
        return;
    }

    let result = mapData
        .filter((md) => md.amount > 0 && !md.extended)
        .map((md) => ({
            flag: md.location.flagUrl,
            name: dt("name", md.location.translations),
            url: `/${language}${md.location.url}`,
            amount: md.amount,
        }));
    sortByName(result);

    return result;
}

import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_REGION_LIST from "./LeftSideRegionListWidgetGraphql.js";
import LeftSideRegionListWidget from "./LeftSideRegionListWidget";
import withDataTranslation from "../lib/translationHelper";
import {withRouter} from "react-router-dom";

export default compose(
    withRouter,
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_REGION_LIST, {
        options: ({match: {params}}) => ({
            variables: {
                countryCode: params.countryCode,
                regionClientId: params.regionClientId,
            }
        })
    }),
)(LeftSideRegionListWidget);
import React from "react";
import {BrowserRouter} from "react-router-dom";
import AppWrapper from "./AppWrapperContainer";
import {ApolloProvider} from "@apollo/react-hooks";
import {apolloClient} from "./apolloClient";
import {Provider} from "react-redux";
import store from "./redux/store";
import Spinner from "./components/SpinnerContainer";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";

export default () =>
    <React.Suspense fallback={<Spinner/>}>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <ApolloProvider client={apolloClient} store={store}>
                    <BrowserRouter>
                        <AppWrapper/>
                    </BrowserRouter>
                </ApolloProvider>
            </Provider>
        </I18nextProvider>
    </React.Suspense>
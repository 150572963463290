import React from "react";
import Pagination from "@material-ui/lab/Pagination";

export default ({numPages, page, baseUrl, urlQuery = "", classes, className, history}) => {
    const selectPage = (event, value) => {
        let pageUrl = `${baseUrl}${value}/`;
        if (value === 1) {
            pageUrl = baseUrl;
        }
        history.push(pageUrl + urlQuery);
    };

    if (numPages === 1) {
        return null;
    }

    return (
        <Pagination count={numPages}
                    page={page}
                    shape="rounded"
                    onChange={selectPage}
                    className={`${classes.root} ${className}`}/>
    );
}

import React from "react";
import {Container, Typography, Link, Grid} from "@material-ui/core";
import canacoLogo from "../img/canaco_logo.svg";

export default ({text, classes}) =>
    <div className={classes.footer}>
        <Container maxWidth="lg">
            <Grid container>
                <Grid item xs={12} sm={8} className={classes.footerText}>
                    <Typography>
                        {text.split("\n").map(
                            (line, index) =>
                                <React.Fragment key={index}>
                                    {line}<br/>
                                </React.Fragment>
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.canacoCell}>
                    <Link href="https://canaco.org" target="_blank">
                        <img src={canacoLogo} alt="CANACO"/>
                    </Link>
                </Grid>
            </Grid>
        </Container>
    </div>

export default theme => ({
    continent: {
        "& a": {
            color: theme.palette.text.primary,
        }
    },
    countryList: {
        marginBottom: 30,
        listStyle: "none",
        padding: 0,
        columnGap: "10%",
        [theme.breakpoints.up("sm")]: {
            columnCount: 2,
        },
        "& li": {
            marginBottom: 5,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            breakInside: "avoid",
        },
        "& img": {
            verticalAlign: "text-top",
            marginRight: 5,
        }
    },
    itemAmount: {
        textAlign: "right",
    }
});
import {lighten} from "@material-ui/core";

export default theme => ({
    previews: {
        maxWidth: 750,
        flexWrap: "wrap",
        margin: "0 auto 40px",
        overflow: "hidden",
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "flex",
        }
    },
    previewLink: {
        display: "flex",
        width: "20%",
        paddingTop: "20%",
        position: "relative",
        "& img": {
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
        }
    },
    itemCard: {
        marginBottom: 25,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        [theme.breakpoints.up(400)]: {
            flexDirection: "row",
        },
        "& > .MuiSvgIcon-root": {
            minWidth: 110,
            width: 110,
            height: 110,
            color: lighten(theme.palette.primary.main, 0.8),
            margin: "0 auto",
            [theme.breakpoints.up(400)]: {
                margin: 0,
            },
        },
    },
    itemCover: {
        width: "100%",
        [theme.breakpoints.up(400)]: {
            minWidth: 110,
            width: 110,
            maxHeight: 110,
        },
    },
    content: {
        display: "flex",
        alignContent: "center",
        width: "100%",
    },
    itemProperties: {
        display: "flex",
        flexWrap: "wrap",
        margin: 0,
        alignSelf: "center",
        width: "100%",
        "& dt": {
            display: "none",
            margin: 0,
            [theme.breakpoints.up(600)]: {
                display: "flex",
                width: "25%",
            },
            "& p": {
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                [theme.breakpoints.up(600)]: {
                    textAlign: "right",
                },
            }
        },
        "& dd": {
            display: "flex",
            flexFlow: "column",
            width: "100%",
            marginBottom: 3,
            marginLeft: 0,
            [theme.breakpoints.up(600)]: {
                marginBottom: 0,
                width: "75%",
                paddingLeft: 10,
            },
        },
    },
    flag: {
        verticalAlign: "text-bottom",
        marginRight: 5,
    },
    mark: {
        display: "inline",
        width: "fit-content",
        marginRight: 5,
    },
    pagination: {
        paddingBottom: 15,
    }
});
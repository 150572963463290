import {withStyles} from "@material-ui/core/styles";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import MobileHeader from "./MobileHeader";
import styles from "./MobileHeaderStyles";
import {connect} from "react-redux";
import {setSearchQuery} from "../redux/actions";
import {withRouter} from "react-router-dom";

export default compose(
    withRouter,
    withStyles(styles),
    withTranslation(),
    connect(
        state => ({
            siteName: state.common.siteName,
            searchQuery: state.common.searchQuery,
        }), {
            setSearchQuery,
        }
    )
)(MobileHeader);
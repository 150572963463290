import BasketButton from "./BasketButton";
import compose from "recompose/compose";
import {connect} from "react-redux";
import {graphql} from "react-apollo";
import QUERY_BASKET from "./BasketButtonGraphql";
import {setBasket} from "../redux/actions/trade";
import {withTranslation} from "react-i18next";

export default compose(
    withTranslation(),
    connect(
        state => ({
            user: state.common.user,
            basket: state.trade.basket,
        }),
        {
            setBasket,
        }
    ),
    graphql(QUERY_BASKET)
)(BasketButton);
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import MapPage from "./MapPage";
import {graphql} from "react-apollo";
import QUERY_MAP_DATA from "./MapPageGraphql";
import withDataTranslation from "./lib/translationHelper";

export default compose(
    withTranslation(),
    connect(
        null,
        {
            setPageTitle,
            setBreadcrumbs
        }
    ),
    withDataTranslation,
    graphql(QUERY_MAP_DATA, {
        options: ({match: {params}}) => ({
            variables: {
                continent: params.continent,
                country: params.country,
                macroRegion: params.macroRegion,
                currentLocation: params.country || params.continent,
            }
        })
    })
)(MapPage);
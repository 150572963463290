import {formatDateTime} from "./lib/dateHelper";

export const mapBlogPostData = (data, dt, language) => {
    if (!data) {
        return;
    }

    return data.list.map(post => ({
        post: post.sort,
        url: `/${language}${post.url}`,
        preview: post.previewUrl,
        picture: post.pictureUrl,
        title: dt("title", post.translations),
        introduction: dt("introduction", post.translations),
        created: formatDateTime(post.created, language),
        modified: formatDateTime(post.modified, language),
    }));
};

export const mapSectionData = (data, dt, language) => {
    if (!data) {
        return {};
    }

    return {
        url: `/${language}${data.url}`,
        title: dt("title", data.translations),
    };
};
export default theme => ({
    footer: {
        background: theme.palette.primary.main,
        color: "#fff",
        position: "absolute",
        bottom: 0,
        padding: "15px 0",
        width: "100%",
        "& a": {
            color: "#fff",
        }
    },
    footerText: {
        textAlign: "center",
        [theme.breakpoints.up("sm")]: {
            textAlign: "left",
        },
    },
    canacoCell: {
        textAlign: "center",
        "& img": {
            width: 120,
            [theme.breakpoints.up("sm")]: {
                width: 64,
            }
        },
        [theme.breakpoints.up("sm")]: {
            textAlign: "right",
        },
    }
});
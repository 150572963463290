import React from "react";
import LeftSideListWidget from "./LeftSideListWidgetContainer";
import mapData from "./LeftSideCreatedListWidgetDataMapper";

export default ({t, i18n, variant, data: {itemAmountData, loading}}) => {
    const getTitle = () => {
        if (variant === "last_month") {
            return t("last_month");
        }
        if (variant === "last_year") {
            return t("last_year");
        }
        if (variant === "all") {
            return t("by_years");
        }
        return "";
    };

    return <LeftSideListWidget title={getTitle()}
                               listData={mapData(itemAmountData, variant, t, i18n.language)}
                               loading={loading}/>;
};
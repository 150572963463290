import moment from "moment";
import {formatDateTime} from "./lib/dateHelper";
import {mapColor} from "./lib/colorHelper"
import unknownItemPicture from "./img/unknown_item_picture.svg";

export const mapItemData = (data, dt, language, defaultPictureType = "main") => {
    if (!data) {
        return;
    }

    return data.list.map(item => ({
        code: item.code,
        twistOff: item.twistOff,
        url: `/${language}${item.url}`,
        comments: dt("comments", item.translations),
        since: item.since && moment(item.since).year(),
        modified: formatDateTime(item.modified, language),
        created: formatDateTime(item.created, language),
        companies: item.companies.map(company => ({
            website: company.website,
            shortName: dt("shortName", company.translations),
        })),
        beverages: item.beverages.map(beverage => ({
            color: mapColor(beverage.color),
            name: dt("name", beverage.translations),
        })),
        brands: item.brands.map(brand => ({
            name: dt("name", brand.translations),
        })),
        locations: item.locations.map(({country, region, city}) => ({
            countryFlag: country && country.flagUrl,
            countryUrl: country && `/${language}${country.url}`,
            countryName: country && dt("name", country.translations),
            regionUrl: region && `/${language}${region.url}`,
            regionName: region && dt("name", region.translations),
            cityName: city && dt("name", city.translations),
        })),
        sets: item.sets.map(set => ({
            url: `/${language}${set.url}`,
            name: dt("name", set.translations),
        })),
        marks: item.marks.map(mark => ({
            markFile: mark.markFileUrl,
            name: dt("name", mark.translations),
        })),
        preview: getPreview(item.pictures, defaultPictureType),
        pictures: item.pictures.length > 0
            ? item.pictures.map(picture => ({
                original: picture.pictureUrl,
                preview: picture.previewUrl,
                pictureType: picture.pictureType,
                default: picture.pictureType === defaultPictureType,
            }))
            : [{
                preview: unknownItemPicture,
                pictureType: "main",
            }],
    }));
};

const getPreview = (previews, pictureType) => {
    if (previews.length === 0) {
        return unknownItemPicture;
    }

    for (let preview of previews) {
        if (preview.pictureType !== pictureType) {
            continue;
        }

        return preview.previewUrl;
    }

    return previews[0].previewUrl;
};

export const mapLocationAncestorData = (data, dt, language) => {
    if (!data) {
        return;
    }

    return data.map((location) => ({
        name: dt("name", location.translations),
        link: `/${language}${location.url}`,
    }));
};

export const mapSetData = (data, dt) => {
    if (!data) {
        return;
    }

    return {
        name: dt("name", data.translations),
        pictureType: data.pictureType,
    }
};
import React from "react";
import {matchPath} from "react-router"
import MenuDataDataMapper, {Menu} from "./MenuDataDataMapper";
import {LANGUAGE_TRANSLATION_KEYS, SUPPORTED_LANGUAGES} from "../lib/translationHelper";
import {getLangRegExp} from "../lib/urlHelper";
import {sortByName} from "../lib/arrayHelper";

export default WrappedComponent => {
    class HOC extends React.Component {
        getRouteMatch = () => matchPath(this.props.history.location.pathname, {
            path: `/:language${getLangRegExp(SUPPORTED_LANGUAGES)}/`,
            exact: false,
            strict: false
        });

        componentDidUpdate = () => {
            const match = this.getRouteMatch();
            if (!match) {
                return;
            }
            if (match.params.language !== this.props.i18n.language) {
                this.props.i18n.changeLanguage(match.params.language).then(r => r);
            }
        };

        getTranslatedPageUrl = language => {
            let oldPath = this.props.location.pathname;
            let newPath = oldPath.replace(/\/[a-z]{2}\/([a-z/]*)/g, `/${language}/$1`);
            return newPath + this.props.location.search;
        };

        getMenuItems = () => {
            let menuData = MenuDataDataMapper(this.props);
            if (this.props.data.loading) {
                return menuData;
            }

            let languageMenus = SUPPORTED_LANGUAGES.map(
                l => Menu(
                    this.getTranslatedPageUrl(l),
                    this.props.t(LANGUAGE_TRANSLATION_KEYS[l]),
                    []
                )
            );
            sortByName(languageMenus);

            menuData.push(Menu("", this.props.t("language"), languageMenus))

            return menuData;
        };

        render = () => (
            <WrappedComponent
                {...this.props}
                menuItems={this.getMenuItems()}
            />
        );
    }

    return HOC;
}

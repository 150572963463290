import React from "react";
import mapData from "./CounterpartyStatDataMapper";
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    LinearProgress,
    Link
} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import TableStatStub from "./TableStatStubContainer";

export default ({t, dt, i18n, classes, data: {counterpartyStatData, loading, error}, ad}) => {
    if (loading) {
        return <TableStatStub/>;
    }
    if (error) {
        return null;
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{t("collector")}</TableCell>
                        <TableCell>{t("city")}</TableCell>
                        <TableCell className={classes.hiddenOnMobile}>{t("country")}</TableCell>
                        <TableCell colSpan={2} className={classes.amountHeader}>{t("amount")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {mapData(counterpartyStatData, dt, i18n.language).map((counterparty, index) => (
                        <React.Fragment key={index}>
                            {index === 50 && counterpartyStatData.length > 60 && (
                                <tr key="ad_row">
                                    <td colSpan={6} className={classes.adCell}>{ad}</td>
                                </tr>
                            )}
                            <TableRow>
                                <TableCell component="th" scope="row">{counterparty.fullName}</TableCell>
                                <TableCell>{counterparty.cityName}</TableCell>
                                <TableCell className={classes.hiddenOnMobile}>
                                    {counterparty.countryItemAmount > 0
                                        ? (
                                            <Link to={counterparty.countryUrl}
                                                  component={RouterLink}
                                                  className={classes.flagLink}>
                                                {counterparty.countryFlag && (
                                                    <img src={counterparty.countryFlag}
                                                         className={classes.flag}
                                                         alt=""/>
                                                )}
                                                {counterparty.countryName}
                                            </Link>
                                        )
                                        : (
                                            <React.Fragment>
                                                {counterparty.countryFlag && (
                                                    <img src={counterparty.countryFlag}
                                                         className={classes.flag}
                                                         alt=""/>
                                                )}
                                                {counterparty.countryName}
                                            </React.Fragment>
                                        )
                                    }
                                </TableCell>
                                <TableCell className={classes.amountCell}>{counterparty.amount}</TableCell>
                                <TableCell className={classes.shareCell}>
                                    <LinearProgress color="secondary" variant="determinate" value={counterparty.share}/>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
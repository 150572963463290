import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";
import compose from "recompose/compose";
import CollectionGallery from "./CollectionGallery";
import styles from "./CollectionGalleryStyles";
import {connect} from "react-redux";

export default compose(
    withStyles(styles),
    withTranslation(),
    connect(
        state => ({
            windowTitle: state.common.windowTitle,
        }),
    ),
)(CollectionGallery);
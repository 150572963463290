import {withTranslation} from "react-i18next";
import {withTheme} from "@material-ui/core/styles";
import compose from "recompose/compose";
import LogoutDialog from "./LogoutDialog";
import {withApollo} from "react-apollo";
import {connect} from "react-redux";

export default compose(
    withTranslation(),
    withApollo,
    connect(
        state => ({
            user: state.common.user,
        })
    ),
    withTheme
)(LogoutDialog);
import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_CHART_DATA from "./BeverageChartGraphql";
import Chart from "./BeverageChart";
import withDataTranslation from "../lib/translationHelper";

export default compose(
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_CHART_DATA)
)(Chart);
export default theme => ({
    root: {
        fill: theme.palette.text.primary,
        filter: `drop-shadow(0px 0px 1px ${theme.palette.background.default})`,
        position: "absolute",
        right: "3%",
        bottom: "3%",
        width: "12%",
        height: "12%",
    }
});
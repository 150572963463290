export default (data, language) => {
    if (!data) {
        return;
    }

    return {
        code: data.code,
        url: `/${language}${data.url}`,
        original: getMainPicture(data.pictures).pictureUrl,
    };
};

const getMainPicture = pictures => pictures.filter(picture => picture.pictureType === "main")[0];
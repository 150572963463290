import React from "react";
import LeftSideListWidget from "./LeftSideListWidgetContainer";

export default ({t, i18n, searchQuery}) =>
    <LeftSideListWidget listData={
        ["company", "brand", "comment", "code", "location", "beverage", "set", "mark"].map(
            by => ({
                name: t(`by_${by}`),
                url: `/${i18n.language}/collection/search/?q=${(encodeURI(searchQuery))}&by=${by}`,
            })
        )
    } title={t("search")}/>;
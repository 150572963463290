import React from "react";
import {AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer} from "recharts";
import {timestampToDate} from "../lib/dateHelper";
import {fade, lighten} from "@material-ui/core";

export default ({t, i18n, data: {dynamicData}, theme: {palette}}) => {
    const RotatedAxisTick = ({x, y, fill, payload}) => (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} textAnchor="end" fill={fill} transform="rotate(-25)">
                {timestampToDate(payload.value, i18n.language)}
            </text>
        </g>
    );

    return (
        <React.Fragment>
            <ResponsiveContainer id="dynamic_chart" width="100%" height="auto" aspect="1">
                <AreaChart data={dynamicData}
                           margin={{top: 5, right: 0, bottom: 35, left: 0}}>
                    <CartesianGrid stroke={lighten(palette.text.primary, 0.7)}
                                   fill={palette.background.paper}
                                   strokeDasharray="2 3"/>
                    {dynamicData
                        ? <Area type="monotone"
                                dataKey="amount"
                                name={t("amount")}
                                animationDuration={1000}
                                stroke={palette.secondary.main}
                                fill={fade(palette.secondary.main, 0.3)}/>
                        : null}
                    <XAxis stroke={lighten(palette.text.primary, 0.3)}
                           dataKey="period"
                           type="number"
                           tickCount="10"
                           domain={["dataMin", "dataMax"]}
                           tick={<RotatedAxisTick fill={palette.text.primary}/>}/>
                    <YAxis tick={{fill: palette.text.primary}}
                           stroke={lighten(palette.text.primary, 0.3)}/>
                    <Tooltip labelFormatter={(timestamp) => timestampToDate(timestamp, i18n.language)}/>
                </AreaChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
};
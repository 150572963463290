import React from "react";
import {
    SET_PAGE_TITLE,
    SET_SITE_NAME,
    SET_BREADCRUMBS,
    SET_USER,
    SET_LANGUAGES,
    SET_SEARCH_QUERY
} from "../actionTypes";
import {SUPPORTED_LANGUAGES} from "../../lib/translationHelper";
import {Skeleton} from "@material-ui/lab";

const initialState = {
    siteName: "",
    languages: SUPPORTED_LANGUAGES,
    pageTitle: "",
    windowTitle: "",
    breadcrumbItems: [],
    user: null,
    searchQuery: "",
};

const getWindowTitle = (pageTitle, siteName) => [pageTitle, siteName].filter(p => p).join(' – ');

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SITE_NAME: {
            return {
                ...state,
                siteName: action.name,
                windowTitle: getWindowTitle(state.pageTitle, action.name),
            };
        }
        case SET_LANGUAGES: {
            return {
                ...state,
                languages: action.languages,
            };
        }
        case SET_PAGE_TITLE: {
            return {
                ...state,
                pageTitle: action.loading
                    ? <Skeleton variant="text" width={200}/>
                    : action.title,
                windowTitle: action.loading
                    ? ""
                    : getWindowTitle(action.title, state.siteName),
            };
        }
        case SET_BREADCRUMBS: {
            return {
                ...state,
                breadcrumbItems: action.items,
            };
        }
        case SET_USER: {
            return {
                ...state,
                user: action.user,
            };
        }
        case SET_SEARCH_QUERY: {
            return {
                ...state,
                searchQuery: action.query,
            };
        }
        default:
            return state;
    }
}

import React from "react";
import {Card, CardContent, Paper, Typography, Link} from "@material-ui/core";
import Pagination from "./PaginationContainer";
import ContentAdWrapper from "./ContentAdWrapperContainer";
import {Link as RouterLink} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import ItemPictures from "./ItemPicturesContainer";
import CollectionGalleryStub from "./CollectionGalleryStubContainer";
import {Helmet} from "react-helmet/es/Helmet";
import ShareButtons from "./ShareButtonsContainer";

export default ({
                    t,
                    items,
                    numPages,
                    page,
                    baseUrl,
                    urlQuery = "",
                    loading,
                    classes,
                    ad,
                    windowTitle,
                    originalAsPreview = false,
                    showShareButtons = false
                }) => {
    if (!items || loading) {
        return <CollectionGalleryStub/>;
    }

    const getAnchorUrl = itemCode => {
        let url = baseUrl;
        if (page > 1) {
            url += `${page}/`;
        }
        url += `${urlQuery}#${itemCode}`;
        return url;
    }

    const getItemDescription = item => {
        let result = [];
        result = result.concat(item.companies.map(company => company.shortName))
        result = result.concat(item.brands.map(brand => brand.name))
        result = result.concat(item.beverages.map(beverage => beverage.name))
        result = result.concat(item.locations.map(
            location => [location.cityName, location.regionName, location.countryName].join(', ')
        ))
        result = result.concat(item.sets.map(set => set.name))
        item.since && result.push(item.since);
        item.comments && result.push(item.comments);
        return result.join(', ');
    }

    return (
        <React.Fragment>
            {page > 1 && (
                <Helmet>
                    <link rel="canonical" href={baseUrl}/>
                </Helmet>
            )}
            {items.length >= 10 && (
                <Paper className={classes.previews}>
                    {items.map((item, index) => (
                        <HashLink to={getAnchorUrl(item.code)}
                                  key={index}
                                  className={classes.previewLink}
                                  smooth>
                            <img src={item.preview} alt={item.code}/>
                        </HashLink>
                    ))}
                </Paper>
            )}
            <Pagination numPages={numPages}
                        page={page}
                        baseUrl={baseUrl}
                        urlQuery={urlQuery}
                        className={classes.pagination}/>

            {items.map((item, index) => (
                <React.Fragment key={`item-${item.code}`}>
                    <Card className={classes.itemCard}
                          id={item.code}
                          style={
                              originalAsPreview ? {flexFlow: "column"} : {}
                          }>
                        <ItemPictures pictures={item.pictures}
                                      itemCode={item.code}
                                      twistOff={item.twistOff}
                                      originalAsPreview={originalAsPreview}/>
                        <CardContent className={classes.content}>
                            <dl className={classes.itemProperties}>
                                <React.Fragment>
                                    <dt><Typography>{t("code")}</Typography></dt>
                                    <dd>
                                        <Link to={item.url} component={RouterLink}>
                                            {item.code}
                                        </Link>
                                    </dd>
                                </React.Fragment>
                                {item.companies.length > 0 && (
                                    <React.Fragment>
                                        <dt><Typography>{t("company")}</Typography></dt>
                                        <dd>{item.companies.map((company, index) => (
                                            company.website
                                                ? <Link href={company.website}
                                                        color="secondary"
                                                        key={index}>{company.shortName}</Link>
                                                : <Typography key={index}>{company.shortName}</Typography>
                                        ))}</dd>
                                    </React.Fragment>
                                )}
                                {item.brands.length > 0 && (
                                    <React.Fragment>
                                        <dt><Typography>{t("brand")}</Typography></dt>
                                        <dd>{item.brands.map((brand, index) =>
                                            <Typography key={index}>{brand.name}</Typography>
                                        )}</dd>
                                    </React.Fragment>
                                )}
                                {item.beverages.length > 0 && (
                                    <React.Fragment>
                                        <dt><Typography>{t("beverage")}</Typography></dt>
                                        <dd>{item.beverages.map((beverage, index) =>
                                            <Typography key={index}>{beverage.name}</Typography>
                                        )}</dd>
                                    </React.Fragment>
                                )}
                                {item.locations.length > 0 && (
                                    <React.Fragment>
                                        <dt><Typography>{t("location")}</Typography></dt>
                                        <dd>{item.locations.map((location, index) =>
                                            <Typography key={index}>
                                                {location.countryFlag && (
                                                    <img src={location.countryFlag}
                                                         className={classes.flag}
                                                         alt=""/>
                                                )}
                                                {location.cityName && (
                                                    <Typography component="span">
                                                        {location.cityName}
                                                        {location.regionName || location.countryName ? ", " : ""}
                                                    </Typography>
                                                )}
                                                {location.regionName && (
                                                    <Link to={location.regionUrl}
                                                          component={RouterLink}>
                                                        {location.regionName}
                                                        {location.countryName ? ", " : ""}
                                                    </Link>
                                                )}
                                                {location.countryName && (
                                                    <Link to={location.countryUrl}
                                                          component={RouterLink}>
                                                        {location.countryName}
                                                    </Link>
                                                )}
                                            </Typography>
                                        )}</dd>
                                    </React.Fragment>
                                )}
                                {item.sets.length > 0 && (
                                    <React.Fragment>
                                        <dt><Typography>{t("sets")}</Typography></dt>
                                        <dd>
                                            {item.sets.map((set, index) =>
                                                <Typography key={index}>
                                                    <Link to={set.url} component={RouterLink}>
                                                        {set.name}
                                                    </Link>
                                                </Typography>
                                            )}
                                        </dd>
                                    </React.Fragment>
                                )}
                                {item.marks.length > 0 && (
                                    <React.Fragment>
                                        <dt><Typography>{t("mark")}</Typography></dt>
                                        <dd>{item.marks.filter(
                                            mark => mark.markFile
                                        ).map((mark, index) => (
                                                <img key={index}
                                                     src={mark.markFile}
                                                     className={classes.mark}
                                                     alt={mark.name}/>
                                            )
                                        )}</dd>
                                    </React.Fragment>
                                )}
                                {item.since && (
                                    <React.Fragment>
                                        <dt><Typography>{t("year")}</Typography></dt>
                                        <dd><Typography>{item.since}</Typography></dd>
                                    </React.Fragment>
                                )}
                                {item.comments && (
                                    <React.Fragment>
                                        <dt><Typography>{t("comments")}</Typography></dt>
                                        <dd><Typography>{item.comments}</Typography></dd>
                                    </React.Fragment>
                                )}
                                {showShareButtons && (
                                    <React.Fragment>
                                        <dt><Typography>{t("share")}</Typography></dt>
                                        <dd>
                                            <ShareButtons title={windowTitle}
                                                          description={getItemDescription(item)}
                                                          imageUrl={item.pictures.original}
                                                          noMargin/>
                                        </dd>
                                    </React.Fragment>
                                )}
                            </dl>
                        </CardContent>
                    </Card>
                    {index === Math.floor(items.length / 2) && <ContentAdWrapper ad={ad}/>}
                </React.Fragment>
            ))}
            <Pagination numPages={numPages} page={page} baseUrl={baseUrl} urlQuery={urlQuery}/>
        </React.Fragment>
    );
}
import {mapColor} from "../lib/colorHelper";

export default (statData, dt) => {
    if (!statData) {
        return;
    }

    return statData
        .filter((md) => md.amount > 0)
        .map((md) => ({
            name: dt("name", md.beverage.translations),
            amount: md.amount,
            color: mapColor(md.beverage.color),
        }));
}

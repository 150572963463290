import {roundTo} from "../lib/numberHelper";

export default (companyStatData, dt) => {
    if (!companyStatData) {
        return;
    }

    const maxAmount = companyStatData[0].amount;

    return companyStatData
        .filter((statData) => statData.amount > 0)
        .map((statData) => ({
            name: dt("shortName", statData.company.translations),
            website: statData.company.website,
            amount: statData.amount,
            share: roundTo(statData.amount * 100 / maxAmount, 1),
        }));
}

import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Alert from "@material-ui/lab/Alert";
import MUTATION_AUTH from "./LoginDialogGraphql";
import QUERY_USER_DATA from "./UserButtonGraphql";

export default ({t, open, onClose, theme, client}) => {
    const [errorMessage, setErrorMessage] = React.useState("");
    const [loginValid, setLoginValid] = React.useState(true);
    const [passwordValid, setPasswordValid] = React.useState(true);

    const authenticate = () => {
        const login = document.getElementById("login").value;
        if (!login) {
            setLoginValid(false);
            return;
        }

        const password = document.getElementById("password").value;
        if (!password) {
            setPasswordValid(false);
            return;
        }

        client.mutate({
            variables: {
                username: login,
                password: password,
            },
            mutation: MUTATION_AUTH,
            refetchQueries: [{
                query: QUERY_USER_DATA,
            }],
        })
            .then(() => {
                onClose();
            })
            .catch(() => {
                setErrorMessage(t("invalid_login_or_password"));
            });
    };

    const clearErrors = () => {
        setErrorMessage("");
        setLoginValid(true);
        setPasswordValid(true);
    };

    const handleKeyPress = event => {
        if (event.key === "Enter") {
            authenticate();
        }
    };

    return (
        <Dialog
            fullScreen={useMediaQuery(theme.breakpoints.down("sm"))}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{t("authentication")}</DialogTitle>
            <DialogContent>
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <form>
                    <TextField id="login"
                               autoFocus
                               margin="dense"
                               label={t("login")}
                               type="text"
                               autoComplete="username"
                               required
                               fullWidth
                               onChange={clearErrors}
                               onKeyPress={handleKeyPress}
                               error={!loginValid}/>
                    <TextField id="password"
                               margin="dense"
                               label={t("password")}
                               type="password"
                               autoComplete="current-password"
                               required
                               fullWidth
                               onChange={clearErrors}
                               onKeyPress={handleKeyPress}
                               error={!passwordValid}/>
                </form>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={authenticate} color="primary">{t("enter")}</Button>
            </DialogActions>
        </Dialog>
    );
}

import {sortByName} from "./lib/arrayHelper";
import unknownFlag from "./img/unknown_flag.svg"

export default (gropedSets, unknownSets, t, dt, language) => {
    if (!gropedSets) {
        return [];
    }

    let result = gropedSets.map(country => ({
        url: `/${language}${country.url}`,
        flag: country.flagUrl,
        name: dt("name", country.translations),
        code: country.code,
        sets: mapSets(country.sets, dt, language),
    }));
    sortByName(result);

    if (unknownSets && unknownSets.length > 0) {
        result.push(
            {
                flag: unknownFlag,
                name: t("international"),
                code: "unknown",
                sets: mapSets(
                    unknownSets.map(setAmount => setAmount.set),
                    dt,
                    language
                ),
            }
        );
    }

    return result;
};

const mapSets = (sets, dt, language) => {
    let result = sets
        .filter(set => set.itemAmount > 0)
        .map(set => ({
            url: `/${language}${set.url}`,
            name: dt("name", set.translations),
            amount: set.completeAmount ? `${set.itemAmount}/${set.completeAmount}` : set.itemAmount,
            pictureType: set.pictureType,
        }));
    sortByName(result);

    return result;
};
import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {Container, Grid, InputAdornment, FormControl, OutlinedInput, IconButton} from "@material-ui/core";
import Breadcrumb from "./BreadcrumbContainer";
import SearchIcon from "@material-ui/icons/Search";
import MainMenu from "./MainMenuContainer";
import DesktopBasketButton from "./DesktopBasketButtonContainer";
import DesktopUserButton from "./DesktopUserButtonContainer";

export default ({siteName, pageTitle, logo, t, classes, i18n, history, searchQuery, setSearchQuery}) => {
    const doSearch = () => history.push(`/${i18n.language}/collection/search/?q=${encodeURI(searchQuery)}`);

    const handleSearchPress = event => {
        if (event.key !== "Enter") {
            return;
        }
        doSearch();
    };

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
    };

    return <div>
        <Container className={classes.top}>
            <Grid container className={classes.showDesktop}>
                <Grid item md={2} className={classes.logoCell}>
                    <RouterLink to={`/${i18n.language}/`}>
                        <img src={logo} className={classes.logo} alt={siteName}/>
                    </RouterLink>
                </Grid>
                <Grid item md={8} className={classes.titleWrapper}>
                    <h1 className={classes.title}>{pageTitle}</h1>
                </Grid>
                <Grid item md={2} className={classes.basketWrapper}>
                    <DesktopUserButton/>
                    <DesktopBasketButton/>
                </Grid>
            </Grid>
        </Container>
        <Grid container className={classes.showDesktop}>
            <MainMenu/>
        </Grid>
        <Container className={classes.bottom}>
            <Grid container>
                <Grid item xs={12} md={9}>
                    <Breadcrumb/>
                </Grid>
                <Grid item md={3} className={`${classes.rightCell} ${classes.showDesktop}`}>
                    <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                            id="search_input"
                            placeholder={t("search")}
                            margin="dense"
                            fullWidth
                            value={searchQuery}
                            onKeyPress={handleSearchPress}
                            onChange={handleSearchChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton onMouseDown={doSearch} disabled={!searchQuery}>
                                        <SearchIcon/>
                                    </IconButton>
                                </InputAdornment>
                            }
                            className={classes.searchInput}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    </div>;
};
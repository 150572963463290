import {withStyles} from "@material-ui/core/styles";
import Map from "./Map";
import styles from "./MapStyles";
import compose from "recompose/compose";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import withDataTranslation from "../lib/translationHelper";

export default compose(
    withRouter,
    withTranslation(),
    withDataTranslation,
    withStyles(styles),
)(Map);
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import {setBreadcrumbs, setPageTitle} from "./redux/actions";
import AboutPage from "./AboutPage";

export default compose(
    withTranslation(),
    connect(
        null,
        {
            setPageTitle,
            setBreadcrumbs
        }
    ),
)(AboutPage);
import {OPEN_MOBILE_MENU, CLOSE_MOBILE_MENU, TOGGLE_MOBILE_SUBMENU} from "../actionTypes";

const initialState = {
    opened: false,
    submenuExpand: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MOBILE_MENU: {
            return {
                ...state,
                opened: true,
            };
        }
        case CLOSE_MOBILE_MENU: {
            return {
                ...state,
                opened: false,
            };
        }
        case TOGGLE_MOBILE_SUBMENU: {
            return {
                ...state,
                submenuExpand: {
                    ...state.submenuExpand,
                    [action.itemCode]: !state.submenuExpand[action.itemCode]
                },
            };
        }
        default:
            return state;
    }
}

import React, {useEffect} from "react";
import Page from "./components/PageContainer";
import StandalonePage from "./components/StandalonePageContentContainer";
import LeftSideTradeScanListWidget from "./components/LeftSideTradeScanListWidgetContainer";
import LeftSideTradeCountryListWidget from "./components/LeftSideTradeCountryListWidgetContainer";

export default ({t, i18n, setPageTitle, setBreadcrumbs, ad}) => {
    useEffect(() => {
        setPageTitle(t("trade"));
        setBreadcrumbs([{
            name: t("trade"),
        }]);
    }, [t, i18n, setPageTitle, setBreadcrumbs]);

    const centralContent = (
        <React.Fragment>
            <StandalonePage pageType="trade"/>
            {ad}
        </React.Fragment>
    );

    const leftContent = (
        <React.Fragment>
            <LeftSideTradeCountryListWidget/>
            <LeftSideTradeScanListWidget/>
        </React.Fragment>
    );

    return <Page leftColContent={leftContent} centralColContent={centralContent}/>;
};
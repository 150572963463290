import {createMuiTheme} from "@material-ui/core";
import {defaultThemeObject} from "./default";

export default createMuiTheme({
    ...defaultThemeObject,
    palette: {
        primary: {
            main: "#2f6dc2",
        },
        secondary: {
            main: "#c00",
        },
        background: {
            default: "#fff",
            paper: "#fff",
        },
        text: {
            primary: "#333",
            secondary: "#333",
        }
    },
    mainContainer: {
        borderColor: "#eee",
        backgroundColor: "#677e91",
    }
});
import React, {useEffect} from 'react';

export default ({adSlot}) => {
    useEffect(() => {
        try {
            window.yaContextCb.push(() => {
                window.Ya.Context.AdvManager.render({
                    renderTo: `yandex_rtb_${adSlot}`,
                    blockId: adSlot
                })
            })
        } catch (e) {
            console.log(e);
        }
    });

    return <div id={`yandex_rtb_${adSlot}`}/>;
}

import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_INSIDE_ITEM_AMOUNTS from "./LeftSideInsideAmountListWidgetGraphql";
import LeftSideInsideAmountListWidget from "./LeftSideInsideAmountListWidget";
import withDataTranslation from "../lib/translationHelper";

export default compose(
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_INSIDE_ITEM_AMOUNTS),
)(LeftSideInsideAmountListWidget);
import {withTranslation} from "react-i18next";
import {graphql} from "react-apollo";
import compose from "recompose/compose";
import MenuData from "./MenuData";
import QUERY_MENU_DATA from "./MenuDataGraphql";
import withDataTranslation from "../lib/translationHelper";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

export default compose(
    withRouter,
    withTranslation(),
    withDataTranslation,
    connect(
        state => ({
            user: state.common.user,
            languages: state.common.languages,
        }),
    ),
    graphql(QUERY_MENU_DATA),
    MenuData,
);
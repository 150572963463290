import gql from "graphql-tag";

export default gql`
  query ($countryCode: String, $regionClientId: String) {
    regions(countryCode: $countryCode, regionClientId: $regionClientId) {
      location {
        url
        translations {
          name
          language
        }
      }
      amount
    }
    country(countryCode: $countryCode, regionClientId: $regionClientId) {
      itemAmount
      code
    }
  }`;
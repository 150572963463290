import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import {withTheme} from "@material-ui/core/styles";
import compose from "recompose/compose";
import QUERY_DYNAMIC_DATA from "./DynamicChartGraphql";
import Chart from "./DynamicChart";

export default compose(
    withTranslation(),
    withTheme,
    graphql(QUERY_DYNAMIC_DATA)
)(Chart);
import gql from "graphql-tag";

export default gql`
  {
    user {
      username
      firstName
      lastName
      email
      isOwner
    }
  }`;

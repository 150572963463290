import {withTranslation} from "react-i18next";
import {graphql} from "react-apollo";
import {withStyles} from "@material-ui/core/styles";
import compose from "recompose/compose";
import QUERY_CONTACTS from "./ContactsGraphql";
import Contacts from "./Contacts";
import styles from "./ContactsStyles";
import withDataTranslation from "../lib/translationHelper";

export default compose(
    withStyles(styles),
    withTranslation(),
    withDataTranslation,
    graphql(QUERY_CONTACTS)
)(Contacts);
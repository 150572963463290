import React from "react";
import {Card, CardContent, Typography, CardHeader, CardActionArea} from "@material-ui/core";
import PhotoIcon from "@material-ui/icons/Photo";
import ZoomablePicture from "./ZoomablePictureContainer";

export default ({history, classes, url, picture, preview, title, subTitle, content, noPicture = <PhotoIcon/>}) => {
    let details = (
        <div className={classes.details}>
            <CardHeader title={title} subheader={subTitle}/>
            <CardContent className={classes.content}>
                <Typography>{content}</Typography>
            </CardContent>
        </div>
    );

    if (url) {
        const clickHandler = () => url.startsWith('http')
            ? window.open(url, '_blank')
            : history.push(url);
        details = <CardActionArea onClick={clickHandler}>{details}</CardActionArea>;
    }

    return <Card className={classes.card}>
        {picture
            ? (
                <div className={classes.pictureWrapper}>
                    <ZoomablePicture src={preview} originalUrl={picture} imgClassName={classes.picture}/>
                </div>
            )
            : noPicture}
        {details}
    </Card>;
};
import React from "react";
import {Dialog} from "@material-ui/core";
import ScrollContainer from "react-indiana-drag-scroll";

export default ({src, originalUrl, hidden, id, classes, imgClassName, alt, marginBottom = 0, originalAsPreview = false}) => {
    const [openOriginal, setOpenOriginal] = React.useState(false);

    const handleClickOpen = () => {
        setOpenOriginal(true);
    };

    const handleClose = () => {
        setOpenOriginal(false);
    };

    return (
        <React.Fragment>
            <picture>
                {!originalAsPreview && <source media="(max-width: 400px)" srcSet={originalUrl}/>}
                <img src={originalAsPreview ? originalUrl : src}
                     role="tabpanel"
                     hidden={hidden}
                     id={id}
                     onClick={handleClickOpen}
                     className={originalUrl && `${classes.picture} ${imgClassName}`}
                     alt={alt}
                     style={{
                         marginBottom: marginBottom,
                     }}
                />
            </picture>
            {originalUrl && (
                <Dialog onClose={handleClose} open={openOriginal} className={classes.dialog} maxWidth="xl">
                    <ScrollContainer>
                        <img src={originalUrl} onClick={handleClose} alt={alt} className={classes.zoomPicture}/>
                    </ScrollContainer>
                </Dialog>
            )}
        </React.Fragment>
    );
};
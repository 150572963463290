import {graphql} from "react-apollo";
import {withTranslation} from "react-i18next";
import compose from "recompose/compose";
import QUERY_ITEM_AMOUNTS from "./LeftSideCreatedListWidgetGraphql";
import LeftSideCreatedListWidget from "./LeftSideCreatedListWidget";

export default compose(
    withTranslation(),
    graphql(QUERY_ITEM_AMOUNTS, {
        options: ({variant}) => ({
            variables: {
                groupBy: variant === "last_month"
                    ? "day"
                    : variant === "last_year"
                        ? "month"
                        : variant === "all"
                            ? "year"
                            : null,
            }
        })
    }),
)(LeftSideCreatedListWidget);